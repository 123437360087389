import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { selectboxComponent } from '../../layouts/selectbox/selectbox.component';
import { InputboxComponent } from '../../layouts/inputbox/inputbox.component';
import { HttpService } from 'src/app/services/http.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { Api } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FullComponent} from '../../layouts/full/full.component'
import { formatDate, Time,DatePipe } from '@angular/common';

interface Role {
  value: string;
  id: string;
}

interface Designation {
  value: string;
  id: string;
}

@Component({
  selector: 'app-usermodal',
  styleUrls: ['./usermodal.component.css'],
  template: `<h1 mat-dialog-title>Add User</h1>
  <div mat-dialog-content>
  <form [formGroup]="addUserForm" name="adduser" >
    <input matInput value="" placeholder="Enter Name" #message formControlName="userid" name="userid" style="visibility:hidden">
    <mat-form-field>
      <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
    </mat-form-field>

    <span class="text-danger"
       *ngIf="(addUserForm.get('name')?.invalid && addUserForm.get('name')?.touched && addUserForm.get('name')?.errors?.required )">
           Name is required
    </span>
    
     <mat-form-field>
        <input matInput value="" placeholder="email" #message formControlName="email" name="email">
     </mat-form-field>  
     <span class="text-danger"
       *ngIf="(addUserForm.get('email')?.invalid && addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.required )">
           Eamil is required
     </span>   
     <span class="text-danger"
       *ngIf="(addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.email )">
        Enter a valid email address
     </span>   
     <mat-form-field>
        <input matInput value="" placeholder="password" #message formControlName="password" name="email">
     </mat-form-field> 
     <span class="text-danger"
     *ngIf="(addUserForm.get('password')?.invalid && addUserForm.get('password')?.touched && addUserForm.get('password')?.errors?.required )">
       Password is required
   </span>  
   <section class="gender">
    <label class="example-margin">Gender: </label>
    <mat-radio-group formControlName="gender" name="gender">
      <mat-radio-button class="example-margin" value="Female">
        Female
      </mat-radio-button>
      <mat-radio-button class="example-margin" value="Male" style="margin-left:15px">
        Male
      </mat-radio-button>
    </mat-radio-group>
    </section><br/>
    <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
      <mat-label>Choose a Birthdate</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="birthdate" name="birthdate">
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <span class="text-danger"
       *ngIf="(addUserForm.get('gender')?.invalid && addUserForm.get('gender')?.touched && addUserForm.get('gender')?.errors?.required )">
        Gender is required
     </span>  
    <mat-form-field appearance="fill">
      <mat-label>Select Role</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of roleoptions" [value]="role.value">
          {{role.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span class="text-danger"
      *ngIf="(addUserForm.get('role')?.invalid && addUserForm.get('role')?.touched && addUserForm.get('role')?.errors?.required )">
        Role is required
    </span>  
    <mat-form-field appearance="fill">
      <mat-label>Select Designation </mat-label>
      <mat-select formControlName="designation">
        <mat-option *ngFor="let des of desOptions" [value]="des.id">
          {{des.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
   
    <span class="text-danger"
      *ngIf="(addUserForm.get('designation')?.invalid && addUserForm.get('designation')?.touched && addUserForm.get('designation')?.errors?.required )">
        Designation is required
    </span>  
    <mat-form-field>
      <input matInput value="" placeholder="contact_no" #message formControlName="contactno" name="contactno">
    </mat-form-field>
    <span class="text-danger"
      *ngIf="(addUserForm.get('contact_no')?.invalid && addUserForm.get('contact_no')?.touched && addUserForm.get('contact_no')?.errors?.required )">
        Contact_No is required
    </span> 
    <mat-form-field>
      <input matInput value="" placeholder="alternate_contact_No" #message formControlName="alternatecontact" name="alternatecontact">
    </mat-form-field>
    <span class="text-danger"
      *ngIf="(addUserForm.get('alternate_contact_No')?.invalid && addUserForm.get('alternate_contact_No')?.touched && addUserForm.get('alternate_contact_No')?.errors?.required )">
        Alternate Contact No is required
    </span> 
    <mat-form-field>
      <input matInput value="" placeholder="parents_contact_No" #message formControlName="parentscontact" name="parentscontact">
    </mat-form-field>
    <span class="text-danger"
      *ngIf="(addUserForm.get('parents_contact_No')?.invalid && addUserForm.get('parents_contact_No')?.touched && addUserForm.get('parents_contact_No')?.errors?.required )">
        Parents Contact No is required
    </span> 
    <mat-form-field>
      <input matInput value="" placeholder="full_current_address" #message formControlName="currentaddress" name="currentaddress">
    </mat-form-field>
    <span class="text-danger"
    *ngIf="(addUserForm.get('full_current_address')?.invalid && addUserForm.get('full_current_address')?.touched && addUserForm.get('full_current_address')?.errors?.required )">
      Full Current Address is required
    </span> 
    <mat-form-field>
      <input matInput value="" placeholder="full_permanent_address" #message formControlName="fulladdress" name="fulladdress">
    </mat-form-field>
    <span class="text-danger"
    *ngIf="(addUserForm.get('full_permanent_address')?.invalid && addUserForm.get('full_permanent_address')?.touched && addUserForm.get('full_permanent_address')?.errors?.required )">
      Permanent Address is required
    </span> 
    <mat-form-field class="example-full-width" appearance="fill" (click)="picker2.open()">
      <mat-label>Choose a Joindate</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="joindate" name="joindate">
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <span class="text-danger"
    *ngIf="(addUserForm.get('joindate')?.invalid && addUserForm.get('joindate')?.touched && addUserForm.get('joindate')?.errors?.required )">
      JoinDate is required
    </span> 
    <mat-form-field>
      <input matInput value="" placeholder="upload" #message formControlName="upload" name="upload">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill" (click)="picker3.open()">
      <mat-label>Choose a LeaveDate</mat-label>
      <input matInput [matDatepicker]="picker3" formControlName="leaving_date" name="leaving_date">
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
    
    <div mat-dialog-actions style="float: right;">
        <button type="submit" *ngIf="adduserbtn" [disabled]="addUserForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="adduser()">Save</button>
        <button type="submit" *ngIf="edituserbtn" mat-raised-button color="primary" tabindex="2" (click)="edituser()">Edit</button>
        <button  mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
      </div>
  </form>
  </div>`

})
export class UsermodalComponent implements OnInit {
  adduserbtn:any;
  edituserbtn:any;
  roleoptions: Role[] = [
    {value: 'User', id: '1'},
    {value: 'Manager', id: '2'},
  ];

  desOptions: Role[] = [
    {value: 'Android developer', id: '1'},
    {value: 'iOS Developer', id: '2'},
    {value: 'Web developer', id: '3'},
    {value: 'Designer', id: '4'},
    {value: 'Python Developer', id: '5'}
    ];
  addUserForm = new FormGroup({
    userid: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required]),
    gender: new FormControl('',[Validators.required]),
    birthdate: new FormControl('',[Validators.required]),
    role: new FormControl('',[Validators.required]),
    designation: new FormControl('',[Validators.required]),
    contactno: new FormControl('',[Validators.required]),
    alternatecontact: new FormControl('',[Validators.required]),
    parentscontact: new FormControl('',[Validators.required]),
    currentaddress: new FormControl('',[Validators.required]),
    fulladdress: new FormControl('',[Validators.required]),
    joindate: new FormControl('',[Validators.required]),
    upload: new FormControl(''),
    leaving_date: new FormControl(''),
  });
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UsermodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private http: HttpService, private auth: AuthGuard, private api: Api,) 
    { 
      this.adduserbtn = true;
      // console.log(this.data);
      if(this.data.modal == "edit")
      {
          this.edituserbtn = true;
          this.adduserbtn = false;
          
          this.addUserForm.controls['name'].setValue(this.data.data['name']);
          this.addUserForm.controls['email'].setValue(this.data.data['email']);
          this.addUserForm.controls['password'].setValue(this.data.data['password']);
          this.addUserForm.controls['gender'].setValue(this.data.data['gender']);
          let Array =  this.data.data['birthdate'].split("-");
          const date = new Date(Array[2], Array[1], Array[0]);
          this.addUserForm.controls['birthdate'].setValue(date);
          this.addUserForm.controls['role'].setValue(this.data.data['role']);
          console.log(this.data.data);
          this.addUserForm.controls['designation'].setValue(this.data.data['designation']);
          this.addUserForm.controls['contactno'].setValue(this.data.data['contactno']);
          this.addUserForm.controls['alternatecontact'].setValue(this.data.data['alternatecontact']);
          this.addUserForm.controls['parentscontact'].setValue(this.data.data['parentscontact']);
          this.addUserForm.controls['currentaddress'].setValue(this.data.data['currentaddress']);
          this.addUserForm.controls['fulladdress'].setValue(this.data.data['fulladdress']);
          let join_Array =  this.data.data['joindate'].split("-");
          const joindate = new Date(join_Array[2], join_Array[1], join_Array[0]);
          this.addUserForm.controls['joindate'].setValue(joindate);
          this.addUserForm.controls['upload'].setValue(this.data.data['upload']);
          this.addUserForm.controls['leaving_date'].setValue(this.data.data['leaving_date']);
          this.addUserForm.controls['userid'].setValue(this.data.data['id']);
      }
    }

  ngOnInit(): void 
  {
   
  }


  openDialog(): void {
    // this.starttime = "1ff"; 
  
    const dialogRef = this.dialog.open(UsermodalComponent, {
      width: '250px',
    });


    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.starttime = result;
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
 
  adduser() {
    this.http.post(Api.url.adminaddUser, this.addUserForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add User Successfully","","green-snackbar");
         this.dialogRef.close();
      }
    });
  }

  edituser()
  {
    this.http.post(Api.url.admineditUser, this.addUserForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Edit User Successfully","","green-snackbar");
         this.dialogRef.close();
         this.edituserbtn = false;
      }
    });
  
  }
 
}
