
<div class="main-chat-block d-flex rounded bg-body">
	<div class="d-flex flex-column p-15" style="width: 300px;overflow: auto;background: #ddd;">
		<div class="d-flex gap-2 align-items-center mb-3">
			<form action="">

				<div class="dropdown user-search-box">
					<input 
					  type="text" 
					  class="form-control rounded-5" 
					  placeholder="Search User..." 
					  (input)="onSearchUser($event.target.value)" 
					  style="height: 30px; box-shadow: none;"
					>
				
					<div class="dropdown-menu show mt-1 shadow-lg p-10">
						<div *ngFor="let names of userSearchSuggessions">
							<button class="btn chatname-bg mb-1 ps-2 d-flex align-items-center" (click)="activatChatUserOrGroup('personal', names)" style="font-size: 12px; width: 100%; text-align: start;">
								<span class="no-shrink chat-list-name-letter me-1" [ngStyle]="{'color':names.txtColor, 'background-color':names.bgColor}">{{names.firstLetter}}</span>
								<span class="d-inline-block">{{names.name}}</span>
							</button>
						</div>
						<div *ngIf="!userSearchSuggessions.length" class="text-muted text-center">
								No Users Found
						</div>
						
					</div>
				</div>
			</form>
			<button class="btn btn-dark group-add-btn no-shrink" data-bs-toggle="modal"
				data-bs-target="#createGroupModal">+ Group</button>
		</div>
		<!-- <div class="ff"></div> -->
		<div class="text-black-50 h6 b-b pb-1">Recent chats</div>
		<div class="flex-grow-1 recent-chat-name-block pe-2" style="overflow: auto;">
			<div class="user-List">
				<div  id="userLists">
					<div *ngFor="let list of userRecentChatsList">
						<button class="btn chatname-bg mb-1 ps-2 position-relative overflow-hidden" 
							[ngClass]="{'active':activeChat.chat_title == list.user_name}" 
							(click)="activatChatUserOrGroup(list.chat_type, {id:list.user_id, name: list.user_name})" 
							style="font-size: 12px; width: 100%; text-align: start;">

							<div *ngIf="list.chat_type == 'personal'" class="d-flex gap-2 align-items-center">
								<span class="chat-list-name-letter no-shrink" [ngStyle]="{'color':list.txtColor, 'background-color':list.bgColor}">{{list.firstLetter}}</span>
								<div class="flex-grow-1 overflow-hidden">
									<div class="chat-list-name">{{list.user_name}}</div>
									<div class="d-flex justify-content-between text-muted recent-last-msg">
										<span>{{list.last_message}}</span>
										<span>{{formatDate(list.message_timestamp)}}</span>
									</div>
								</div>
							</div>
							<div *ngIf="list.chat_type == 'group'" class="d-flex gap-2 align-items-center">
								<!-- <span class="chat-list-name-img no-shrink"><img src="assets/images/group-icon.png"></span> -->
								<span class="chat-list-name-letter no-shrink position-relative" [ngStyle]="{'color':list.txtColor, 'background-color':list.bgColor}">
									{{list.firstLetter}}
									<span class="chat-list-name-img no-shrink"><img src="assets/images/group-icon.png"></span>
								</span>
								<div class="flex-grow-1 overflow-hidden">
									<div class="chat-list-name">{{list.user_name}}</div>
									<div class="d-flex justify-content-between text-muted recent-last-msg">
										<span>{{list.last_message}}</span>
										<span>{{formatDate(list.message_timestamp)}}</span>
									</div>
								</div>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="flex-grow-1 d-flex flex-column ps-2 p-15">
		<div class="shadow-sm py-1 p-2 rounded d-flex align-items-center justify-content-between" *ngIf="activeChat.chat_title">
			<div class="">
				<span class="chat-list-name-letter no-shrink" [ngStyle]="{'color':activeChat.chat_group_info.txtColor, 'background-color':activeChat.chat_group_info.bgColor}">{{activeChat.chat_group_info.firstLetter}}</span>
				<span class="ms-2">{{activeChat.chat_title}}</span>
			</div>
			<div>
				<button class="btn border border-1 rounded" *ngIf="activeChat.chat_type == 'group'" data-bs-toggle="modal" data-bs-target="#addMembersModal" style="font-size: 12px;">
					View Details
				</button>
			</div>
		</div>
		<div class="common mt-3 flex-grow-1" style="overflow: auto;" #chatScroller>
			<div class="h-100 d-flex align-items-center justify-content-center shadow-sm rounded" *ngIf="!activeChat.chat_title">Select user Or Groups</div>
			
			<div *ngIf="activeChat.chat_title" class="chat-history d-flex align-items-end" #scroll style="min-height: 100%;">
				<div class="w-100">
					<div *ngFor="let chat of activeChat.chat_messages" class="mb" >
						<div class="gap-1" style="width: 100%; display: flex;" #chatMessages
							[style.justify-content]="chat.sender_user_id == loginUserID ? 'end' : 'start' ">
							<!-- <div class="border border-1 p-1 shadow-sm bg-body rounded" [style.border-color]="chat.senderID == loginUserID ? 'green !important' : 'red !important' "> -->
							<span class="chat-list-name-letter no-shrink" *ngIf="chat.sender_user_id !== loginUserID" [ngStyle]="{'color':chat.txtColor, 'background-color':chat.bgColor}">{{chat.firstLetter}}</span>
							<div>
								<div class="text-black-50 d-flex gap-2 mb-1" style="font-size: 11px; line-height: 1;" [style.justify-content]="chat.sender_user_id == loginUserID ? 'end' : 'start' ">
									<span *ngIf="chat.sender_user_id !== loginUserID">{{chat.sender_name}},</span>
									<span> {{formatDate(chat.message_timestamp)}} </span>
								</div>
								<div class="border border-1 p-2 shadow-sm  rounded" 
									[ngClass]="{'chat-self-user-theme':chat.sender_user_id == loginUserID,
												'chat-other-user-theme':chat.sender_user_id !== loginUserID}">
									<div style="max-width: 500px;min-width: 60px; word-wrap: break-word;">
										{{chat.message}}
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chat-area">
			<div *ngIf="activeChat.chat_title">
				<form id="message" class="chat-form" (ngSubmit)="onChatSubmit()">
					<mat-form-field appearance="outline" class="fw chat-message-input">
						<mat-label>Write Here</mat-label>
						<textarea matInput id="inputMessage" style="resize: none;"></textarea>
						<button type="submit" matSuffix mat-icon-button aria-label="Submit">
							<mat-icon>send</mat-icon>
						</button>
					</mat-form-field>
				</form>
			</div>
		</div>
	</div>
</div>

<!-- Add Member Modal -->
<div class="modal fade" id="addMembersModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header"  style="background: #888; color: #fff;">
			<div class="modal-title fs-5" id="exampleModalLabel">Group: {{activeChat.chat_title}}</div>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
			<div>
				<div>
					<div class="mb-2">Group Members</div>
					<div>
						<div *ngFor="let list of activeChat.chat_type_group_receivers">
							<button class="btn d-flex align-items-center justify-content-between chatname-bg mb-1 ps-2 position-relative overflow-hidden" style="font-size: 12px; width: 100%; text-align: start;">
								<div>
									<span class="chat-list-name-letter me-1" [ngStyle]="{'color':list.txtColor, 'background-color':list.bgColor}">{{list.firstLetter}}</span>
									<span>{{list.user_name}}</span>
								</div>
								<span *ngIf="list.user_id != loginUserID && activeChat.chat_group_info.creater_user_id == loginUserID" style="font-size: 18px;" (click)="removeGroupMember(list)"><i class="fa fa-trash text-danger"></i></span>
								<span *ngIf="list.user_role == 'admin'" style="font-size: 14px;" class="text-black-50">Group Admin</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			
			<div class="add-new-members mt-4" *ngIf="loginUserID == activeChat.chat_group_info.creater_user_id">
				<form id="create-group" [formGroup]="groupMemberform" class="add-new-group-members-form" (submit)="addMemberToGroup()">
					<mat-form-field>
						<mat-label>Add Members</mat-label>
						<mat-select multiple formControlName="members">
							<ng-container *ngFor="let names of activeChat.chat_type_group_members_avail_to_join">
								<mat-option  value="{{names.id}}">{{names.name}}</mat-option>
							</ng-container>
						</mat-select>
						<mat-error *ngIf="groupMemberform.get('members').hasError">Select atleast 1 user.</mat-error>
					</mat-form-field>
					<div class="w-100 text-right" >
						<button type="submit" class="btn border border-1 rounded" style="font-size: 12px;">
							Add Members
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
	</div>
</div>


<!-- Create Group Modal -->
<div class="modal fade" id="createGroupModal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="background: #888; color: #fff;">
				<h2 class="modal-title m-0">Create New Group</h2>
				<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
			</div>
			<div class="modal-body">
				<form id="create-group" class="create-group-form" [formGroup]="groupSaveform" (submit)="onCreateGroupFromSubmit()">
					<mat-form-field>
						<mat-label>Group Name</mat-label>
						<input matInput formControlName="group_name">
						<mat-error *ngIf="groupSaveform.get('group_name').hasError">This field is required</mat-error>
					</mat-form-field>
					<mat-form-field>
						<mat-label>Select Members</mat-label>
						<mat-select [(value)]="addUsersToGroups" multiple
							 formControlName="members">
							<mat-option>None</mat-option>
							<mat-option value="{{names.id}}"
								*ngFor="let names of userList">{{names.name}}</mat-option>
						</mat-select>
						<mat-error *ngIf="groupSaveform.get('members').hasError">Select atleast 1 member</mat-error>
					</mat-form-field>
					<div class="text-right">
						<button class="btn border border-1 rounded" type="submit" style="font-size: 12px;">
							Create Group
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>