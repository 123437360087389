import { Time } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {MatSort, MatSortable, Sort} from '@angular/material/sort';
export interface TaskLog {
  id:number,
  name: string,
  track:Time,
  date:Date,
}
const ELEMENT_DATA: TaskLog[] = [];

@Component({
  selector: 'app-task-track',
  templateUrl: './task-track.component.html',
  styleUrls: ['./task-track.component.css']
})
export class TaskTrackComponent implements OnInit {
  displayedColumns:string[] = ['name','track','date'];
  data:any;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  dataSource = new MatTableDataSource<TaskLog>();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  constructor(private http:HttpService,private auth:AuthGuard,private api:Api) {
    this.TaskLogList();
   }
   ngAfterViewInit() {
    this.sort.sort(({ id: 'id', start: 'asc'}) as MatSortable);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
  }
  
  TaskLogList() {
    this.http.get(Api.url.tasklogList).then((response: any)=>{
     if(response.success)
     {
      // console.log(response.data.data);
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   changePaging(event: PageEvent)
   {
    console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.TaskLogList();
   }
}
