<div class="main-content bg-body">
    <div class="row p-2 border-bottom section m-0">
        <div class="col-md-6 row">
            <div class="dropdown col-md-6">
                <div class="input-group  ">
                    <!-- <button class="btn btn-primary btn-md disabled" type="button">SELECT USER</button> -->
                    <select class="form-select" (change)="getSpecificUser($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
    
                        <option selected value="0">Select User</option>
                        <ng-container *ngFor="let item of userDataDisplay">
                            <option value="{{item.id}}">{{item.name}}</option>
                        </ng-container>
    
                    </select>
                </div>
            </div>
            <div class="margin-bottom col-md-6">
                <div class="col-auto">
                    <label class="visually-hidden" for="search">Search</label>
                    <input type="text" (keyup)="searchData($event)" class="form-control" id="search" placeholder="Search">
                </div>
            </div>
        </div>
        <div class="col-md-6">

            <div class="col-auto">
                <button class="mat-focus-indicator user-btn width-100 mat-raised-button mat-button-base mat-primary" (click)="gotoAddLeave()" type="button">Add
                    Request</button>
            </div>
        </div>
    </div>
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="">
                
                <ng-container matColumnDef="username">
                    <th class="custom" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username ">
                        User Name
                    </th>

                    <td mat-cell *matCellDef="let element"> {{element.username}} </td>

                </ng-container>

                
                <ng-container matColumnDef="leavedate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by leaveate">
                        Leave Date
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.leavedate}} </td>
                </ng-container>

                
                <ng-container matColumnDef="leavereason">
                    <th class="custom leavereason"  mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by leaveReason">
                        Leave Reason
                    </th>
                    <ng-container leaveReason()>

                        <td class="text-over-wrap leave-reason" (click)="openModal($event)" mat-cell *matCellDef="let element"> {{element.leavereason}}
                            
                        </td>
                    </ng-container>

                </ng-container>

                
                <ng-container matColumnDef="leavetype">
                    <th class="custom leavetype" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by leaveType">
                        Leave Type
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.leavetype}} </td>
                </ng-container>



                <ng-container matColumnDef="admin_note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by adminNote">
                        Admin Note
                    </th>
                    <td class="adminnote" mat-cell *matCellDef="let element"> {{element.admin_note}} </td>
                </ng-container>

                <ng-container matColumnDef="isapprove">

                    <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by approval"> Approval
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="leave-status" [ngStyle]=" { 'color':'#fff',  'background-color':  element.isapprove==='2'?'rgb(193 109 100)': ( element.isapprove==='1'?'rgb(110 157 120)' : '#F4CE6A' ) } ">
                            {{element.isapprove==='1'?'Approved': ( element.isapprove==='2'?'Declined' : 'Pending' ) }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef sortActionDescription="">
                        Action
                    </th>
                    <td mat-cell *matCellDef="let element">

                        <mat-nav-list appAccordion>
                        
                            <mat-icon (click)="editData(element)" [ngStyle]="{'cursor':'pointer', 'color':'#26c6da' }" class="material-icons">
                                edit
                            </mat-icon>

                            <mat-icon (click)="deleteData(element)" data-bs-toggle="modal" data-bs-target="#deleteModal" [ngStyle]="{'cursor':'pointer', 'color':'#EB4D4B' ,'padding-left':'10px'  }" class="material-icons">
                                delete
                            </mat-icon>
                        </mat-nav-list>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>

    <table id="leavetable" class="display" style="width:100%"></table>
          <!-- <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
            <mat-form-field appearance="fill">
              <mat-label>Records per page</mat-label>
              <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
                <mat-option [value]="-1">All</mat-option>
                <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
              [pageSizeOptions]="[50, 100, 200, 500, -1]"
              aria-label="Select page">
          </mat-paginator> -->
</div>


<div class="mat-elevation-z8">

    <!-- <div>
            <select name="" id="" class="form-select form-select-md">
                <option value="" selected>SELECT USER</option>
            </select>
        </div> -->

    
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator> -->



</div>

<!-- Button trigger modal -->



<!-- Modal  Add USER MODAL -->


<div class="modal fade" id="addRequest" tabindex="-1" aria-labelledby="addRequest" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Add Leave Request</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">

                    <div class="dropdown">
                        <div class="input-group mb-3 ">
                            <button class="btn select-user btn-md disabled text-white" type="button">USER</button>
                            <select formControlName="addUserLeave" class="form-select" id="inputGroupSelect03" aria-label="Example select with button addon">
                                <option value="">Select User</option>
                                <option *ngFor="let item of userDataDisplay" value="{{item.id}}">{{item.name}}
                                </option>
                            </select>

                            <div class="invalid-error" *ngIf="isSubmitted && formElement['addUserLeave'].errors?.required">
                                <sup>*</sup>Select User Please
                            </div>

                        </div>
                    </div>

                    <div class="mt-2" [ngStyle]="{'display':'flex','gap' :'20px'}">
                        <label>Leave Type</label>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" formControlName="leaveType" type="radio" name="leaveType" id="inlineRadio1" value="0">
                            <label class="form-check-label" for="inlineRadio1"> Half Day Leave
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input checked formControlName="leaveType" class="form-check-input" type="radio" name="leaveType" id="inlineRadio2" value="1">
                            <label class="form-check-label" for="inlineRadio2"> Full Day Leave</label>
                        </div>
                    </div>

                    <div class="invalid-error" *ngIf="isSubmitted && formElement['leaveType'].errors?.required">
                        <sup>*</sup>Select Leave Type

                    </div>

                    <div [ngStyle]="{ 'margin-top':'20px','display':'flex','gap' :'20px'}">
                        <label class="w-25 mt-3">Leave Date</label>

                        <mat-form-field (click)="picker.open()">
                            <mat-chip-list #chipList aria-label="Choose a date">
                                <mat-chip *ngFor="let value of model" [selectable]="false" [removable]="true" (removed)="remove(value)">
                                    {{ value | date }}
                                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                                </mat-chip>
                                <input formControlName="selectDate" [value]="resetModal" matInput [matDatepicker]="picker" [min]="minDate" placeholder="" (dateChange)="dateChanged($event)" [matChipInputFor]="chipList" hidden />
                            </mat-chip-list>
                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker [startAt]="init" [dateClass]="dateClass"></mat-datepicker>
                        </mat-form-field>

                    </div>

                    <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                        <sup>*</sup>Select Date Please
                    </div>

                    <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'30px'}">
                        <label>Valid&nbsp;Reason
                        </label>
                        <textarea name="" id="adminnote" formControlName="adminNote" class="form-control" rows="2"></textarea>
                    </div>

                    <div class="invalid-error" *ngIf="isSubmitted && formElement['adminNote'].errors?.rquired ">
                        <sup>*</sup>Enter Valid Reason

                    </div>
                    <div class="text-center  mt-4">
                        <!-- <button type=" button " class="btn btn-secondary " data-bs-dismiss="modal ">Close</button> -->
                        <button type="submit" [disabled]="requestForm.invalid" class="btn save-button text-white btn close-button ">Save</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>






<!-- Modal EDITT -->
<div class="modal fade " id="editModal" tabindex="-1" aria-labelledby="editModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content mt-5">
            <div class="modal-header">
                <h1 class="modal-title fs-5" [ngStyle]="{'color':'#46B2E0'}" id="exampleModalLabel">Edit Leave Request
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form [formGroup]="editForm" (ngSubmit)="editLeaveSubmit()">

                    <div [ngStyle]="{'margin-left':'10px'}">
                        <div [ngStyle]="{'display':'flex','gap' :'50px'}">
                            <label>User Name</label>
                            <label class="control-label username">{{userEditData.username}}</label>

                        </div>

                        <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap':'50px'}">
                            <label>Leave Date </label>
                            <label class="control-label username">{{userEditData.leavedate}}
                            </label>
                        </div>

                        <div class="reason" [ngStyle]="{'margin-top':'30px','display':'flex','gap': '30px'  }">
                            <label>Leave Reason </label>
                            <label class="control-label username">{{userEditData.leavereason}}

                            </label>
                        </div>

                        <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'50px'}">
                            <label>Leave Type</label>
                            <label class="control-label username">{{userEditData.leavetype}}
                            </label>
                        </div>

                        <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'30px'}">
                            <label>Admin&nbsp;Note
                            </label>
                            <textarea formControlName="adminNote" name="" id="adminnote" class="form-control" rows="2"> </textarea>

                        </div>

                        <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'50px'}">
                            <label>Approval </label>

                            <select formControlName="approvalSelect" name="" id="" class="form-control">
                                <option value="" selected>Please Select</option>
                                <option value="1">Approved</option>
                                <option value="2">Declined</option>
                            </select>
                        </div>

                        <div class="invalid-error" *ngIf="isSubmitted && editElement['approvalSelect'].errors?.required">
                            <sup>*</sup>Select DropDown
                        </div>

                        <!-- <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                            <sup>*</sup>Select Date Please
                        </div> -->

                    </div>
                    <div class="mt-4 text-center d-flex justify-content-center gap-3">
                        <button type="submit" [disabled]="editForm.invalid" class="btn close-button">Save</button>
                        <button id="cancelBtn" type="button" data-bs-dismiss="modal" class="btn btn-outline-danger">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
  </button> -->

<!-- Modal  DELETE -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Record</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure, you want to delete this Leave?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn close-button" data-bs-dismiss="modal">No</button>
                <button type="button" (click)="deleteUserRecord()" value="delete" data-bs-dismiss="modal" class="btn btn-outline-danger">Yes,
                    delete
                    it!</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="contentModal" tabindex="-1" aria-labelledby="contentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header p-2">
          <div class="modal-title fs-2" id="contentModalLabel">Leave Reason</div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-2">
          <!-- Display dynamic content here -->
          <p id="modalContent"></p>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div> -->
      </div>
    </div>
  </div>
<!-- TOAST MESSAGEEE -->

<div id="toast"></div>