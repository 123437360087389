<!-- <div fxLayout="row">
    <div fxFlex.gt-sm="100%" >
        <span class="dashboard-title">PROFILE</span>
    </div>
</div>
<span layout="row"><hr flex/></span> -->
<mat-card class="oh text-center little-profile">
    <mat-card-content>
        <!-- <h3 class="m-b-0">Angela Dominic</h3>
        <h6 class="m-t-0 ">Web Designer &amp; Developer</h6>
        <mat-card-actions>
            <button mat-raised-button color="warn">Follow</button>
        </mat-card-actions> -->
        <div fxLayout="row" fxLayoutWrap="wrap" class="m-t-30 text-justify inbox-list"  *ngIf="profiledata">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon"> person </mat-icon>
                <small>Name</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.name}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon"> email </mat-icon>
                <small>Email</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.email}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon"> people </mat-icon>
                <small>Gender</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.gender}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon"> perm_contact_calendar </mat-icon>
                <small>Birthdate</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.birthdate}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon"> people </mat-icon>
                <small>Designation</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{role}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">phone_android</mat-icon>
                <small>Contact No.</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.contactno}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">phonelink </mat-icon>
                <small>Alternate Contact No.</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.alternatecontact}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">phone</mat-icon>
                <small>Parents Contact No.</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.parentscontact}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">public </mat-icon>
                <small>Full Current Address</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.currentaddress}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list" >
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">public </mat-icon>
                <small>Full Permanent Address</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.fulladdress}}</small>
            </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap"  *ngIf="profiledata" class="text-justify inbox-list">
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-box">
                <mat-icon class="profile-icon">date_range</mat-icon>
                <small>Joindate</small>
            </div>
            <div fxFlex.gt-sm="50%" fxFlex.gt-xs="50%" fxFlex="100" class="profile-left">
                <small>{{profiledata.joindate}}</small>
            </div>
        </div>
    </mat-card-content>
</mat-card>
