
<div class="main-content bg-body">

    <div class="row p-2 border-bottom section m-0">
      <div class="col-sm-4">
          <div class="col-auto">
              <label class="visually-hidden" for="search">Search</label>
              <input type="text" (keyup)="SearchTimsheet($event)" class="form-control" id="search" placeholder="Search">
          </div>
      </div>
      <div class="col-sm-4 mobile-none"></div>
      <div class="col-sm-4">
        <div class="dropdown">
          <div class="input-group">
              <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
    
                  <!-- <option selected value="0">Select User</option> -->
                  <ng-container *ngFor="let des of usersname">
                      <option value="{{des.id}}">{{des.name}}</option>
                  </ng-container>
    
    
              </select>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <table mat-table [dataSource]="dataSource" class="" matSort>
              
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
            <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
          </ng-container>
    
          <ng-container matColumnDef="startime">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> startime </th>
            <td mat-cell *matCellDef="let element"> {{element.startime}} </td>
          </ng-container>
    
          <ng-container matColumnDef="endtime">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> endtime </th>
            <td mat-cell *matCellDef="let element"> {{element.endtime}} </td>
          </ng-container>
    
          <ng-container matColumnDef="projname">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> projname </th>
            <td mat-cell *matCellDef="let element"> {{element.projname}}</td>
          </ng-container>
    
          <ng-container matColumnDef="taskname">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>  taskname  </th>
            <td mat-cell *matCellDef="let element"> {{element.taskname}}</td>
          </ng-container>
    
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>  description  </th>
            <td mat-cell *matCellDef="let element">  {{element.description}} </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
      <mat-form-field appearance="fill">
        <mat-label>Records per page</mat-label>
        <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
          <mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
          <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
        [pageSizeOptions]="[50, 100, 200, 500, -1]"
        aria-label="Select page">
    </mat-paginator>
</div>