import { Component, OnInit, AfterViewInit, ViewChild, Inject, ChangeDetectorRef, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { HttpService } from 'src/app/services/http.service';
import { Api } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	AbstractControl,
	FormBuilder,
	Validators
} from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';


// import Validation from './utils/validation';

// export class AdminleaveEditDialogComponent {
//   constructor(
//     public dialogRef: MatDialogRef<AdminleaveEditDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) { }
// }
declare var $;
declare var toastr;
declare var moment;
declare var DataTable;
export interface employeeData {
	username: string;
	leavedate: string;
	leavereason: string;
	leavetype: string;
	admin_note: string;
	isapprove: string;
}


@Component({
	selector: 'app-adminleave',
	templateUrl: './adminleave.component.html',
	styleUrls: ['./adminleave.component.css'],

})


export class AdminleaveComponent implements OnInit {

	minDate = new Date(); // For disabling Previous Dates from current Date//

	// requestForm: FormGroup | any;
	isSubmitted = false;

	public CLOSE_ON_SELECTED = false;
	public init = new Date();
	public resetModal = new Date();
	public model: any = [];


	@Component({
		selector: 'app-adminleave-delete-dialog',
		templateUrl: './adminleave-delete.component.html'
	})

	@ViewChild('picker', { static: true })
	_picker!: MatDatepicker<Date>;

	constructor(private _liveAnnouncer: LiveAnnouncer, public formBuilder: FormBuilder, protected http: HttpService, 
		public dialog: MatDialog, private changeDetect: ChangeDetectorRef, private NgZone: NgZone, 
		private router: Router,private renderer: Renderer2, private el: ElementRef) { }



	public dateClass = (date: Date) => {
		if (this._findDate(date) != -1) {
			return ['selected'];
		}
		return [];
	}

	public dateChanged(event: MatDatepickerInputEvent<Date>): void {
		if (event.value) {
			const date = event.value;
			const index = this._findDate(date);

			if (index === -1) {
				this.model.push(date);
			} else {
				this.model.splice(index, 1);
			}
			this.resetModal = new Date(0);
			if (!this.CLOSE_ON_SELECTED) {
				const closeFn = this._picker.close;
				this._picker.close = () => { };
				this._picker['_componentRef'].instance._calendar.monthView._createWeekCells()
				setTimeout(() => {
					this._picker.close = closeFn;
				});
			}
		}

		// console.log(this.model);

	}
	public remove(date: Date): void {
		const index = this._findDate(date);
		this.model.splice(index, 1)
		!this.model.length && this.requestForm.controls['selectDate'].setValue('');

	}
	private _findDate(date: Date): number {
		return this.model.map((m: any) => +m).indexOf(+date);
	}

	@ViewChild(MatSort) sort: MatSort | any;
	@ViewChild(MatPaginator) paginator: MatPaginator | any;
	displayedColumns: string[] = ['username', 'leavedate', 'leavereason', 'leavetype', 'admin_note', 'isapprove', 'action'];



	dataSource: any = [];
	userEditData: any = [];
	userDataDisplay: any = [];
	deleteUserId: any;
	specificUserId: any;
	searchUserData: any;
	userId = 0;
	pageSize = 50;
	pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
	currentPage = 0;
	length: 0;
	// VALIDATION CODE

	requestForm = this.formBuilder.group({
		addUserLeave: ['', [Validators.required]],
		selectDate: ['', [Validators.required]],
		adminNote: ['', [Validators.required]],
		leaveType: ['', [Validators.required]]

	});
	editForm = this.formBuilder.group({
		adminNote: [''],
		approvalSelect: ['', [Validators.required]],
		userId: [0, [Validators.required]]

	})

	get formElement(): { [key: string]: AbstractControl } {
		return this.requestForm.controls;
	}

	get editElement(): { [key: string]: AbstractControl } {
		return this.editForm.controls;
	}

	// get editElement(): { [key: string]: AbstractControl } {
	//   return this.editForm.controls;
	// }

	ngOnInit(): void {
		// console.log(this.requestForm.value);
		this.displayUserListByDropDown();
		this.leaveList()
		// this.getLeaveList({recordPerPage: this.pageSize, page: this.currentPage});
		this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId);

		$('#editModal').on('hidden.bs.modal', () => {

			this.editForm.reset();
			this.isSubmitted = false;
		});
		$('#addRequest').on('hidden.bs.modal', () => {
			this.NgZone.run(() => {
				this.requestForm.patchValue({
					addUserLeave: '',
					selectDate: '',
					adminNote: '',
					leaveType: '',
				})

				this.isSubmitted = false;
				this.model = [];
			})
			// this.changeDetect.detectChanges();
		});

	}

	private getLeaveList(searchvalue: any, pageSize: any, currentPage: any, id: any ) {
		// console.log(data);
		this.http.post(Api.url.adminleaveList, { searchtext: searchvalue, page: currentPage + 1, recordPerPage: pageSize, id: id }).then((data: any) => {
			this.dataSource = new MatTableDataSource(data.data.data);
			this.dataSource.data = data.data.data;
			this.paginator.length = data.data.total;
			this.length = data.data.total;
		});
	}


	announceSortChange(sortState: Sort) {
		if (sortState.direction) {
			this._liveAnnouncer.announce(`Sorted ${sortState.direction} ending`);
		} else {
			this._liveAnnouncer.announce('Sorting cleared');
		}
	}

	searchData(data: any) {
		this.searchUserData = data.target.value;
		if(this.specificUserId){
			// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId);
			return;
		}
		// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, 0);
	}

	// leaveReason(data: any) {}

	getSpecificUser(event: any) {
		this.specificUserId = event.target.value;
		if(this.specificUserId){
			// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId);
			return;
		}
		
		// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, 0);
	}

	displayUserListByDropDown() {
		this.http.post(Api.url.adminUserList, {}).then((data: any) => {
			if(data.success){
				// console.log(data.data[0].id);
				// if(!this.userId){
				// 	// this.getLeaveList({id: data.data[0].id});
				// 	this.userId = data.data[0].id;
				// 	this.getLeaveList({recordPerPage: this.pageSize, id: this.userId, search: this.searchUserData, page: this.currentPage })
				// }
				data.data.forEach((element: any) => {
					this.userDataDisplay.push(element)
				});
			}
		});
	}

	editData(data: any) {
		// data.editLeaveMode = true;
		this.router.navigate(['/admin/edit-leave'], { state: data });
      	// console.log("edit users data", data);
		// this.editForm.controls['userId'].setValue(data.id);
		
		// this.editForm.patchValue({
		// 	userId: data.id,
		// 	adminNote: data.admin_note,
		// 	approvalSelect: data.isapprove
		// })
		// this.userEditData = data;
	}

	deleteData(data: any) {
		this.deleteUserId = data;
	}

	deleteUserRecord() {
		let id = this.deleteUserId.id
		this.http.post(Api.url.adminDeleteLeave, { id }).then((response: any) => {
			if(response.success){
				if(this.specificUserId){
					// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId);
					return;
				}
				// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, 0);
			}
		 })
		

		setTimeout(() => {
			toastr.success('Record Deleted Successfully');
		}, 1000)

	}
	editLeaveSubmit() {
		this.isSubmitted = true;
		if (!this.editForm.valid) {
			return false
		}
		else {
			const adminNote = this.editForm.value.adminNote;
			const approveSelect = this.editForm.value.approvalSelect;
			const userId = this.editForm.value.userId;

			this.http.post(Api.url.adminEditLeave, { admin_note: adminNote, leaveid: userId, isapprove: approveSelect }).then((data: any) => {
				this.isSubmitted = false;
				if (data.success) {
					// this.getLeaveList({ id: this.specificUserId, search: this.searchUserData })
					$('#editModal').modal('hide');
					setTimeout(() => {
						toastr.success('Updated Successfully');
					}, 1000)
				}
				else {
					toastr.error(data.message)
				}
			});
		}

	}
   changePaging(event: PageEvent)
   {
	if (this.pageSize !== -1) {
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
		// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.userId)
	  }
   }


	onSubmit() {
		this.isSubmitted = true;
		if (!this.requestForm.valid) {
			return false;
		}
		else {
			if (!this.model.length) {
				this.requestForm.controls['selectDate'].setValue('');
				return;
			}
			else {
				const id = this.requestForm.value.addUserLeave;
				let selectDate = this.requestForm.value.selectDate;
				const leavereason = this.requestForm.value.adminNote;
				const leavetype = parseInt(this.requestForm.value.leaveType);
				const today = moment();

				let parseDate = this.model.map((ele: any) => moment(ele).format('YYYY-MM-DD'));
				console.log("LEAVE DATESZ________________------->", { leavedate: parseDate });
				console.log("LEAVE TYPEEEE=-------------------------->", leavetype);
				this.http.post(Api.url.adminAddLeave, { id, leavereason, leavetype, leavedate: parseDate }).then((data: any) => {
					if (data.success) {
						// this.getLeaveList({ search: this.searchUserData, id: this.specificUserId })

						$('#addRequest').modal('hide');
						setTimeout(() => {
							toastr.success(data.message);
						}, 1000)

					} else {
						toastr.error(data.message);
					}
				});

			}

		}

	}

	gotoAddLeave(){
		this.router.navigate(['/admin/add-leave']);
	}
	openModal(event: any) {
		// Get the content of the clicked <td> element
		// const tdContent = event.target.innerText;
		const tdContent = event.target.closest('td').innerText.trim(); 
	
		// Update the modal content dynamically using jQuery
		$('#modalContent').text(tdContent);  // Set the modal content with the clicked <td> content
		$('#contentModal').modal('show');  // Open the modal using jQuery
	  }



	onPageSizeChange(event: any) {
		const selectedSize = event.value;
		if (selectedSize === -1) {
			this.showAllRecords();
		} else {
		  	this.pageSize = selectedSize;
			// this.getLeaveList(this.searchData, this.pageSize, this.currentPage, this.userId);
		}
	}
	 
	showAllRecords() {
		this.pageSize = this.length; 
		this.paginator.pageSize = this.length;  
		this.paginator.pageIndex = 0; 
		this.paginator._changePageSize(this.length); 
		// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId)
	}



	leaveList() {
		const table = new DataTable('#leavetable', {
		  processing: true,
		  serverSide: true,
		  // pagingType: "simple_numbers",
		  // pageLength: 10, // Default to 10 records per page
		  "drawCallback": function (settings) {
			const api = this.api();
			const totalRecords = api.settings()[0].json.totalRecords; // Total number of records from the server
			const totalPages = Math.ceil(totalRecords / settings._iDisplayLength); // Calculate total pages
			console.log(`Total pages: ${totalPages}, Total records: ${totalRecords}`);
		  },
		  ajax: {
			url: `${Api.url.getAdminLeaveList}`,
			type: 'GET',
			headers: {
			  'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('logindata')).user.token,
			  'Content-Type': 'application/json',
	
			},
			data: function (d) {
			  // console.log(d, "data");return;
			  return {
				page: Math.floor(d.start / d.length) + 1,
				recordPerPage: d.length, 
				searchtext: d.search.value
			  };
			},
			dataSrc: function (json) {
				console.log(json, "leavedata")
			  return json.data.data;
			}
		  },  
		  columns: [
			{
			  title: 'SR No.',
			  render: function (data, type, row, meta) {
				return meta.row + 1;
			  },
			  searchable: false,
			  orderable: false
			},
			{
	
			  data: 'username',
			  title: 'Name',
			  render: function (data, type, row) {
				return data;
			  }
			},
			{
	
			  data: 'leavedate',
			  title: 'Leave date',
			  render: function (data, type, row) {
				return data;
			  }
			},
			{
	
			  data: 'leavetype',
			  title: 'Leave Type',
			  render: function (data, type, row) {
				return data;
			  }
			},
			{
	
			  data: 'admin_note',
			  title: 'Admin note',
			  render: function (data, type, row) {
				return data;
			  }
			},
			{
				data: 'isapprove',
				title: 'Status',
				render: function (data, type, row) {
				  var statusText = '';
				  var statusClass = '';
			  
				  // Determine the text and background class based on the value of 'isapprove'
				  if (data == 0) {
					statusText = 'Pending';
					statusClass = 'bg-warning'; // Background color for Pending
				  } else if (data == 1) {
					statusText = 'Approved';
					statusClass = 'bg-success'; // Background color for Approved
				  } else if (data == 2) {
					statusText = 'Decline';
					statusClass = 'bg-danger'; // Background color for Decline
				  } else {
					statusText = 'Unknown';
					statusClass = 'bg-secondary'; // Background color for Unknown (default)
				  }
			  
				  // Return the HTML with the appropriate class and text
				  return '<span class="p-1 rounded text-light ' + statusClass + '">' + statusText + '</span>';
				}
			  },
			
			{
			  title: 'Action',
			  render: (data, type, row) => {
				const rowDataString = JSON.stringify(row);
				// console.log('Serialized Row data:', rowDataString);
	
				return `
					<button class="btn example-icon edit-icon"  data-row='${rowDataString}'><i class="fa fa-edit"></i></button>
					<button class="btn example-icon delete-icon" data-bs-toggle="modal" data-bs-target="#deleteModal" data-id="${row.id}"><i class="fa fa-trash"></i></button>
				  `;
			  }
			}
		  ],
		});
		  this.renderer.listen(this.el.nativeElement, 'click', (event) => {
			if (event.target && event.target.classList.contains('edit-icon')) {
			  const rowData = JSON.parse(event.target.getAttribute('data-row'));
			  this.editData(rowData);
			} else if (event.target && event.target.classList.contains('delete-icon')) {
			  const id = event.target.getAttribute('data-id');
			  this.deleteData(id);
			}
		  });
	  }
	
}
