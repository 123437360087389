import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface TaskLog{
  name:String,
  total_time:String,
  date:String,
  created_at:String
}

@Component({
  selector: 'app-admintasklog',
  templateUrl: './admintasklog.component.html',
  styleUrls: ['./admintasklog.component.css']
})
export class AdmintasklogComponent implements OnInit {
  displayedColumns:string[] = ['id','name','total_time','date','created_at'];
  data:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<TaskLog> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  names:any;
  holiday_id:any;
  addHolidayForm = new FormGroup({
    id: new FormControl(''),
    title: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    hdate: new FormControl('',[Validators.required]),
  });
  addholidaybtn:any;
  editholidaybtn:any;
  usersname:any;

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void {
    this.TaskLogList(this.id,this.searchvalue,this.pageSize,this.currentPage);
    this.UserList();
  }

  TaskLogList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminTaskLogList,{user_id:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
      if(response.success)
     {
        this.dataSource.sort = this.sort;
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   changePaging(event: PageEvent)
   {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      const page = event.pageIndex +1;
      this.TaskLogList(this.id,this.searchvalue,this.pageSize,page);
   }

   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }

   onSelectEvent(event:any){
    // console.log(event);
    this.TaskLogList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }

}
