<div class="shadow bg-body">
  
  <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
    <div>
      <div class="fs-5">{{componentTitle}}</div>
    </div>
    <div><button class="btn" (click)="returnToProjectComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
  </div>
  <div class="">
    <div class="">
      <form [formGroup]="addProjectForm" name="addproject" class="px-2 py-2" id="myform">
        <input matInput value="" placeholder="Enter id" #message formControlName="project_id" name="project_id"
          style="visibility:hidden;">
        <mat-form-field>
          <mat-label>Project Name </mat-label>
          <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addProjectForm.get('name')?.invalid && addProjectForm.get('name')?.touched && addProjectForm.get('name')?.errors?.required )">
          ProjectName is required
        </span>
  
        <mat-form-field>
          <mat-label> Description </mat-label>
          <textarea matInput value="" placeholder="Enter Description" #message formControlName="description"
            name="description"></textarea>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addProjectForm.get('description')?.invalid && addProjectForm.get('description')?.touched && addProjectForm.get('description')?.errors?.required )">
          Description is required
        </span>
  
        <div class="row mx-0">
          <div class="col-md-6">
            <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
              <mat-label>Choose a startDate</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="startdate" name="startdate">
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
      
            <span class="text-danger"
              *ngIf="(addProjectForm.get('startdate')?.invalid && addProjectForm.get('startdate')?.touched && addProjectForm.get('startdate')?.errors?.required )">
              Startdate is required
            </span>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Is Finished </mat-label>
              <mat-select formControlName="finished">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
      
            <span class="text-danger"
              *ngIf="(addProjectForm.get('finished')?.invalid && addProjectForm.get('finished')?.touched && addProjectForm.get('finished')?.errors?.required )">
              Finished is required
            </span>
          </div>
        </div>
  
        
  
        <mat-form-field appearance="fill">
          <mat-label>Select User </mat-label>
          <mat-select formControlName="users" name="users" multiple>
            <mat-option *ngFor="let user of usersname" [value]="user.id">
              {{user.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addProjectForm.get('users')?.invalid && addProjectForm.get('users')?.touched && addProjectForm.get('users')?.errors?.required )">
          Users is required
        </span>
  
      
  
        <div mat-dialog-actions class="p-2 d-flex justify-content-end align-items-end">
          <button type="submit" *ngIf="addprojectbtn" [disabled]="addProjectForm.invalid" mat-raised-button
            color="primary" tabindex="2" (click)="addProject()">Save</button>
          <span class="margin-right-20"></span>
          <button type="submit" *ngIf="editprojectbtn" mat-raised-button color="primary" tabindex="2"
            (click)="saveProject()">Update</button>
          <span class="margin-right-20"></span>
  
          <button mat-raised-button color="warn" (click)="returnToProjectComponent()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

