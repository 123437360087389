import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { selectboxComponent } from '../layouts/selectbox/selectbox.component';
import { InputboxComponent } from '../layouts/inputbox/inputbox.component';
import { Api } from '../services/api.service';
import { HttpService } from '../services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
	selector: 'app-task-dialog',

	template: `<mat-list  >
		<mat-list-item   [ngStyle]="{'padding-bottom': '80px'}">
					<h1 class="text-center mx-auto"> Add New Task </h1>
					<mat-divider></mat-divider>
		</mat-list-item>
		<form [formGroup]="reactiveForm" (ngSubmit)="AddTask()"> 	

			<mat-form-field>
				<!-- <input  name="id" type="hidden"  formControlName="userid" name="userid"> -->
				<input matInput value="" placeholder="Enter Task Name" #message formControlName="newtask" name="newtask"> 

				<mat-error *ngIf="reactiveForm.get('newtask').hasError('required')">
					Task name is required
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<!-- <input  name="id" type="hidden"  formControlName="userid" name="userid"> -->
				<input matInput value="" placeholder="Description" #message formControlName="description" name="description"> 

				<mat-error *ngIf="reactiveForm.get('description').hasError('required')">
					Description is required
				</mat-error>
			</mat-form-field>
		</form>
		<mat-list-item class="d-flex justify-content-center" >
  <div class="button-row pt-3 text-center">
      <button mat-raised-button color="primary" [disabled]="!reactiveForm.valid"   (click)="AddTask()" >Submit</button>
			<button mat-dialog-close mat-raised-button    color="" (click)="closeForm()">
      Close
    </button>
  </div>
  </mat-list-item>

	</mat-list>`,
	styleUrls: ['./task-dialog.component.css']
})
export class TaskDialogComponent implements OnInit {



	reactiveForm = new FormGroup({
		newtask: new FormControl('', [Validators.required]),
		description: new FormControl('', [Validators.required]),

	});


	constructor(public dialogRef: MatDialogRef<TaskDialogComponent>, private formBuilder: FormBuilder, private http: HttpService, private api: Api, public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public project_id: any) {


		console.log("CHEKING MY TASKKKK------>", project_id.key);
	}
	closeForm() {

	}

	ngOnInit(): void {
	}

	openSnackBar(message: string, action: string, Color: string) {
		this.snackBar.open(message, action, {
			duration: 2000,
			verticalPosition: 'top',
			horizontalPosition: 'end',
			panelClass: [Color]
		});
	}

	AddTask() {
		const result = localStorage.getItem('logindata');
		let array = JSON.parse(result);
		const userid = array['user']['id'];

		const dataObj = {
			taskname: this.reactiveForm.value['newtask'],
			description: this.reactiveForm.value['description'],
			userid,
			projectid: this.project_id.key
		}
		this.http.post(Api.url.addnewtask, dataObj).then((response: any) => {

			if (response.success) {
				this.openSnackBar("Task Created Successfully", "", "blue-snackbar");
				this.dialogRef.close();
			}

		})

	}



}
