import { Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  constructor(private http: HttpClient, private auth: Api) {}

  get(url:any, params = {}) {
    Object.assign(params,{...Api.commonParams})
    return this.http.get(url, { params, headers:this.getHeaders()}).toPromise();
  }

  post(url:any, params = {}) {
    Object.assign(params,{...Api.commonParams})
    return this.http.post(url, params, { headers:this.getHeaders()}).toPromise();
  }

  delete(url:any, params = {}) {
    Object.assign(params,{...Api.commonParams})
    return this.http.delete(url, { params, headers:this.getHeaders()}).toPromise();
  }

  private getHeaders() {
    return new HttpHeaders(this.auth.getTradeshowHeaders());
  }
}
