import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Api } from './api.service';
// import { SocialAuthService } from 'angularx-social-login';
// import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    loginChange = new Subject()
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const Role = localStorage.getItem('ROLE');
        console.log(route.data.role)
        if (!this.isTokenExpired() && Role == route.data.role) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    canActivateChild() {

        if (!this.isTokenExpired()) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }

    redirectIfLogin() {
        if (!this.isTokenExpired()) {
            this.router.navigate(['dashboard'])
        }
        // this.router.navigate(['/login']);
        // return false;
    }
    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.isUserLoggedin()) {
          const userRole = localStorage.getItem('ROLE');
          if (route.data.role && route.data.role.indexOf(userRole) === -1) {
            this.router.navigate(['/home']);
            return false;
          }
          return true;
        }
    
        this.router.navigate(['/home']);
        return false;
      }
    // signInWithGoogle(): void {
    //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // }

    // signInWithFB() {
    //   return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // }

    login(userData: any, token: any, Admin: any) {
        const logindata = JSON.stringify({ user: userData, token, Admin: Admin });
        localStorage.setItem('logindata', logindata);
        if (!Admin) {
            localStorage.setItem('ROLE', 'Admin');
            this.router.navigate(['admin/home'])
        } else {
            localStorage.setItem('ROLE', 'User');
            this.router.navigate(['dashboard'])
        }
        this.loginChange.next(this.getLoggedUserData())

    }

    logout() {
        localStorage.removeItem('logindata');
        // this.authService.signOut();
        this.router.navigate(['login'])
        // window.location.href = "http://dashboard.emap.live/logoutaccess";
    }

    isUserLoggedin() {
        if (this.isTokenExpired()) {
            localStorage.removeItem('logindata');
            // this.authService.signOut();
            return false;
        } else {
            return true;
        }
    }

    isTokenExpired(token?: string): boolean {
        const logindata = localStorage.getItem('logindata');
        // console.log(logindata);
        if (logindata === null) {
            return true;
        }
        const logindataObj = JSON.parse(logindata);
        if (!token) { token = logindataObj.token; }
        if (!token) { return true; }

        return false;
        // const date = this.getTokenExpirationDate(token);
        // if (date === undefined || date === null) { return false; }
        // return !(date.valueOf() > new Date().valueOf());
    }

    getTokenExpirationDate(token: string): Date | null {
        const decoded: any = this.decode_token(token);

        if (decoded.exp === undefined) { return null; }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    getLoggedUserData() {
        const logindata: any = localStorage.getItem('logindata');
        if (logindata === null) {
            return;
        }

        const logindataObj = logindata ? JSON.parse(logindata) : { user: {} };
        logindataObj.user.logintoken = logindataObj.token;
        return logindataObj.user;
    }

    decode_token(token: any) {
        const str = token.split('.')[1];
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: break; case 2: output += '=='; break;
            case 3: output += '='; break;
        }
        const decoded = decodeURIComponent(atob(output).replace(/(.)/g, (m, p) => {
            let code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) { code = '0' + code; } return '%' + code;
        }));
        return JSON.parse(decoded);
    }
}
