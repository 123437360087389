<div class="main-content bg-body">
	<div class="row p-2 border-bottom section m-0">
		<div class="col-sm-4">
			<div class="col-auto">
				<label class="visually-hidden" for="search">Search</label>
				<input type="text" (keyup)="SearchLeave($event)" class="form-control" id="search" placeholder="Search">
			</div>
		</div>
		<div class="col-sm-4 mobile-none"></div>
		<div class="col-sm-4">
			<button mat-raised-button color="primary" class="leave-btn width-100" data-bs-target="#addRequest"
				(click)="AddLeave()">Add Leave</button>
		</div>
	</div>
	<table mat-table [dataSource]="dataSource" matSort>

		<!-- Position Column -->
		<ng-container matColumnDef="leavedate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Leave Date </th>
			<td mat-cell *matCellDef="let element let i = index" style="white-space: nowrap; overflow: hidden;"> {{element.leavedate}} </td>
		</ng-container>

		<ng-container matColumnDef="leavereason">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Leave Reason </th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap; overflow: hidden;"> {{element.leavereason}} </td>
		</ng-container>

		<ng-container matColumnDef="leavetype">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Leave Type</th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap; overflow: hidden;"> {{element.leavetype}} </td>
		</ng-container>

		<ng-container matColumnDef="admin_note">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Admin Note </th>
			<td mat-cell *matCellDef="let element" style="white-space: nowrap; overflow: hidden;"> {{element.admin_note}} </td>
		</ng-container>

		<ng-container matColumnDef="isapprove">

			<th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by approval" style="white-space: nowrap; overflow: hidden;"> Approval
			</th>
			<td mat-cell *matCellDef="let element">
				<span class="leave-status" [ngStyle]=" { 'color':'#fff',  'background-color':  element.isapprove==='2'?'rgb(193 109 100)': ( element.isapprove==='1'?'rgb(110 157 120)' : '#F4CE6A' ) } ">
					{{element.isapprove==='1'?'Approved': ( element.isapprove==='2'?'Declined' : 'Pending' ) }}
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
			<td mat-cell *matCellDef="let element">
				<mat-icon class="example-icon" (click)="getTimeSheetById(element.id)">edit</mat-icon>
			</td>
		</ng-container>


		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
		<mat-form-field appearance="fill">
		  <mat-label>Records per page</mat-label>
		  <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
			<mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
			<mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
		  </mat-select>
		</mat-form-field>
	  </div>
	  <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
		  [pageSizeOptions]="[50, 100, 200, 500, -1]"
		  aria-label="Select page">
	  </mat-paginator>
</div>


	<!-- <div fxLayout="row">
    <div fxFlex.gt-sm="100%">
        <mat-form-field style="width:30%" (keyup)="SearchLeave($event)">
            <input matInput value="" placeholder="Search">
        </mat-form-field>
        <button mat-raised-button color="primary" class="leave-btn" (click)="AddLeave()">Add Leave</button>
    </div>
</div> -->

	

<div class="modal hide" id="leaveModal">
		<div class="modal-dialog  modal-dialog-centered">
			<div class="modal-content">

				<div class="modal-header py-2">
					<h4 class="modal-title">Add Leave</h4>
					<button (click)="clearValue()" type="button" class="btn-close" data-bs-dismiss="modal"></button>
				</div>

				<!-- Modal body   LEAVE NEW -->

				<!-- Modal body   LEAVE OLD -->
				<div class="modal-body py-2" style="height: 353px;">
					<div mat-dialog-content>
						<form [formGroup]="addLeaveForm" name="addleave">
							<mat-grid-list cols="1" rowHeight="2:1">
								<mat-grid-tile>
									<mat-form-field>
										<mat-label>Select Leave Type</mat-label>
										<mat-select class="example-select" formControlName="leavetype">
											<mat-option *ngFor="let item of leavetypeoptions" [value]=item>{{item}}</mat-option>
										</mat-select>
									</mat-form-field>

									<span class="text-danger"
										*ngIf="(addLeaveForm.get('leavetype')?.invalid && addLeaveForm.get('leavetype')?.touched && addLeaveForm.get('leavetype')?.errors?.required )">
										LeaveType is required
									</span>


									<div [ngStyle]="{ 'margin-top':'20px', 'height': '110px', 'overflow-y': 'scroll', 'overflow-x': 'hidden'}">

										<mat-form-field (click)="picker.open()">


											<mat-label class="">Select a Leave Date</mat-label>


											<mat-chip-list #chipList aria-label="Choose a date">
												<mat-chip *ngFor="let value of model" [selectable]="true" [removable]="true"
													(removed)="remove(value)">
													{{ value | date }}
													<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
												</mat-chip>

												<div class="pt-4 ms-4">
													<input formControlName="leavedate" name="leavedate" [value]="resetModal" matInput
														[matDatepicker]="picker" [min]="minDate" (dateChange)="dateChanged($event)"
														[matChipInputFor]="chipList" hidden />
												</div>
											</mat-chip-list>

											<mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
											<mat-datepicker #picker [startAt]="init" [dateClass]="dateClass"></mat-datepicker>
										</mat-form-field>

									</div>


									<span class="text-danger"
										*ngIf="(addLeaveForm.get('leavedate')?.invalid &&  addLeaveForm.get('leavedate')?.touched && addLeaveForm.get('leavedate')?.errors?.required)">

										Date is required
									</span>


									<!-- <span class="text-danger" *ngIf="(addLeaveForm.get('leavedate')?.invalid && addLeaveForm.get('leavedate')?.touched &&
										addLeaveForm.get('leavedate')?.errors?.required )">
										Date is required
									</span> -->


									<!-- <div class="invalid-error" *ngIf="isSubmitted && formElement['leavedate'].errors?.required">
										<sup>*</sup>Select Date Please
									</div>

									<mat-form-field>
										<mat-label>Select a Leave Date</mat-label>
										<div style="display:flex">
											<input matInput [matDatepicker]="picker" [min]="minDate" formControlName="leavedate"
												name="leavedate">
											<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
											<mat-datepicker #picker></mat-datepicker>
										</div>
									</mat-form-field>
									<span class="text-danger"
										*ngIf="(addLeaveForm.get('leavedate')?.invalid && addLeaveForm.get('leavedate')?.touched && addLeaveForm.get('leavedate')?.errors?.required )">
										LeaveDate is required
									</span> -->



									<mat-form-field>
										<textarea matInput placeholder="Valid Reason" formControlName="leavereason" name="leavereason">
                        			</textarea>
									</mat-form-field>
									<span class="text-danger"
										*ngIf="(addLeaveForm.get('leavereason')?.invalid && addLeaveForm.get('leavereason')?.touched && addLeaveForm.get('leavereason')?.errors?.required )">
										Leave Reason is required
									</span>
									<div style="float:right">
										<button mat-raised-button color="primary" tabindex="2" [disabled]="addLeaveForm.invalid"
											(click)="saveLeave()">Add Leave</button>
									</div>

								</mat-grid-tile>
							</mat-grid-list>
						</form>

					</div>
				</div>
			</div>
		</div>
</div>