import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';


/** @title Responsive sidenav */
@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
	mobileQuery: MediaQueryList;
	isUserLoggedIn = true;

	private _mobileQueryListener: () => void;
	loginPage: boolean;
	logo: any;
	data: any;
	sub: any;
	title: any;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		public route: ActivatedRoute,
		private titleService: Title
	) {
		this.mobileQuery = media.matchMedia('(min-width: 768px)');
		this.loginPage = true;
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);

	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	ngAfterViewInit() {


	}

	ngOnInit() {

		// console.log(this.sub.snapshot.firstChild.routeConfig.data.title);

	}

	ngAfterContentChecked(): void {
		this.data = localStorage.getItem('logindata');
		this.sub = this.route;
		// console.log(this.sub.snapshot.firstChild.routeConfig);
		this.title = this.sub.snapshot.firstChild.routeConfig.data.title;
		let array = JSON.parse(this.data);
		this.logo = '';
		// this.logo = array['Admin']['logo'];
		// if(array['Admin']['logo']){
		//   this.logo = array['Admin']['logo'];
		// }else{
		//   this.logo = array['user']['logo'];
		// }

		// if(!this.logo){
		this.logo = './assets/images/bmac-logo.png';
		// }
		// else{
		//     this.logo = array['user']['logo'];
		//   }
	}
}

