import { Component, OnInit,NgZone } from '@angular/core';
import { FullComponent } from '../../layouts/full/full.component'
import { Api } from 'src/app/services/api.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  recentActivity: any = [];

  upcomingActivity: any = [];

  absentEmployee: any = [];
  constructor(protected http: HttpService, private NgZone: NgZone) { }

  ngOnInit(): void {
    this.http.post(Api.url.recentActivity, {}).then((data: any) => {
      this.recentActivity = data.data;
    });


    this.http.post(Api.url.upcomingActivity, {}).then((data: any) => {
      this.upcomingActivity = data.data; 
    });
    
    this.http.post(Api.url.absentEmployee, {}).then((data: any) => {
      this.absentEmployee = data.data.data;
      // console.log("this.absentEmployee", this.absentEmployee)

    });
  }

}
