import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Api } from 'src/app/services/api.service';
import { HttpService } from 'src/app/services/http.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  AbstractControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';




declare var ClassicEditor;
declare var moment;
declare var $;

@Component({
  selector: 'app-add-edit-docs',
  templateUrl: './add-edit-docs.component.html',
  styleUrls: ['./add-edit-docs.component.css'],

})

export class AddEditDocumentComponent implements OnInit, AfterViewInit {
  componentTitle = "Add Docs";
  editDocumentData: any;


  isSubmitted = false;
  deleteUserId: any;
  dataSource: any = [];
  isAdd:boolean;
  isupdate:boolean;
  id:any;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  length: any;
  pageSize = 50;
  @ViewChild(MatSort) sort: MatSort | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  currentPage: number;

  openSnackBar(message: string, action: string, Color: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private auth: Api,private httpclient:HttpClient, public snackBar: MatSnackBar ,private _liveAnnouncer: LiveAnnouncer, protected http: HttpService, public formBuilder: FormBuilder, private router: Router) { }
  CKEditor: any;
  ngOnInit(): void {

    this.isAdd = true;
    this.isupdate = false;
    this.editDocumentData = history.state;
    if(this.editDocumentData.editDocsMode){
      this.componentTitle = "Edit Docs";
      // this.CKEditor.setData(this.editDocumentData.content);
      this.editDocument(this.editDocumentData);
      console.log(this.editDocumentData);
    } else {
      this.CKEditor.setData('');
    }
    

   


  }

  ngAfterViewInit(): void {
    ClassicEditor.create(document.querySelector('#editor')).then(editor => {

      this.CKEditor = editor
      editor.model.document.on('change:data', (e, data) => {
        this.addForm.controls['content'].setValue(editor.getData());
      });

    })
      .catch(error => {
        console.error(error);
      });

  }

  private getDocumentlist(currentPage:any,pageSize:any) {
    this.http.post(Api.url.adminDocumentList,{page:currentPage,recordPerPage:pageSize}).then((data: any) => {
      this.dataSource = new MatTableDataSource(data.data.data);
      this.dataSource.sort = this.sort;
      this.paginator.length = data.data.total;
      this.length = data.data.total;
    })

  }
  changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.getDocumentlist(page,this.pageSize);
   }
  // displayedColumns: string[] = ['no', 'type', 'date', 'todate', 'gstno', 'actions'];

  addForm = this.formBuilder.group({
    addType: ['', [Validators.required]],
    selectDate: ['', [Validators.required]],
    enterTo: ['', [Validators.required]],
    gst: [''],
    content: ['', [Validators.required]],
    id: ['']

  })

  get formElement(): { [key: string]: AbstractControl } {
    return this.addForm.controls;
  }





 

  addDocument() {
    // console.log(this.addForm.value.selectDate);
    this.isSubmitted = true;
    if (!this.addForm.valid) {
      return false;
    }
    else {
      const type = this.addForm.value.addType;
      const enterTo = this.addForm.value.enterTo;
      const gst = this.addForm.value.gst;
      const date = this.addForm.value.selectDate
      const today = moment();
      const content = this.addForm.value.content

      const parseDate = moment(date).format('YYYY-MM-DD')
      // console.log(date);

      this.http.post(Api.url.adminDocumentAdd, { dtype: type, date: parseDate, content: content, gstno: gst, description: enterTo }).then((data: any) => {
        console.log(data);
        if(data.success){
          this.openSnackBar("Add Document Successfully","","green-snackbar");
          this.returnToDocsComponent();
        }
      });
      
    }
  }

  editDocument(data: any) {
    // console.log("Edut docs data",data)
    this.isupdate = true;
    this.isAdd = false;
    
    const momentDate = moment(data.date, 'YYYY-MM-DD');
    const dateObject = momentDate.toDate();
    // console.log(data.date);
    // console.log(new Date(data.date));
    let join_Array = data.date.split("-");
    const formattedDate = new Date(join_Array[2], join_Array[1] - 1, join_Array[0]);
    
    this.id = data.id;
    this.addForm.patchValue({
      addType: data.type,
      selectDate: new Date(formattedDate),
      enterTo: data.d_to,
      content: data.content,
      gst: data.isGSTNo
    });

    this.CKEditor.setData(data.content);
    console.log(data.date);

  }
  
  updateDocument()
   {
    const type = this.addForm.value.addType;
    const enterTo = this.addForm.value.enterTo;
    const gst = this.addForm.value.gst;
    const date = this.addForm.value.selectDate
    const today = moment();
    const content = this.addForm.value.content
    const parseDate = moment(date).format('YYYY-MM-DD');

     this.http.post(Api.url.adminDocumentUpdate,{ id: this.id,dtype: type, date: parseDate, content: content, gstno: gst, description: enterTo}).then((response: any) => {
      if (response.success){
         this.openSnackBar("Edit Document Successfully","","green-snackbar");
         this.returnToDocsComponent();
      }
    });
   }


  
    returnToDocsComponent(){
      this.router.navigate(['/admin/document']);
    }
}
