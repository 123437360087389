import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {MatSort, MatSortable, Sort} from '@angular/material/sort';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  // profiledata:any;
  profiledata = [];
  role: string | undefined;

  constructor(private http:HttpService,private auth:AuthGuard,private api:Api) { 
    this.ProfileData();
  }

  ngOnInit(): void {
  }
  ProfileData() {
    this.http.get(Api.url.profile).then((response: any)=>{
      // console.log(response.data[0]);
     if(response.success){
      this.profiledata = response.data[0];
      if(response.data[0]['designation'] == 1){
        this.role = "Android developer";
      }else if(response.data[0]['designation'] == 2){
        this.role = "iOS developer";
      }else if(response.data[0]['designation'] == 3){
        this.role = "Web developer";
      }else if(response.data[0]['designation'] == 4){
        this.role = "Designer";
      }else if(response.data[0]['designation'] == 5){
        this.role = "Python developer";
      }
      // console.log(this.profiledata)
     }
    }).catch(() => {
     
    });
   }
}
