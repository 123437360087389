<!-- <button (click)="onSave()">test</button> -->
<div class="bg-img login-register">
	<div class="login-register-box">
		<mat-card>
			<div class="text-center logo-section">
				<img alt="homepage" src="./assets/images/logo.png">
				<!-- <mat-card-title>Login</mat-card-title> -->
			</div>
			<mat-card-content>

				<form name="LoginForm" novalidate [formGroup]="LoginForm" (ngSubmit)="doLogin()">
					<div fxlayout="row wrap">
						<mat-form-field>
							<input type="text" matInput placeholder="Email" formControlName="email" name="email">
						</mat-form-field>
						<div
							*ngIf="LoginForm.controls['email'].invalid && (LoginForm.controls['email'].dirty || LoginForm.controls['email'].touched)"
							class="danger">
							<div *ngIf="LoginForm.controls['email'].errors.required">
								Email is required.
							</div>
						</div>

						<mat-form-field>
							<input type="password" matInput placeholder="Password" formControlName="password" name="password">
						</mat-form-field>
						<div
							*ngIf="LoginForm.controls['password'].invalid && (LoginForm.controls['password'].dirty || LoginForm.controls['password'].touched)"
							class="danger">
							<div *ngIf="LoginForm.controls['password'].errors.required">
								password is required.
							</div>
						</div>

						<button mat-raised-button color="primary" class="login-btn" [disabled]="!LoginForm.valid">Login</button>
					</div>

				</form>
			</mat-card-content>
		</mat-card>
	</div>

</div>