import { Time, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { AppCalendar } from '../../shared/calendars/attendancecalendar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


export interface Attendance {
	createdate: Date,
	createtime: Time,
	checkout: Time,
}
declare var $: any, toastr: any;
@Component({
	selector: 'app-attendance',
	templateUrl: './attendance.component.html',
	styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
	cols: any;

	gridByBreakpoint = {
		xl: 2,
		lg: 2,
		md: 2,
		sm: 1,
		xs: 1
	}
	displayedColumns: string[] = ['createdate', 'createtime', 'checkout'];
	data: any;
	status: String | undefined;
	dataSource = new MatTableDataSource<Attendance>();
	@ViewChild(MatSort)
	sort!: MatSort;
	@ViewChild(MatPaginator)
	paginator!: MatPaginator;
	currentdate: any;
	breakpoint: any;
	searchvalue: any = '';
	pageSize: any = '';
	currentPage: any = '';



	constructor(private breakpointObserver: BreakpointObserver, private http: HttpService, private auth: AuthGuard, private api: Api) {
		this.AttendanceList(this.searchvalue, this.pageSize, this.currentPage);
		this.AttendanceCalendareList();
		this.breakpointObserver.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
			Breakpoints.Medium,
			Breakpoints.Large,
			Breakpoints.XLarge,
		]).subscribe(result => {
			if (result.matches) {
				if (result.breakpoints[Breakpoints.XSmall]) {
					this.cols = this.gridByBreakpoint.xs;
				}
				if (result.breakpoints[Breakpoints.Small]) {
					this.cols = this.gridByBreakpoint.sm;
				}
				if (result.breakpoints[Breakpoints.Medium]) {
					this.cols = this.gridByBreakpoint.md;
				}
				if (result.breakpoints[Breakpoints.Large]) {
					this.cols = this.gridByBreakpoint.lg;
				}
				if (result.breakpoints[Breakpoints.XLarge]) {
					this.cols = this.gridByBreakpoint.xl;
				}
			}
		});
	}

	ngAfterViewInit() {
		this.sort.sort(({ id: 'id', start: 'asc' }) as MatSortable);
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;

		AppCalendar().init(this);

	}

	getData(start: any, end: any, self: any) {
		return new Promise(resolve => {
			this.http.get(Api.url.attendanceByUser, { start: start.unix(), end: end.unix(), })
				.then((doc: any) => {
					resolve(doc);
				});
		})
	}

	ngOnInit(): void {
		this.currentdate = formatDate(new Date(), 'dd-MM-YYYY', 'en');
	}

	AttendanceCalendareList() {
		this.http.get(Api.url.attendanceByUser).then((response: any) => {
			// console.log(response.data);
		});
	}
	searchAttendance(event: any) {
		this.searchvalue = event.target.value;

		console.log("CHEKZ--->", this.searchvalue);
		this.AttendanceList(this.searchvalue, this.pageSize, this.currentPage);
	}

	AttendanceList(searchvalue: string, pageSize: string, currentPage: string) {
		this.http.get(Api.url.attendanceList, { searchvalue, pageSize, currentPage }).then((response: any) => {
			// console.log(response.data[0]);
			if (response.success) {
				this.dataSource.data = response.data[0];

				// response.data[0].forEach(data => {
				// 	temp.push(data);
				// 	temp.push(data);  										// <- this code is extra checking for ui...
				// })
				// this.dataSource.data = temp
			}
		}).catch(() => {

		});
	}

}
