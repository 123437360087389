<mat-card>
    <div class="p-3 bg-danger position-relative">
        <h4 class="card-title  text-white m-0">Absent Employee Today
        </h4>

        <h6 class="card-subtitle text-white m-0 op-5"></h6>
        <!-- <button mat-mini-fab color="accent" class="add-contact">+</button> -->
    </div>

    <div class="message-box  Contact-box">
        <div class="message-widget contact-widget">
            <a *ngFor="let element of  absentEmployeeDataShow ">
                <!-- <div class="user-img">
                    <img [src]='contact.image' alt="user" class="img-circle">
                    <span class="profile-status pull-right"></span>
                </div> -->
                <div [ngStyle]="{'margin-right':'20px', 'margin-top':'-2px' }">
                    <mat-nav-list appAccordion>
                        <mat-icon [ngStyle]="{'color':'#FC4B6C','font-size':'18px' }" class="material-icons-outlined">
                            <!-- {{contact.icon}} -->
                            check_circle

                        </mat-icon>

                    </mat-nav-list>
                </div>
                <div class="mail-contnet">
                    <h5 [ngStyle]="{'font-size':'14px','font-weight':'250'}"> {{element.name}} </h5>
                    <span class="mail-desc"> {{element.email}}</span>
                </div>

            </a>
        </div>
    </div>
</mat-card>