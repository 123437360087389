<mat-card class="no-shadow">
  <mat-card-content>
  <mat-card-title>Toolbar</mat-card-title>
  <mat-card-subtitle>matToolbar is a container for headers, titles, or actions.<code class=""><a href="https://material.angular.io/components/toolbar/overview">Official Component</a></code></mat-card-subtitle>
  
    <p>Basic toolbar:</p>
    <mat-toolbar>My App</mat-toolbar>
    <p>The primary color toolbar:</p>
    <mat-toolbar color="primary">
      <span>Primary Toolbar</span>
      <span fxFlex></span>
      <button mat-button href="#" mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <button mat-button href="#" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
    </mat-toolbar>
    <p>Multiple row</p>
    <mat-toolbar>  
      <mat-toolbar-row>
        <span>First Row</span>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <span>Second Row</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <p>Positining toolbar</p>
    <mat-toolbar color="primary">
  <span>Application Title</span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  <span>Right Aligned Text</span>
</mat-toolbar>  
    <p>An accent toolbar using the second toolbar row:</p>
    <mat-toolbar color="accent">
      <mat-toolbar-row>
        <span>Second Line Toolbar</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <p>A primary toolbar using the third toolbar row:</p>
    <mat-toolbar class="bg-success" color="warn">
  <mat-toolbar-row>
    <span>Custom Toolbar</span>
  </mat-toolbar-row>

  <mat-toolbar-row>
    <span>Second Line</span>
    <span class="example-spacer"></span>
    <mat-icon class="example-icon">verified_user</mat-icon>
  </mat-toolbar-row>

  <mat-toolbar-row>
    <span>Third Line</span>
    <span class="example-spacer"></span>
    <mat-icon class="example-icon">favorite</mat-icon>
    <mat-icon class="example-icon">delete</mat-icon>
  </mat-toolbar-row>
</mat-toolbar>
  </mat-card-content>
</mat-card>