<div class="shadow bg-body">
  <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
    <div>
      <div><div class="fs-5">{{componentTitle}}</div></div>
    </div>
    <div><button class="btn" (click)="returnToInventoryComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
  </div>
  <form [formGroup]="addInventoryForm" name="adduser" class="p-4"  id="myform">
    <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id" style="visibility:hidden;" >
    <mat-form-field>
      <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
    </mat-form-field>

    <span class="text-danger"
       *ngIf="(addInventoryForm.get('name')?.invalid && addInventoryForm.get('name')?.touched && addInventoryForm.get('name')?.errors?.required )">
           Name is required
    </span>

    <mat-form-field>
      <input matInput value="" placeholder="Enter Description" #message formControlName="description" name="description">
    </mat-form-field>

    <span class="text-danger"
       *ngIf="(addInventoryForm.get('description')?.invalid && addInventoryForm.get('description')?.touched && addInventoryForm.get('description')?.errors?.required )">
         Description is required
    </span>

    <mat-form-field>
      <input matInput value="" placeholder="Material..." #message formControlName="giventotheuser" name="giventotheuser">
    </mat-form-field>

    <span class="text-danger"
    *ngIf="(addInventoryForm.get('giventotheuser')?.invalid && addInventoryForm.get('giventotheuser')?.touched && addInventoryForm.get('giventotheuser')?.errors?.required )">
        Given To User is required
    </span> 

    <mat-form-field class="example-full-width" appearance="fill" (click)="picker2.open()">
      <mat-label>Inventory Date</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="date_inventory" name="date_inventory">
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <input matInput value="" placeholder="Enter Status" #message formControlName="status" name="status">
      </mat-form-field>

      <span class="text-danger"
      *ngIf="(addInventoryForm.get('status')?.invalid && addInventoryForm.get('status')?.touched && addInventoryForm.get('status')?.errors?.required )">
         Status is required
      </span> 

    <div mat-dialog-actions class="d-flex align-items-end justify-content-end">
        <button type="submit" *ngIf="addinventorybtn" [disabled]="addInventoryForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addResume()">Save</button>
        <span class="margin-right-20"></span>
        <button type="submit" *ngIf="editinventorybtn" mat-raised-button color="primary" tabindex="2" (click)="updateInventory()">Update</button>
        <span class="margin-right-20"></span>
        <button  mat-raised-button color="warn" (click)="returnToInventoryComponent()">Cancel</button> 
    </div>
  </form>
</div>