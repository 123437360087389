import { Component } from '@angular/core';
import { AuthGuard } from './services/auth.guard';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase, ref, set, onValue, push, DataSnapshot, remove, onChildChanged, off, onChildAdded} from "firebase/database";
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpService } from './services/http.service';
import { Api } from './services/api.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	isLoggedIn = false;
	fireapp: any;
	db:any;
	loginUserID: any;
	isChatPage = window.location.pathname.startsWith('/chat')?true:false;
	userList: any;
	constructor(private auth: AuthGuard, private router: Router, private httpService: HttpService) {
		this.router.events.subscribe(() => {
			this.isChatPage = window.location.pathname.startsWith('/chat')?true:false;
		})
		this.fireapp = initializeApp(environment.firebase);
		this.db = getDatabase(this.fireapp);
		this.isLoggedIn = this.auth.isUserLoggedin();
		this.auth.loginChange.subscribe(() => {
			this.isLoggedIn = this.auth.isUserLoggedin();
		})

	}

	firebaseKey_personal_chat = 'bmac_erp_chat_board/personal_chat';
	firebaseKey_recent_chat = 'bmac_erp_chat_board/recent_chats';
	firebaseKey_group_chat_list = 'bmac_erp_chat_board/group_chat/group_list';
	firebaseKey_user_groups = 'bmac_erp_chat_board/group_chat/user_groups';
	ngOnInit(): void {
	}
	
	ngAfterViewInit(): void {
		if(localStorage.getItem('logindata')){
			this.loginUserID = JSON.parse(localStorage.getItem('logindata')).user.id;
		
			let erpChatRoomsRef = ref(this.db, `${this.firebaseKey_recent_chat}/user_${this.loginUserID}`);
			onChildChanged(erpChatRoomsRef, (snapshot) => {
				console.log('notification should trigger ======', snapshot.val())
				const chats = snapshot.val();
				if(chats.sender_id !== this.loginUserID && !this.isChatPage) {
					this.notifyMe({
						title: chats.sender_name,
						body: chats.last_message
					});
				}
			});
			onChildAdded(erpChatRoomsRef, (snapshot) => {
				console.log('notification should trigger ======', snapshot.val())
				const chats = snapshot.val();
				if(chats.sender_id !== this.loginUserID && !this.isChatPage) {
					this.notifyMe({
						title: chats.sender_name,
						body: chats.last_message
					});
				}
			});
		}
	}
	
	notifyMe(data) {
		console.log("Inside nOtify me", Notification.permission)
		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		} else if (Notification.permission === "granted") {
			console.log("Inside nOtify me granted")
			this.sendNotification(data)
		} else if (Notification.permission !== "denied") {
			console.log("Inside nOtify me not eual to denied")
			Notification.requestPermission().then((permission) => {
				console.log("Inside nOtify me after permission get")
				if (permission === "granted") {
					this.sendNotification(data);
				}
			});
		}
	}

	sendNotification(data) {
		const notification =  new Notification(data.title, {
			body: data.body,
			icon: "./assets/images/bmac-dark-logo.png",
			// renotify: true,
			tag:"bmac-chat",
			data: { userId: 101, messageId: 501 },
		});
		setTimeout(() => notification.close(), 5000)
		notification.onclick = (event:any) => {
			console.log("Message ID: " + event.target.data.messageId);
			const win = open("about:blank");
			if (!win) return;
			win.opener.focus();
			win.close();
		};
	}
}
