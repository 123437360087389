import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked,NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AfterViewInit, ElementRef, Renderer2 } from '@angular/core';

declare var $:any;
declare var DataTable;
export interface Resume{
  name:string,
  department:string,
  contact_no:Number,
  address:String,
  experince:String,
  iscontacted:string,
  dateofcontact:Date
}

interface Role {
  value: string;
  id: string;
}
const ELEMENT_DATA:Resume[] = [];
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  displayedColumns:string[] = ['id','name','department','contact_no','address','experience','iscontacted','dateofcontact','action'];
  data:any;
  addresumebtn:any;
  editresumebtn:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Resume> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  resumeid:any;
  addResumeForm = new FormGroup({
    resumeid: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    department:new FormControl('',[Validators.required]),
    phone:new FormControl('',[Validators.required]),
    address:new FormControl('',[Validators.required]),
    experience:new FormControl('',[Validators.required]),
    iscontacted:new FormControl(''),
    dateofcontact:new FormControl('')
  });
  contact_contition:any;
  title_section:string;

  desOptions: Role[] = [
    {value: 'Android Developer', id: '1'},
    {value: 'iOS Developer', id: '2'},
    {value: 'Web Developer', id: '3'},
    {value: 'Designer', id: '4'},
    {value: 'Python Developer', id: '5'}
    ];

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  
  constructor(private NgZone: NgZone,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,
    private auth:AuthGuard,private api:Api, private router: Router, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void 
  {
    this.addResumeForm.reset();
    this.title_section = "Add Resume";
    this.contact_contition = false;
    this.addresumebtn = true;
    // this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
    this.resumeTable();
  }
  
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onSelectEvent(value: any){
    // console.log(value);
    if(value == "Yes"){
      this.contact_contition = true;
    }else{
      this.contact_contition = false;
    }
  }
  ResumeList(searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminResumeList,{searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
      // console.log(response.data.total);
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   addResume(){
    this.http.post(Api.url.adminResumeAdd, this.addResumeForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Resume Successfully","","green-snackbar");
         $("#ResumeModal").modal('hide');
         this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

   editResume(element:any)
   {
      element.editResumeMode = true;
      // console.log("edit users data", element);
      this.router.navigate(['/admin/add-edit-resumes'], { state: element });

      // this.addresumebtn = false;
      // this.editresumebtn = true;
      // $("#ResumeModal").modal('show');
      // this.title_section = "Update Resume";

      // let index = this.desOptions.findIndex(x => x.value === element.department);
      
      // this.addResumeForm.controls['resumeid'].setValue(element['id']);
      // this.addResumeForm.controls['name'].setValue(element['name']);
      // this.addResumeForm.controls['department'].patchValue(this.desOptions[index]['id']);
      // this.addResumeForm.controls['phone'].setValue(element['phone']);
      // this.addResumeForm.controls['address'].setValue(element['address']);
      // this.addResumeForm.controls['experience'].setValue(element['experience']);
      // this.addResumeForm.controls['iscontacted'].setValue(element['iscontacted']);
      // if(element['iscontacted'] == "Yes"){
      //   this.contact_contition = true;
      //   let join_Array =  element['dateofcontact'].split("-");
      //   const contactdate = new Date(join_Array[2], join_Array[1], join_Array[0]);
      //   this.addResumeForm.controls['dateofcontact'].setValue(contactdate);
      // }
   }

   updateResume()
   {
    this.http.post(Api.url.adminUpdateResume, this.addResumeForm.value).then((response: any) => {
      if (response.success) 
      {
         this.openSnackBar("Edit Resume Successfully","","green-snackbar");
         $("#ResumeModal").modal('hide');
         this.addResumeForm.reset();
         this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
         this.editresumebtn = false;
         this.addresumebtn = true;
         this.title_section = "Add Resume";
      }
    });

   }

   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    // console.log(this.currentPage+1);
    this.ResumeList(this.searchvalue,this.pageSize,page);
   }

   SearchResume(event:any)
   {
    this.searchvalue = event.target.value;
    this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
   }

   deleteResume(id:any){
    // console.log(id);
    this.resumeid = id;
    $("#delresume").modal('show');

   }

   deleteresume()
   {
    this.http.post(Api.url.adminResumeDelete,{resumeid:this.resumeid}).then((response: any)=>{
      $("#delresume").modal('hide');
      this.ResumeList(this.searchvalue,this.pageSize,this.currentPage);
      this.openSnackBar("Delete Resume Successfully","","green-snackbar");
    });

   }
  
   goToAddResumeComponent(){
    this.router.navigate(['/admin/add-edit-resumes']);
   }



   resumeTable() {
    const table = new DataTable('#resumeDataTable', {
      processing: true,
      serverSide: true,
      // pagingType: "simple_numbers",
      // pageLength: 10, // Default to 10 records per page
      "drawCallback": function (settings) {
        const api = this.api();
        const totalRecords = api.settings()[0].json.totalRecords; // Total number of records from the server
        const totalPages = Math.ceil(totalRecords / settings._iDisplayLength); // Calculate total pages
        console.log(`Total pages: ${totalPages}, Total records: ${totalRecords}`);
      },
      ajax: {
        url: `${Api.url.getAdminResumeList}`,
        type: 'GET',
        headers: {
          'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('logindata')).user.token,
          'Content-Type': 'application/json',

        },
        data: function (d) {
          // console.log(d, "data");return;
          return {
            page: Math.floor(d.start / d.length) + 1,
            recordPerPage: d.length, 
            searchtext: d.search.value
          };
        },
        dataSrc: function (json) {
          console.log((json), "resume list")
          return json.data;
        }
      },  
      columns: [
        {
          title: 'SR No.',
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
          searchable: false,
          orderable: false
        },
        {
          title: 'Name',
          render: function (data, type, row, meta) {
            return row.name;
          },
        },
        {
          title: 'Department',
          render: function (data, type, row, meta) {
            return row.department;
          },
        },
        {
          title: 'Contact No.',
          render: function (data, type, row, meta) {
            return row.phone;
          },
        },
        {
          title: 'Address',
          render: function (data, type, row, meta) {
            return row.address;
          },
        },
        {
          title: 'Experience',
          render: function (data, type, row, meta) {
            return row.experience;
          },
        },
        {
          title: 'Is contacted',
          render: function (data, type, row, meta) {
            return row.iscontacted;
          },
        },
        {
          title: 'Date of contact',
          render: function (data, type, row, meta) {
            return row.dateofcontact;
          },
        },
        {
          title: 'Action',
          render: (data, type, row) => {
            const rowDataString = JSON.stringify(row);
            // console.log('Serialized Row data:', rowDataString);

            return `
                <button class="btn example-icon edit-icon" data-row='${rowDataString}'><i class="fa fa-edit"></i></button>
                <button class="btn example-icon delete-icon" data-id="${row.id}"><i class="fa fa-trash"></i></button>
              `;
          }
        }
      ],
    });
      this.renderer.listen(this.el.nativeElement, 'click', (event) => {
        if (event.target && event.target.classList.contains('edit-icon')) {
          const rowData = JSON.parse(event.target.getAttribute('data-row'));
          this.editResume(rowData);
        } else if (event.target && event.target.classList.contains('delete-icon')) {
          const id = event.target.getAttribute('data-id');
          this.deleteResume(id);
        }
      });
  }

}
