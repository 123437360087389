<div fxLayout="row wrap">

    <div fxFlex.gt-lg="25" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">
        <app-up-coming-events [upcomingEventData]="upcomingActivity" *ngIf="upcomingActivity.length>0">
        </app-up-coming-events>
        <!-- <div *ngIf="upcomingActivity.length==0">No upcoming events</div> -->
    </div>

    <div fxFlex.gt-lg="25" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">
        <app-absent-employee-today [absentEmployeeDataShow]="absentEmployee" *ngIf="absentEmployee.length>0">
        </app-absent-employee-today>
    </div>
    <div fxFlex.gt-lg="25" fxFlex.gt-md="33" fxFlex.gt-xs="100" fxFlex="100">
        <app-recent-activity [recentActivityData]="recentActivity" *ngIf="absentEmployee.length>0">
        </app-recent-activity>
    </div>
</div>