import { Component, Input, OnInit } from '@angular/core';
import { recentData } from './recent-activity-data';
@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.component.html',

})
export class RecentActivityComponent implements OnInit {

  // contactsData: Contact[];
  @Input() recentActivityData: recentData[] = [];
  constructor() {
    // this.contactsData = contacts;
  }

  ngOnInit(): void {
    console.log(this.recentActivityData, "recentActivityData");
    
  }

}
