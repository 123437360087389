import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import {HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { AuthGuard } from '../../services/auth.guard';
import { Api } from '../../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {
  durationInSeconds = 5;
  isUserLoggedIn = false;
  options:any;

  constructor(private http:HttpService,private auth:AuthGuard,private api:Api,private fb: FormBuilder,public snackBar: MatSnackBar) { 
    this.auth.redirectIfLogin();
    this.createForm();
   }
   openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  LoginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  ngOnInit(): void {
    this.options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
  }
  createForm() {
    this.LoginForm = this.fb.group({
       email: ['', Validators.required],
       password: ['', Validators.required ]
    });
  }
  doLogin() {
    this.http.post(Api.url.adminLogin,this.LoginForm.value).then((response: any)=>{
     if(response.success){
      this.auth.login(response.data[0],response.data[0].token,response.data[0]['Admin']);
      this.openSnackBar("Login successfully.","","blue-snackbar");
     
     }
    }).catch((error) => {
  
     this.openSnackBar(error.message,"",'red');
    });
   }
}
