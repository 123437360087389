import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Api } from 'src/app/services/api.service';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-theme',
  templateUrl: './change-theme.component.html',
  styleUrls: []
})
export class ChangeThemeComponent implements OnInit {

  changeThemeForm = new FormGroup({
    theme_color: new FormControl('#1e88e5', Validators.required),
    back_color: new FormControl('#26c6da', Validators.required),
    menu_color: new FormControl('#ffffff', Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<ChangeThemeComponent>,
    private http: HttpService,
    private api: Api,
    private snackBar: MatSnackBar
  ) { }

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  ngOnInit() {
    this.loadCurrentTheme();
  }


  loadCurrentTheme() {
    this.http.get(Api.url.adminDetail).then((response: any) => {
      if (response.success) {
        const themeColor = response.data['theme_color'];
        const backColor = response.data['back_color'];
        const menuColor = response.data['menu_color'];


        this.applyTheme(themeColor, backColor, menuColor);


        this.changeThemeForm.setValue({
          theme_color: themeColor,
          back_color: backColor,
          menu_color: menuColor
        });
      }
    });
  }


  applyTheme(themeColor: string, backColor: string, menuColor: string) {

    const toolbar = document.querySelector('.mat-toolbar.mat-primary') as HTMLElement;
    if (toolbar) {
      toolbar.style.backgroundColor = themeColor;
    }


    const root = document.documentElement;
    root.style.setProperty('--themecolor-alt', backColor);
    root.style.setProperty('--menu-color', menuColor);
  }


  changeTheme() {
    if (this.changeThemeForm.valid) {
      const formData = this.changeThemeForm.value;
      this.http.post(Api.url.adminThemeChange, formData).then((response: any) => {
        if (response.success) {

          this.applyTheme(response.data.theme_color, response.data.back_color, response.data.menu_color);


          // this.snackBar.open('Theme changed successfully', '', { duration: 3000 });
          this.openSnackBar("Theme changed successfully","","green-snackbar");


          this.dialogRef.close();
        }
      });
    } else {

      // this.snackBar.open('Please complete the form correctly', '', { duration: 3000 });
      this.openSnackBar("Please complete the form correctly","","green-snackbar");
    }
  }


  DefaultTheme() {
    const defaultTheme = {
      theme_color: '#1e88e5',
      back_color: '#26c6da',
      menu_color: '#ffffff'
    };

    this.http.post(Api.url.adminThemeChange, defaultTheme).then((response: any) => {
      if (response.success) {

        this.applyTheme(response.data.theme_color, response.data.back_color, response.data.menu_color);


        // this.snackBar.open('Default theme applied successfully', '', { duration: 3000 });
        this.openSnackBar("Default theme applied successfully","","green-snackbar");


        this.dialogRef.close();
      }
    });
  }
}
