import { Time } from '@angular/common';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeavemodalComponent } from '../../layouts/leavemodal/leavemodal.component'
import { selectboxComponent } from '../../layouts/selectbox/selectbox.component';
import { FormControl, FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';



declare var $: any;
declare var moment;
declare var toastr;
export interface Leave {
	leavedate: Date,
	leavereason: string,
	leavetype: Time,
	admin_note: String,
	isapprove: String,
}

@Component({
	selector: 'app-leave',
	templateUrl: './leave.component.html',
	styleUrls: ['./leave.component.css']
})
export class LeaveComponent implements OnInit {
	displayedColumns: string[] = ['leavedate', 'leavereason', 'leavetype', 'admin_note', 'isapprove'];
	data: any;
	status: String | undefined;
	totalRows = 0;
	pageSize = 50;
	currentPage = 0;
	pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
	dataSource = new MatTableDataSource<Leave>();
	@ViewChild(MatSort)
	sort!: MatSort;
	@ViewChild(MatPaginator)
	paginator!: MatPaginator;
	searchvalue: any = '';
	length: any;


	addLeaveForm = new FormGroup({
		leavetype: new FormControl('', [Validators.required]),
		leavedate: new FormControl('', [Validators.required]),
		leavereason: new FormControl('', [Validators.required])
	});
	leavetypeoptions: any;
	minDate: Date;

	isSubmitted = false;
	public model: any = [];
	searchUserData: any;
	specificUserId: any;
	userDataDisplay: any = [];

	public resetModal = new Date();
	public CLOSE_ON_SELECTED = false;
	public init = new Date();

	@ViewChild('picker', { static: true })
	_picker!: MatDatepicker<Date>;


	public dateClass = (date: Date) => {
		if (this._findDate(date) != -1) {
			return ['selected'];
		}
		return [];
	}

	openSnackBar(message: string, action: string, Color: string) {
		this.snackBar.open(message, action, {
			duration: 2000,
			verticalPosition: 'top',
			horizontalPosition: 'end',
			panelClass: [Color]
		});
	}

	constructor(public snackBar: MatSnackBar, public formBuilder: FormBuilder, public dialogbox: MatDialog, private http: HttpService, private auth: AuthGuard, private api: Api, private NgZone: NgZone) {
		this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);
		this.leavetypeoptions = ['Half Day Leave', 'Full Day Leave'];
		this.minDate = new Date();
	}

	// get formElement(): { [key: string]: AbstractControl } {
	// 	return this.requestForm.controls;
	// }

	private _findDate(date: Date): number {
		return this.model.map((m: any) => +m).indexOf(+date);
	}


	private getLeaveList(data: any) {
		console.log("CHEKZ------->", data);


		this.http.post(Api.url.adminleaveList, data).then((data: any) => {
			console.log("CHEKING MU NEW LIST -------------->", data.data.data);
			this.dataSource = new MatTableDataSource(data.data.data);
			this.dataSource.sort = this.sort;
			this.dataSource.paginator = this.paginator;
		});
	}

	ngAfterViewInit() {
		this.sort.sort(({ id: 'id', start: 'asc' }) as MatSortable);
		this.dataSource.sort = this.sort;
	}
	ngOnInit(): void {
		// this.getLeaveList({});

		$('#leaveModal').on('hidden.bs.modal', () => {
			this.NgZone.run(() => {
				this.addLeaveForm.patchValue({
					leavetype: '',
					leavedate: '',
					leavereason: '',

				})
				this.model = [];
			})
		});
	}

	SearchLeave(event: any) {
		this.searchvalue = event.target.value;
		this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);
	}
	LeaveList(searchvalue: any, pageSize: any, currentPage: any) {

		if(this.searchvalue.match('Fu') || this.searchvalue.match('fu')){
			this.searchvalue = 1;
		} else if(this.searchvalue.match('Ha') || this.searchvalue.match('ha')) {
			this.searchvalue = 0;
		}
		this.http.get(Api.url.leaveList, { search: this.searchvalue, page: currentPage, recordPerPage: pageSize }).then((response: any) => {
			console.log("Search value",this.searchvalue)
			if (response.success) {

				this.dataSource.data = response.data.data;
				this.paginator.length = response.data.total;
				this.length = response.data.total;

				console.log("CHEKING LEAVESS------->", response.data.data);

			}
		}).catch((error) => {

		});
	}

	clearValue() {
		this.addLeaveForm.reset();
		this.model = [];
	}

	public remove(date: Date): void {
		const index = this._findDate(date);
		this.model.splice(index, 1)
		if (!this.model.length) {
			this.addLeaveForm.controls['leavedate'].setValue('');
		}
		// !this.model.length && this.addLeaveForm.controls['selectDate'].setValue('');

	}




	changePaging(event: PageEvent) {
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex + 1;
		this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);
	}
	AddLeave() {
		$("#leaveModal").modal('show');
	}

	saveLeave() {
		// console.log("hello");

		const leavetype = this.addLeaveForm.get('leavetype')?.value;
		const leavedate = this.model.map((ele: any) => moment(ele).format('YYYY-MM-DD')).join(',');
		const leavereason = this.addLeaveForm.get('leavereason')?.value;

		this.http.post(Api.url.addleave, { leavetype, leavedate, leavereason }).then((response: any) => {

			if (response.success) {
				this.addLeaveForm.reset();
				this.model = [];
				$("#leaveModal").modal('hide');
				this.openSnackBar("Leave Add Successfully", "", "green-snackbar");
				this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);

			} else {
				// $("#leaveModal").modal('hide');
				this.openSnackBar(response.message, "", "green-snackbar");
			}
		});
	}

	public dateChanged(event: MatDatepickerInputEvent<Date>): void {
		if (event.value) {
			const date = event.value;
			const index = this._findDate(date);

			if (index === -1) {
				this.model.push(date);
			} else {
				this.model.splice(index, 1);
			}
			this.resetModal = new Date(0);
			if (!this.CLOSE_ON_SELECTED) {
				const closeFn = this._picker.close;
				this._picker.close = () => { };
				this._picker['_componentRef'].instance._calendar.monthView._createWeekCells()
				setTimeout(() => {
					this._picker.close = closeFn;
				});
			}
		}

	}


	onPageSizeChange(event: any) {
		const selectedSize = event.value;
		if (selectedSize === -1) {
			this.showAllRecords();
		} else {
		  	this.pageSize = selectedSize;
			  this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);
        // this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
		}
	}
	 
	showAllRecords() {
		this.pageSize = this.length; 
		this.paginator.pageSize = this.length;  
		this.paginator.pageIndex = 0; 
		this.paginator._changePageSize(this.length);
		this.LeaveList(this.searchvalue, this.pageSize, this.currentPage);
    // this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage); 
	}
}
