<h4>Manage Menu</h4>
<form class="example-form" [formGroup]="Setting_Menu" name="adduser"  id="myform">
  <!-- <mat-slide-toggle
  class="example-margin"
  [checked]="checked"
 >
Slide me!
</mat-slide-toggle> -->
    <mat-slide-toggle formControlName="Resumes" name="Resumes" (change)="changed($event)">Resumes</mat-slide-toggle><br/>
    <mat-slide-toggle  formControlName="Timesheet" name="Timesheet" (change)="changed($event)">Timesheet</mat-slide-toggle><br/>
    <mat-slide-toggle formControlName="Attendance" name="Attendance" (change)="changed($event)">Attendance</mat-slide-toggle><br/>
    <mat-slide-toggle formControlName="Machine" name="Machine" (change)="changed($event)">Machine</mat-slide-toggle><br/>
    <mat-slide-toggle formControlName="Tasklogs" name="Tasklogs" (change)="changed($event)">Tasklogs</mat-slide-toggle><br/>
    <mat-slide-toggle formControlName="Inventory" name="Inventory" (change)="changed($event)">Inventory</mat-slide-toggle><br/>
    <mat-slide-toggle formControlName="Document" name="Document" (change)="changed($event)">Document</mat-slide-toggle><br/>
</form>


<div mat-dialog-actions style="float: right;">
    <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="settingMenu()">Save</button>
    <span class="margin-right-20"></span>
    <button mat-button mat-dialog-close  mat-raised-button color="warn">Cancel</button> 
</div>