<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<router-outlet>
	<app-spinner></app-spinner>
</router-outlet>
<ng-container *ngIf="isLoggedIn && !isChatPage">
	<!-- <div class="chat-icon">
		<button type="button" id="chat-here" class="btn btn-primary d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModal2" style="height: 46px; width: 46px; border-radius: 40px;">
			<mat-icon style="height: 30px; width: 30px; font-size: 30px;"> chat</mat-icon>
		</button>
	</div>
	<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog">
		  <div class="modal-content">
			<div class="modal-header px-3 py-2 border">
			  <div class="modal-title fs-5" id="exampleModalLabel">Chat</div>
			  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<app-firestore-chat ></app-firestore-chat>
			</div>
			<div class="modal-footer px-3 py-2 border">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
			</div>
		  </div>
		</div>
	</div> -->
</ng-container>