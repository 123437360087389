<div class="shadow bg-body">
  <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
    <div><div class="fs-5">{{componentTitle}}</div></div>
    <div><button class="btn" (click)="returnToResumeComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
  </div>
  <div class="mt-5">
    <div class="">
      <form [formGroup]="addResumeForm" name="adduser" class="p-l-20" style="margin-top:-45px;" id="myform">
        <input matInput value="" placeholder="Enter id" #message formControlName="resumeid" name="resumeid" style="visibility:hidden;" >


        <div class="row mx-0">
          <div class="col-md-6 px-0">
            <mat-form-field>
              <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addResumeForm.get('name')?.invalid && addResumeForm.get('name')?.touched && addResumeForm.get('name')?.errors?.required )">
                   Name is required
            </span>
          </div>
          <div class="col-md-6">

            <mat-form-field>
              <input matInput value="" placeholder="Enter contact Number" #message formControlName="phone" name="phone">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addResumeForm.get('phone')?.invalid && addResumeForm.get('phone')?.touched && addResumeForm.get('phone')?.errors?.required )">
                Contact Number is required
            </span>
          </div>
        </div>

      
  
        <mat-form-field appearance="fill">
          <mat-label>Department </mat-label>
          <mat-select formControlName="department">
            <mat-option *ngFor="let des of desOptions" [value]="des.id">
              {{des.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
       
        <span class="text-danger"
          *ngIf="(addResumeForm.get('department')?.invalid && addResumeForm.get('department')?.touched && addResumeForm.get('department')?.errors?.required )">
          Department is required
        </span>  
  
  
        <mat-form-field>
          <textarea matInput value="" placeholder="address" #message formControlName="address" name="address"></textarea>
        </mat-form-field>
  
        <span class="text-danger"
        *ngIf="(addResumeForm.get('address')?.invalid && addResumeForm.get('address')?.touched && addResumeForm.get('address')?.errors?.required )">
           Address is required
        </span> 

        <div class="row mx-0">
          <div class="col-md-6 px-0">
            <mat-form-field appearance="fill">
              <mat-label>Experience </mat-label>
              <mat-select formControlName="experience">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
      
            <span class="text-danger"
              *ngIf="(addResumeForm.get('designation')?.invalid && addResumeForm.get('designation')?.touched && addResumeForm.get('designation')?.errors?.required )">
                Experience is required
            </span>  
          </div>
          <div class="col-md-6">

            <mat-form-field appearance="fill">
              <mat-label>IsContacted </mat-label>
              <mat-select formControlName="iscontacted" (selectionChange)="onSelectEvent($event.value)">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
      
            <!-- <mat-form-field *ngIf="contact_contition">
              <input matInput value=""  placeholder="dateofcontact" #message formControlName="dateofcontact" name="dateofcontact">
            </mat-form-field> -->
      
            <mat-form-field *ngIf="contact_contition" class="example-full-width" appearance="fill" (click)="picker2.open()">
              <mat-label>Contact Date</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="dateofcontact" name="dateofcontact">
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
       
  
  
        <div class="d-flex align-items-end justify-content-end p-2" mat-dialog-actions>
            <button type="submit" *ngIf="addresumebtn" [disabled]="addResumeForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addResume()">Save</button>
            <span class="margin-right-20"></span>
            <button type="submit" *ngIf="editresumebtn" mat-raised-button color="primary" tabindex="2" (click)="updateResume()">Update</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" (click)="returnToResumeComponent()">Cancel</button> 
        </div>
      </form>
    </div>
  </div>
</div>
