import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase, ref, off, set, onValue, push, DataSnapshot, remove, query, orderByChild} from "firebase/database";
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
// import { v4 as uuidv4 } from 'uuid';
import { Chat } from '../../chat/chat';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from '../services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { BehaviorSubject, ObservedValueOf, Subject } from 'rxjs';
import * as Push from 'push.js';
import { FirebaseService } from './firebase.service';

declare const $;
@Component({
	selector: 'app-firestore-chat',
	templateUrl: './firestore-chat.component.html',
	styleUrls: ['./firestore-chat.component.css']
})
export class FirebaseChatComponent implements OnInit {
	
	@ViewChildren('chatMessages') chatMessages:QueryList<ElementRef>;
	@ViewChild('chatScroller') chatScroller:ElementRef;
	title = 'fire-chat';
	app: FirebaseApp;
	db: Database;
	groupSaveform = new FormGroup({
		group_name: new FormControl('',[Validators.required]),
		members: new FormControl([],[Validators.required])
	});
	groupMemberform = new FormGroup({
		members: new FormControl([],[Validators.required])
	});
	username = '';
	message = '';
	chats: Chat[] = [];
	isChatVisible = false;
	selected = 'None';
	userList: any;
	loginUserID: any;
	loginUserName: any;
	selectedUserName: any;
	selectedUserID: any;
	messageUniqueID: any;
	chatRoomData: any;
	chatRoomExistsStatus = false;
	OneToOneUserChatData: any;
	chatRoomID: any;
	checkInStatus:false;
	groupChatData: any;
	addUsersToGroups: any;
	selectedUsersForGroup: any;
	currentUserGroupList: any = [];
	groupUniqueID: any;
	groupChathistroy:any = "No Messages yet";
	erpTestGroupChatHistroy: any;
	noGroupChatHistroyFound = true;
	chatBoxTitle: any;
	isGroupChat: any;
	isGroupUsers = false;
	groupKeyForGroupUserList: any;
	groupUsersListToshow = [];
	activeGroupDetails = [];
	currentGroupUsreList: any;
	addNewUsersToGroups: any;
	addNewMembersToGroupList = [];
	groupCreatorId: any;
	userRecentChatsList: any;
	pushNotvari: false;
	handleChatNotication = false;
	recentChatDataForNotification: any;
	colorPairs = {
		A: { background: "#FFDAB9", text: "#8B4513" }, B: { background: "#E6E6FA", text: "#4B0082" }, C: { background: "#B0E0E6", text: "#2F4F4F" }, D: { background: "#FFFFE0", text: "#8B8000" }, E: { background: "#F5F5DC", text: "#8B4513" }, F: { background: "#F0FFF0", text: "#006400" }, G: { background: "#FFFACD", text: "#8B4513" }, H: { background: "#F0F8FF", text: "#00008B" }, I: { background: "#FFF0F5", text: "#800000" }, J: { background: "#FFF5EE", text: "#8B4513" }, K: { background: "#FFE4E1", text: "#800000" }, L: { background: "#E0FFFF", text: "#2F4F4F" }, M: { background: "#FFEBCD", text: "#8B4513" }, N: { background: "#FAFAD2", text: "#8B8000" }, O: { background: "#FAEBD7", text: "#8B4513" }, P: { background: "#FFEFD5", text: "#8B4513" }, Q: { background: "#FDF5E6", text: "#8B4513" }, R: { background: "#FFFAF0", text: "#8B0000" }, S: { background: "#DCDCDC", text: "#2F4F4F" }, T: { background: "#AFEEEE", text: "#2F4F4F" }, U: { background: "#FFF8DC", text: "#8B4513" }, V: { background: "#F5F5F5", text: "#2F4F4F" }, W: { background: "#F5F5F5", text: "#2F4F4F" }, X: { background: "#F5F5F5", text: "#2F4F4F" }, Y: { background: "#F5F5F5", text: "#2F4F4F" }, Z: { background: "#F5F5F5", text: "#2F4F4F" },
	};

	@ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
	
	firebaseKey_personal_chat = 'bmac_erp_chat_board/personal_chat';
	firebaseKey_recent_chat = 'bmac_erp_chat_board/recent_chats';
	firebaseKey_group_chat_list = 'bmac_erp_chat_board/group_chat/group_list';
	firebaseKey_user_groups = 'bmac_erp_chat_board/group_chat/user_groups';

	constructor(private formBuilder: FormBuilder, private http: HttpService, private api: Api, private fbService: FirebaseService, ) {
		this.app = initializeApp(environment.firebase);
		this.db = getDatabase(this.app);
		// this.form = this.formBuilder.group({
		// 	'message': [],
		// 	'username': []
		// });
		let loginUserData: any = JSON.parse(localStorage.getItem("logindata") || "[]");
		this.loginUserID = loginUserData.user.id;
		this.loginUserName = loginUserData.user.name;
	}

	ngOnInit(): void {
		this.listingBmacUsers();
		this.recentChatList();
	}

	ngAfterViewInit() {
		this.chatMessages.changes.subscribe(comp => {
			console.log('should be scoll we got changes', comp)
			if(comp.first && comp.last) {
				this.scrollBottom();
			}
		})
	}

	listingBmacUsers() {
		this.fbService.getBmacUsersList().then(({userList, userSearchSuggessions}) => {
			this.userList = userList;
			this.userSearchSuggessions = userSearchSuggessions;
		})
	}

	getDateTimeFormat(){
		const now = new Date();

		// Get the individual components of the date
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0');  // Months are 0-indexed, so add 1
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');

		// Return the formatted date as 'YYYY-MM-DD HH:mm:ss'
		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	firebaseValueChanges( key, options:any = {} ) {
		const valueChange = new Subject<{ref,key,value}>();
		let erpChatsRoomsRef:any = ref(this.db, key);
		if ( options.sortBy ) {
			erpChatsRoomsRef = query(erpChatsRoomsRef, orderByChild(options.sortBy))
		}
		off(erpChatsRoomsRef);
		setTimeout(() => {
			onValue(erpChatsRoomsRef, (snapshot) => {
				let orderedValues = [];
				if ( options.sortBy ) {
					snapshot.forEach(function(child) {
						// console.log("-----", child.val())
						orderedValues.push(child.val()) // NOW THE CHILDREN PRINT IN ORDER
					});
				} else {
					orderedValues = snapshot.val()
				}
				valueChange.next({ ref: snapshot.ref ,key: snapshot.key, value: orderedValues })
			})
		}, 1000);
		return valueChange;
	}

	async recentChatList() {
		const path = `${this.firebaseKey_recent_chat}/user_${this.loginUserID}`;
		this.firebaseValueChanges(path,{ sortBy: 'message_timestamp'}).subscribe(({ key, value}) => {
			console.log("value changes also triggering ---- ")
			if(key && value) {
				this.userRecentChatsList = [];
				Object.entries(value).forEach(([key,obj])=>{
					const nameFirstLetter = obj['reciver_name'].substring(0,1).toUpperCase();
					console.log("obj------", obj);
					this.userRecentChatsList.push({
						user_name: obj['sender_id'] == this.loginUserID || obj['chat_type'] == 'group' ? obj['reciver_name'] : obj['sender_name'],
						user_id: obj['sender_id'] == this.loginUserID || obj['chat_type'] == 'group'? obj['reciver_id'] : obj['sender_id'],
						chat_type: obj['chat_type'],
						last_message: obj['last_message'],
						message_timestamp: obj['message_timestamp'],
						firstLetter: nameFirstLetter,
						bgColor: this.colorPairs[nameFirstLetter].background || '#fff',
						txtColor: this.colorPairs[nameFirstLetter].text || '#000'
					})
				});
				this.userRecentChatsList.reverse();
				console.log('recent chat ----- updatedlll ----- ', this.userRecentChatsList)
			}
		})
	}

	getValues(path) {
		return new Promise<DataSnapshot>((resolve) => {
			const erpChatsRoomsRef = ref(this.db, path);
			onValue(erpChatsRoomsRef, (snapshot) => {
				resolve(snapshot);
			})
		})
	}
	
	activeChat = {
		chat_type: <'personal'|'group'>'personal',
		chat_key: '',
		chat_title: '',
		chat_type_personal_receivers: { user_name: '', user_id: ''},
		chat_type_group_receivers:<{ user_name: '', user_id: '', user_role: 'admin'|'member',}[]>[],
		chat_type_group_members_avail_to_join:<{ user_name: '', user_id: ''}[]>[],
		chat_group_info: { group_name: "", creater_user_id: "", creater_user_name: "", cteated_timestamp: ""},
		chat_icon: '',
		chat_messages: [],
		chat_subscriptions: []
	}
	resetActiveChat() {
		this.activeChat.chat_subscriptions.forEach((subscription) => {
			subscription && subscription.unsubscribe();
		})
		this.activeChat.chat_type = 'personal';
		this.activeChat.chat_key = '';
		this.activeChat.chat_title = '';
		this.activeChat.chat_type_personal_receivers = { user_name: '', user_id: ''};
		this.activeChat.chat_type_group_receivers = [];
		this.activeChat.chat_type_group_members_avail_to_join = [];
		this.activeChat.chat_group_info = { 
			group_name: "", 
			creater_user_id: "", 
			creater_user_name: "", 
			cteated_timestamp: ""
		};
		this.activeChat.chat_icon = '';
		this.activeChat.chat_messages = [];
		this.activeChat.chat_subscriptions = [];
	}

	async activatChatUserOrGroup(chat_type:'personal'|'group', user) {

		this.resetActiveChat()

		this.activeChat.chat_type = chat_type;
		this.activeChat.chat_title = user.name;

		let path1, path2;
		if(chat_type == 'personal') {
			// managed for save recent chat
			this.activeChat.chat_type_personal_receivers = { user_id: user.id, user_name: user.name };
			
			path1 = `${this.firebaseKey_personal_chat}/chat_${user.id}_${this.loginUserID}`;
			path2 = `${this.firebaseKey_personal_chat}/chat_${this.loginUserID}_${user.id}`;
		} else if (chat_type == 'group') {
			path1 = `${this.firebaseKey_group_chat_list}/${user.id}`;
		}

		let subscription2 = null;
		console.log('group sub for path', path1)
		const subscription1 = this.firebaseValueChanges(path1).subscribe(({key, value}) => {
			console.log('group path', path1, value)
			if(!value && chat_type == 'personal') {
				subscription2 = this.firebaseValueChanges(path2).subscribe(({key, value}) => {
					if(!value) {
						set(ref(this.db, path2), {
							user_1_id: this.loginUserID,
							user_1_name: this.loginUserName,
							user_2_id: user.id,
							user_2_name: user.name,
							chat_start_timestamp: this.getDateTimeFormat()
						})
					} else {
						this.activeChat.chat_key = key;
						if(value && value.messages) {
							this.activeChat.chat_messages = Object.keys(value.messages).map(_ => value.messages[_])
							.sort((a,b) => a.message_timestamp - b.message_timestamp);
						}
					}
				})
				this.activeChat.chat_group_info = {
					...({} as any),
					...this.fbService.getIconFromuserName(user.name)
				}
				this.activeChat.chat_subscriptions.push(subscription2);
			} else {
				this.activeChat.chat_key = key;
				this.activeChat.chat_group_info = {
					...({} as any),
					...this.fbService.getIconFromuserName(user.name)
				}
				if(value && chat_type == 'group') {
					this.activeChat.chat_group_info = { 
						group_name: value.group_name, 
						creater_user_id: value.creater_user_id, 
						creater_user_name: value.creater_user_name, 
						cteated_timestamp: value.cteated_timestamp,
						...this.fbService.getIconFromuserName(value.group_name)
					};
					this.activeChat.chat_type_group_receivers = Object.keys(value.members || {}).map(_ => ({ 
						user_name:value.members[_].user_name, 
						user_id:value.members[_].user_id,
						key:_,
						user_role: 'member',
						...this.fbService.getIconFromuserName(value.members[_].user_name)
					}))
					this.activeChat.chat_type_group_receivers.unshift({
						user_id: value.creater_user_id, 
						user_name: value.creater_user_name,
						user_role: 'admin',
						...this.fbService.getIconFromuserName(value.creater_user_name)
					});
					this.activeChat.chat_type_group_members_avail_to_join = this.userList.filter(user => {
						return !this.activeChat.chat_type_group_receivers.find(_ => _.user_id == user.id)
					})
				}
				if(value && value.messages) {	
					this.activeChat.chat_messages = Object.keys(value.messages).map(_ => {
						return {
							...value.messages[_],
							...this.fbService.getIconFromuserName(value.messages[_].sender_name)
						}
					})
					.sort((a,b) => a.message_timestamp - b.message_timestamp);
				}
			}
		})
		this.activeChat.chat_subscriptions.push(subscription1);
	}

	createRecentChatForAllGroupMembers(message) {
		console.log('revent for group ----', this.activeChat.chat_type_group_receivers)
		this.activeChat.chat_type_group_receivers.forEach((member) => {
			const recentForCurrentUser = {
				reciver_id: this.activeChat.chat_key,
				reciver_name: this.activeChat.chat_title,
				sender_id: this.loginUserID,
				sender_name: this.loginUserName,
				last_message: message.substring(0,20),
				chat_type: "group",
				message_timestamp: this.getDateTimeFormat()
			}
			set(ref(this.db, `${this.firebaseKey_recent_chat}/user_${member.user_id}/receiver_group_${this.activeChat.chat_key}`), recentForCurrentUser)
		})
	}

	createRecentChatForNewGroupMembers(useridsArray, message) {
		useridsArray.forEach((userid) => {
			const recentForCurrentUser = {
				reciver_id: this.activeChat.chat_key,
				reciver_name: this.activeChat.chat_title,
				sender_id: this.loginUserID,
				sender_name: this.loginUserName,
				last_message: message.substring(0,20),
				chat_type: "group",
				message_timestamp: this.getDateTimeFormat()
			}
			set(ref(this.db, `${this.firebaseKey_recent_chat}/user_${userid}/receiver_group_${this.activeChat.chat_key}`), recentForCurrentUser)
		})
	}

	async onChatSubmit() {
		let inputMessage:any = (document.getElementById("inputMessage") as any).value;
		if(!inputMessage.trim()) return;
		(document.getElementById("inputMessage") as any).value = "";
		let newChat = {
			sender_user_id: this.loginUserID,	
			sender_name: this.loginUserName,
			message: inputMessage,
			message_timestamp: this.getDateTimeFormat(),
		}
		if(this.activeChat.chat_type == 'group') {
			push(ref(this.db, `${this.firebaseKey_group_chat_list}/${this.activeChat.chat_key}/messages`), newChat)
			this.createRecentChatForAllGroupMembers(inputMessage)
		} else {
			push(ref(this.db, `${this.firebaseKey_personal_chat}/${this.activeChat.chat_key}/messages`), newChat)
			const receiver_id = this.activeChat.chat_type_personal_receivers.user_id;
			if(this.activeChat.chat_type == 'personal') {
				const recentForUser = {
					reciver_id: receiver_id,
					reciver_name: this.activeChat.chat_type_personal_receivers.user_name,
					sender_id: this.loginUserID,
					sender_name: this.loginUserName,
					last_message: (inputMessage as string).substring(0,20),
					chat_type: "personal",
					message_timestamp: this.getDateTimeFormat()
				}
				set(ref(this.db, `${this.firebaseKey_recent_chat}/user_${this.loginUserID}/receiver_${receiver_id}`), recentForUser);
				set(ref(this.db, `${this.firebaseKey_recent_chat}/user_${receiver_id}/receiver_${this.loginUserID}`), recentForUser);
			}
		}

		this.scrollBottom();
	}

	async onCreateGroupFromSubmit(){
		if(this.groupSaveform.invalid) {
			return;
		}
		$('#createGroupModal').modal('hide');
		const path = `${this.firebaseKey_group_chat_list}`;
		const obj = {
			group_name: this.groupSaveform.value.group_name,
			creater_user_name: this.loginUserName,
			creater_user_id: this.loginUserID,
			cteated_timestamp: this.getDateTimeFormat(),
		}
		push(ref(this.db, path), obj).then((snapshot) => {
			console.log("snapshot group",snapshot)
			const promiseArr = [];
			const pathMembers = `${this.firebaseKey_group_chat_list}/${snapshot.key}/members`;
			this.groupSaveform.value.members.forEach(member_id => {
				const memberObj = {
					user_name: this.userList.find(_ => _.id == member_id).name,
					user_id: member_id
				}		
				promiseArr.push(
					new Promise(resolve => {
						push(ref(this.db, pathMembers), memberObj).then(() => {
							resolve('');
						});
					})
				)
			});
			Promise.all(promiseArr).then((x) => {
				this.activatChatUserOrGroup('group', { id: snapshot.key, name: this.groupSaveform.value.group_name })
				setTimeout(() => {
					this.createRecentChatForAllGroupMembers('Group created')
				}, 3000);
				this.groupSaveform.reset();
			});
		});
	}

	addMemberToGroup() {
		const pathMembers = `${this.firebaseKey_group_chat_list}/${this.activeChat.chat_key}/members`;
		const promiseArr = [];
		this.groupMemberform.value.members.forEach(member_id => {
			const memberObj = {
				user_name: this.userList.find(_ => _.id == member_id).name,
				user_id: member_id
			}		
			promiseArr.push(
				new Promise(resolve => {
					push(ref(this.db, pathMembers), memberObj).then(() => {
						resolve('');
					});
				})
			)
		});
		Promise.all(promiseArr).then((x) => {
			this.createRecentChatForNewGroupMembers(this.groupMemberform.value.members,'Added to group')
			this.groupMemberform.reset();
		})
	}

	removeGroupMember(user) {
		if(confirm('Are you sure you want to remove this memeber?')) {
			const pathMember = `${this.firebaseKey_group_chat_list}/${this.activeChat.chat_key}/members/${user.key}`;
			remove(ref(this.db, pathMember))
		}
	}

	scrollBottom() {
		console.log('should be scoll now', this.chatScroller)
		setTimeout(()=>{
			if(this.chatScroller){
				this.chatScroller.nativeElement.scrollTop = this.chatScroller.nativeElement.scrollHeight;
			}
		}, 500)
	}

	formatDate(dateString) {
		const date = new Date(dateString);
	  
		// Get the day, month, and year
		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // 'NOV'
		const year = date.getFullYear().toString().slice(-2); // '24'
		
		// Get the time in 12-hour format with AM/PM
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const hour12 = hours % 12 || 12; // Convert 24-hour to 12-hour format
		const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
	  
		return `${day} ${month} '${year} ${hour12}:${minutesFormatted} ${ampm}`;
	}

	userSearchSuggessions = [];

	onSearchUser(val) {
		this.userSearchSuggessions = [];
		if(!val) {
			this.userSearchSuggessions = this.userList;
		} else {
			this.userList.forEach(user => {
				if((user.name as string).toLowerCase().includes(val.toLowerCase())) {
					this.userSearchSuggessions.push(user);
				}
			});
		}
		console.log('this.userSearchSuggessions', this.userSearchSuggessions)
	}
}
