<div class="shadow bg-body">
  <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
    <div>
      <div class="fs-5">{{componentTitle}}</div>
    </div>
    <div><button class="btn" (click)="returnToTaskComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
  </div>
  <div class="">
    <div class=" px-3 py-3" style="border-radius: 15px;">
      <form [formGroup]="addTaskForm" name="addproject" id="myform">
        <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id"
          style="visibility:hidden;">
        <mat-form-field appearance="fill">
          <mat-label>Select Project </mat-label>
          <mat-select formControlName="projectid" name="projectid">
            <mat-option *ngFor="let des of projectname" [value]="des.id">
              {{des.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addTaskForm.get('name')?.invalid && addTaskForm.get('name')?.touched && addTaskForm.get('name')?.errors?.required )">
          ProjectName is required
        </span>
  
        <mat-form-field>
          <mat-label>Task Name </mat-label>
          <input matInput value="" placeholder="Enter Name" #message formControlName="taskname" name="taskname">
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addTaskForm.get('taskname')?.invalid && addTaskForm.get('taskname')?.touched && addTaskForm.get('taskname')?.errors?.required )">
          TaskName is required
        </span>
  
        <mat-form-field>
          <mat-label> Description </mat-label>
          <textarea matInput value="" placeholder="Enter Description" #message formControlName="description"
            name="description"></textarea>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addTaskForm.get('description')?.invalid && addTaskForm.get('description')?.touched && addTaskForm.get('description')?.errors?.required )">
          Description is required
        </span>
  
        <mat-form-field appearance="fill">
          <mat-label>Select User </mat-label>
          <mat-select formControlName="userids" name="userids" multiple>
            <mat-option *ngFor="let des of usersname" [value]="des.id">
              {{des.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addTaskForm.get('users')?.invalid && addTaskForm.get('users')?.touched && addTaskForm.get('users')?.errors?.required )">
          Users is required
        </span>
  
        <mat-form-field appearance="fill">
          <mat-label>Is Finished </mat-label>
          <mat-select formControlName="isfinished">
            <mat-option value="Yes">Yes</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
  
        <span class="text-danger"
          *ngIf="(addTaskForm.get('finished')?.invalid && addTaskForm.get('finished')?.touched && addTaskForm.get('finished')?.errors?.required )">
          Finished is required
        </span>
  
        <div mat-dialog-actions class="d-flex align-items-end justify-content-end p-2">
          <button type="submit" *ngIf="addtasktbtn" [disabled]="addTaskForm.invalid" mat-raised-button color="primary"
            tabindex="2" (click)="addTask()">Save</button>
          <span class="margin-right-20"></span>
          <button type="submit" *ngIf="edittaskbtn" mat-raised-button color="primary" tabindex="2"
            (click)="saveTask()">Edit</button>
          <span class="margin-right-20"></span>
  
          <button mat-raised-button color="warn" (click)="returnToTaskComponent()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>

