<h4>Change Theme</h4>
<form class="example-form" [formGroup]="changeThemeForm" name="adduser"  id="myform">
  <mat-form-field class="example-full-width">
    <mat-label>Theme Color</mat-label>
    <input matInput type="color" placeholder="color" value="#000" formControlName="theme_color" name="theme_color">
  </mat-form-field><Br/>

  <mat-form-field class="example-full-width">
      <mat-label>Forecolor</mat-label>
      <input matInput type="color" placeholder="color" value="#000" formControlName="back_color" name="back_color">
  </mat-form-field><Br/>

  <mat-form-field class="example-full-width">
      <mat-label>Menu Color</mat-label>
      <input matInput type="color" placeholder="color" value="#000" formControlName="menu_color" name="menu_color">
  </mat-form-field><Br/>

</form>
<div mat-dialog-actions>
  <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="DefaultTheme()">Default Theme</button>
  <span class="margin-right-20"></span>
  <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="changeTheme()">Save</button>
  <!-- <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button>  -->
   
  <button mat-button mat-dialog-close mat-raised-button color="warn">Close</button>
 
</div>
