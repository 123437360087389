import { Time, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

declare var $: any;
export interface Project {
  name: String,
  description: String,
  startdate: String,
  users: String,
  finished: String
}
@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.css']
})
export class AddEditProjectComponent implements OnInit {
  componentTitle = "Add Project";




  displayedColumns: string[] = ['id', 'name', 'description', 'startdate', 'users', 'finished', 'action'];
  // selectedUsersValue: string[];
  data: any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Project> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id: any;
  usersname: any;
  addProjectForm = new FormGroup({
    project_id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    startdate: new FormControl('', [Validators.required]),
    users: new FormControl('', [Validators.required]),
    finished: new FormControl('', [Validators.required]),
  });
  addprojectbtn: any;
  editprojectbtn: any;
  projectname: any;
  projectid: any;
  title_section: string;
  editProjectData: any;

  openSnackBar(message: string, action: string, Color: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef, public snackBar: MatSnackBar, private http: HttpService, private auth: AuthGuard, private api: Api, private router: Router) { }

  ngOnInit(): void {
    this.UserList();
    this.editProjectData = history.state;
    if(this.editProjectData.editProjectMode){
      this.componentTitle = "Edit Project";
      this.editProject(this.editProjectData);
    }else {
      this.addprojectbtn = true;
    }  
  }







  ProjectNameList() {
    this.http.post(Api.url.adminProject).then((response: any) => {
      // console.log(response.data)
      if (response.success) {
        this.projectname = response.data;
      }
    }).catch((error) => {
    });
  }

  

  UserList() {
    this.http.post(Api.url.UserList).then((response: any) => {
      // console.log(response.data);
      if (response.success) {
        this.usersname = response.data;
      }
    }).catch((error) => {

    });
  }
  addProjectBtn() {
    this.title_section = "Add Project";
    this.editprojectbtn = false;
    this.addprojectbtn = true;
    this.addProjectForm.reset();
  }
  addProject() {
    // console.log(typeof this.addProjectForm.value.users);
    // return;
    if (this.addProjectForm.value.users) {
      this.addProjectForm.value.users = this.addProjectForm.value.users.toString();
    }
    this.http.post(Api.url.adminProjectAdd, this.addProjectForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Add Project Successfully", "", "green-snackbar");
        this.router.navigate(['/admin/project'])
      }
    });
  }

  editProject(element: any) {
    // this.title_section = "Update Project";
    this.editprojectbtn = true;
    this.addprojectbtn = false;
    // console.log("this.usersname", this.usersname)
    let userIdsArr = element.userids.split(",");
    // this.selectedUsersValue = userIdsArr;
    userIdsArr = userIdsArr.map((id) => { return parseInt(id, 10); });
    // console.log("users ids", element.userids.split(","));
    // console.log("users ids", userIdsArr);
    // const usersArr = this.usersname;
    // const usersByProjects = usersArr.filter(user => userIdsArr.includes(user.id));
    // console.log("this.addProjectForm.controls['users']", this.addProjectForm.controls['users'])

    // var index = this.usersname.findIndex(function(el: { name: string; }) {
    //   return el.name == element.username;
    // });
    
    this.addProjectForm.controls['project_id'].setValue(element['id']);
    this.addProjectForm.controls['name'].setValue(element['name']);
    this.addProjectForm.controls['description'].setValue(element['description']);
    // this.addProjectForm.controls['users'].setValue(this.usersname[index]['id']);
    this.addProjectForm.controls['users'].setValue(userIdsArr);

    let Array = element['startdate'].split("-");
    const date = new Date(Array[2], Array[1] - 1, Array[0]);
    this.addProjectForm.controls['startdate'].setValue(date);
    this.addProjectForm.controls['finished'].setValue(element['isfinished']);
  }

  saveProject() {
    if (this.addProjectForm.value.users) {
      this.addProjectForm.value.users = this.addProjectForm.value.users.toString();
    }
    const dateInLocalTimezone = new Date(this.addProjectForm.value.startdate).toLocaleDateString();
    const [month, day, year] = dateInLocalTimezone.split('/');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    if (this.addProjectForm.value.startdate) {
      this.addProjectForm.value.startdate = formattedDate
    }
    this.http.post(Api.url.adminProjectUpdate, this.addProjectForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Edit Project Successfully", "", "green-snackbar");
        this.router.navigate(['/admin/project']);
      }
    });
  }

 

  
  returnToProjectComponent() {
    this.router.navigate(['/admin/project']);
  }
}


