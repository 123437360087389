import { Time, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

declare var $: any;
export interface Attendance {
  name: String,
  checkin: String,
  checkout: String,
  attend: String,
}

@Component({
  selector: 'app-adminattendance',
  templateUrl: './adminattendance.component.html',
  styleUrls: ['./adminattendance.component.css']
})

export class AdminattendanceComponent implements OnInit {
  // displayedColumns: string[] = ['id', 'userid', 'name', 'checkin', 'checkout', 'attend', 'action'];
  displayedColumns: string[] = ['id', 'username', 'date', 'checkInTime', 'checkOutTime', 'attend', 'action'];
  // New columns name, date, checkin time, checkout time, attend, action

  data: any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Attendance> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id: any;
  usersname: any;
  editAttendanceForm = new FormGroup({
    attendanceid: new FormControl(''),
    date: new FormControl('', [Validators.required]),
    checkin: new FormControl('', [Validators.required]),
    checkout: new FormControl('', [Validators.required]),
  });
  currenttime: any;
  checkout: any;
  openSnackBar(message: string, action: string, Color: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef, public snackBar: MatSnackBar, private http: HttpService, private auth: AuthGuard, private api: Api) { }

  ngOnInit(): void {
    this.UserList();
    this.AttendanceList(this.id, this.searchvalue, this.pageSize, this.currentPage);



    const currentDate = new Date();
    this.currenttime = this.formatTime(currentDate);
  }

  formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;  // 24-hour format
  }

  AttendanceList(id: any, searchvalue: any, pageSize: any, currentPage: any) {
    this.http.post(Api.url.adminGetAttendanceList, { userid: id, searchtext: searchvalue, page: currentPage, recordPerPage: pageSize }).then((response: any) => {
      if (response.success) {
        console.log(response.data);
        // return;
        const formattedData = response.data.data.map(entry => ({
          ...entry,
          date: this.formatDateInAttResponse(entry.date),
          checkInTime: this.formatTimeInAttResponse(entry.checkInTime),
          checkOutTime: this.formatTimeInAttResponse(entry.checkOutTime)
        }));
        
        this.dataSource.data = formattedData;
        this.dataSource.sort = this.sort;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
        // this.paginator.length = response.data.total;
      }
    }).catch((error) => {

    });
  }

   formatTimeInAttResponse(time) {
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
  
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Converts 0 hour to 12 for AM
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
  
    return `${formattedHour}:${formattedMinute} ${period}`;
  }
  
  //  to format the date from yyyy-mm-dd to dd-mm-yyyy
   formatDateInAttResponse(date) {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  }


  onSelectEvent(event: any) {
    this.id = event.target.value;
    this.AttendanceList(event.target.value, this.searchvalue, this.pageSize, this.currentPage);
  }

  UserList() {
    this.http.post(Api.url.UserList).then((response: any) => {
      // console.log(response.data)
      if (response.success) {
        this.usersname = response.data;
      }
    }).catch((error) => {

    });
  }

  SearchAttendance(event: any) {

    this.searchvalue = event.target.value;
    this.currentPage = 1;
    this.AttendanceList(this.id, this.searchvalue, this.pageSize, this.currentPage);

  }

  changePaging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex + 1;
    this.AttendanceList(this.id, this.searchvalue, this.pageSize, page);
  }

  editAttendance(element: any) {
    $("#attendanceModal").modal('show');

    console.log(element);
    // return;
    let join_Array = element['createdate'].split("-");
    const joindate = new Date(join_Array[2], join_Array[1] - 1, join_Array[0]);
    this.editAttendanceForm.controls['date'].setValue(joindate);

    const today2 = new Date(element['created_at']);
    this.currenttime = formatDate(today2, 'hh:mm a', 'en-US', '+0530');

    // const checkInTime = element['checkin'].match(/\d{2}:\d{2}$/)[0];
    // return;
    // const checkoutTime = element.checkouttime.split(':').slice(0, 2).join(':');
    // const checkoutTime = element['checkouttime'].match(/\d{2}:\d{2}$/)[0];

    this.editAttendanceForm.controls['checkin'].setValue(element['checkInTime']);
    const today = element['checkout'].split(":");
    this.checkout = today[0] + ":" + today[1];
    this.editAttendanceForm.controls['checkout'].setValue(element['checkOutTime']);
    this.editAttendanceForm.controls['attendanceid'].setValue(element['id']);


  }

  saveAtetndance() {
    this.editAttendanceForm.value.checkin = this.convertTo24HourFormat(this.editAttendanceForm.value.checkin);
    this.editAttendanceForm.value.checkout = this.convertTo24HourFormat(this.editAttendanceForm.value.checkout);
    // console.log(this.editAttendanceForm.value);
    // return;
    this.http.post(Api.url.adminEditAttendance, this.editAttendanceForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Update Successfully", "", "green-snackbar");
        $("#attendanceModal").modal('hide');
        this.AttendanceList(this.id, this.searchvalue, this.pageSize, this.currentPage);
        //  this.UserList(this.searchvalue,this.pageSize,this.currentPage);
      }
    });
  }

  convertTo24HourFormat(time) {
    // Check if the time is in 12-hour format with AM/PM
    const match = time.match(/^(\d{1,2}):(\d{2})\s*(AM|PM)$/i);

    if (match) {
      let [_, hours, minutes, period] = match; // Extract hour, minute, and AM/PM
      hours = parseInt(hours, 10); // Convert the hour to an integer

      // Convert AM/PM to 24-hour format
      if (period.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12; // Add 12 hours for PM (except 12 PM)
      } else if (period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0; // 12 AM is 00:00 in 24-hour format
      }

      // Ensure two digits for hours, e.g., "04" instead of "4"
      return `${String(hours).padStart(2, '0')}:${minutes}`;
    }

    // If no AM/PM is found, return the time as is (assumes it's already in 24-hour format)
    return time;
  }


	onPageSizeChange(event: any) {
		const selectedSize = event.value;
		if (selectedSize === -1) {
			this.showAllRecords();
		} else {
		  	this.pageSize = selectedSize;
        this.AttendanceList(this.id, this.searchvalue, this.pageSize, this.currentPage);
		}
	}
	 
	showAllRecords() {
		this.pageSize = this.length; 
		this.paginator.pageSize = this.length;  
		this.paginator.pageIndex = 0; 
		this.paginator._changePageSize(this.length); 
    this.AttendanceList(this.id, this.searchvalue, this.pageSize, this.currentPage);
	}
	
}
