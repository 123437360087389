<div class="shadow bg-body">

    <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
        <div><div class="fs-5">{{componentTitle}}</div></div>
        <div><button class="btn" (click)="returnToDocsComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
    </div>
    <form [formGroup]="addForm" id="myform" class="p-2">

        <div [ngStyle]="{'margin-left':'10px'}">

            <div class="form-group d-flex align-items-center">
                <label for="type" class="me-2">Type</label>
                <label class="control-label username"></label>
                <mat-form-field appearance="fill">
                    <input formControlName="addType" matInput placeholder="Type" id="type" type="text" id="usr">
                </mat-form-field>
            </div>

            <div class="invalid-error" *ngIf="isSubmitted && formElement['addType'].errors?.required">
                <sup>*</sup>Please Enter Value
            </div>

            <div class="row mx-0">
                <div class="col-md-6">
                    <div class="my-2 form-group d-flex align-items-center gap-2">
                        <label class="">Date</label>
                        <mat-form-field  (click)="picker.open()" appearance="fill">
                            <input formControlName="selectDate" matInput placeholder="Select Date" [matDatepicker]="picker">
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                        <sup>*</sup>Please Select Date
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="reason my-2 form-group d-flex align-items-center gap-2">
                        <label>GSTNo</label>
                        <mat-form-field appearance="fill">
                            <input formControlName="gst" id="type" type="text" matInput id="usr">
                        </mat-form-field>
                    </div>
                </div>
            </div>
           

           

            <div class="reason my-2 form-group d-flex align-items-center gap-2">
                <label>To</label>
                <mat-form-field appearance="fill">
                    <textarea formControlName="enterTo" matInput placeholder="To Description" name=" " id="to" rows="2 "></textarea>
                </mat-form-field>
            </div>

            <div class="invalid-error" *ngIf="isSubmitted && formElement['enterTo'].errors?.required">
                <sup>*</sup>Please enter Value
            </div>

            <div class="reason">
                <label>Content</label>
                <textarea id="editor"></textarea>
                <!-- <custom-element formControlName="content"></custom-element> -->
            </div>

            <div class="invalid-error" *ngIf="isSubmitted && formElement['content'].errors?.required">
                <sup>*</sup>Please enter Content
            </div>


        

        </div>
        <div class=" text-center d-flex justify-content-end align-items-end gap-3 p-2">
            <button type="submit" *ngIf="isupdate" [disabled]="addForm.invalid"  mat-raised-button color="primary" tabindex="2" (click)="updateDocument()" >Update</button>
            <button type="submit" *ngIf="isAdd" [disabled]="addForm.invalid"  mat-raised-button color="primary" tabindex="2"  (click)="addDocument()">Save</button>
            <button id="cancelBtn" type="button" mat-raised-button color="warn" (click)="returnToDocsComponent()">Cancel</button>
        </div>

    </form>
</div>