import { Api } from "src/app/services/api.service";


declare var jQuery:any,$:any,toastr:any;
var ajaxCallData:any;
export function AdminReportCalendar(options:any={}) {
    const dateClickCallback = options.dateClickCallback;
     
    return {
        ajaxCallData:null,
        //main function to initiate the module
        init: function(data:any) {
            ajaxCallData = data;
            this.initCalendar();
        },

        initCalendar: async function() {

            if (!jQuery().fullCalendar) {
                return;
            }
            
            const result = await findElement();
            if(!result) return;

            var date = new Date();
            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var h = {};

            // if (App.isRTL()) {
            //     if ($('#attendancecalendar').parents(".portlet").width() <= 720) {
            //         $('#attendancecalendar').addClass("mobile");
            //         h = {
            //             left: 'title',
            //             center: '',
            //             right: 'prev, next,month'
            //         };
            //     } else {
            //         $('#attendancecalendar').removeClass("mobile");
            //         h = {
            //             left: 'title',
            //             center: '',
            //             right: 'prev, next,month'
            //         };
            //     }
            // } else {
                if ($('#attendancecalendar').parents(".portlet").width() <= 720) {
                    $('#attendancecalendar').addClass("mobile");
                    h = {
                        left: 'title',
                        center: '',
                        right: 'prev, next,month'
                    };
                } else {
                    $('#attendancecalendar').removeClass("mobile");
                    h = {
                        left: 'title',
                        center: '',
                        right: 'prev, next,month'
                    };
                }
            // }

            var initDrag = function(el:any) {
                // console.log("hello");
                // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
                // it doesn't need to have a start or end
                
                var eventObject = {
                    title: $.trim(el.text()) // use the element's text as the event title
                };
                // store the Event Object in the DOM element so we can get to it later
                el.data('eventObject', eventObject);
                // make the event draggable using jQuery UI
                el.draggable({
                    zIndex: 999,
                    revert: true, // will cause the event to go back to its
                    revertDuration: 0 //  original position after the drag
                });
            };

            $('#attendancecalendar').fullCalendar('destroy'); // destroy the calendar
            $('#attendancecalendar').fullCalendar({ //re-initialize the calendar
                header: h,
                defaultView: 'month', // change default view with available options from http://arshaw.com/fullcalendar/docs/views/Available_Views/ 
                slotMinutes: 15,
                // eventLimit: true,
                editable: false,
                droppable: true, // this allows things to be dropped onto the calendar !!!
                // selectable: true,
                // selectHelper: true,
                showNonCurrentDates: false,
                fixedWeekCount: false,
                unselectAuto: false,                
                views: {
                    month: {
                        displayEventTime: false,
                    },                    
                }, 
                viewRender: function(view) {
                    // console.log(view, "View month");
                    // currentSelectedMonth(view.title)
                    // let dt = new Date(start);
                    // let month = '' + (dt.getMonth() + 1),year = dt.getFullYear();
                    // if (month.length < 2) month = '0' + month;
                    ajaxCallData.getCurrentMonth(view.title);
                 },    
                           
                eventRender: function(eventObj:any, el:any) {
                    // console.log(el)
                    // console.log(eventObj);
                    if(eventObj.title == "Present") {
                        $(el).popover({
                            title: eventObj.title,
                            content: eventObj.description,
                            trigger: 'hover',
                            placement: 'top',
                            container: 'body'
                        });
                    }
                    if(eventObj.title == "Half-Day") {
                        $(el).popover({
                            title: eventObj.title,
                            content: eventObj.description,
                            trigger: 'hover',
                            placement: 'top',
                            container: 'body'
                        });
                    }
                    if(eventObj.holidayData) {
                        $(el).popover({
                            title: eventObj.holidayData.holidayTitle,
                            content: eventObj.holidayData.desc,
                            trigger: 'hover',
                            placement: 'top',
                            container: 'body'
                        });
                    }
                },
                dayClick: function(date, allDay, jsEvent, view) {
                 
                    if(dateClickCallback){
                        dateClickCallback(date)
                    }
                    // alert('called' +  new Date(date).toLocaleDateString())
                 },
                eventAfterAllRender: function(view:any){
                    var date = new Date();
                    var d = date.getDate();
                    // var m = date.getMonth()+1;
                    var m = ("0" + (date.getMonth() + 1)).slice(-2)
                    var y= date.getFullYear();
                    // var chkdate = y+"-"+m+"-"+d;
                    var chkdate = d+"-"+m+"-"+y;
                    // disable future dates
                    $(".fc-future").addClass('fc-state-disabled');
                    $(".fc-future").addClass('fc-not-allowed');
                    $(".fc-future").addClass('disabled-cell');
                    // end disable future dates
                },    
                
                events: (start:any, end:any, timezone:any, callback:any) => {                   
                    ajaxCallData.getData(start,end,ajaxCallData).then((doc:any) => {
                        if (doc.success){
                            // console.log(doc.data);
                            var report = [];
                            for (let index = 0; index < doc.data.length; index++) {
                                if(doc.data[index]) {
                                    let lt="";
                                    if(doc.data[index].title == "Half-Day") {
                                        lt = "Half-Day";
                                    } else if(doc.data[index].title == "Present") { 
                                        lt = "Present";
                                    } else if(doc.data[index].title == "Holiday") {
                                        lt = "Holiday";
                                    } else {
                                        lt = "Absent";
                                    }
                                    // if(index == 0) {

                                    report.push({
                                            // editable: false,
                                            // id: doc.event.data[index].userid,
                                            // title: doc.event,
                                            start: doc.data[index].start.split('T')[0], 
                                            end: doc.data[index].end.split('T')[0],
                                            // title:"",
                                            title: lt,
                                            description: doc.data[index].description,                                             
                                            color: doc.data[index].color,                             
                                        });
                                    // }
                                }
                            }
                            // console.log(attendance);
                            
                            // console.log("att before callback", attendance)
                            // callback(attendance);
                            ajaxCallData.getHolidayData(start,end,ajaxCallData).then((data:any) => {
                                if (data.success){ 
                                    // console.log(data.data);
                                //    console.log("data", data)
                                    // console.log(attendance);
                                    // report.push({
                                    //     date: "10-25-2024",
                                    //     title: "test",
                                    //     description: "HI",  
                                    // })
                                    // report.push({
                                    //     date: "10-24-2024",
                                    //     title: "test",
                                    //     description: "HI",  
                                    // })
                                    // report.push({
                                    //     date: "10-23-2024",
                                    //     title: "test",
                                    //     description: "HI",  
                                    // })   
                                    for(let index = 0; index < data.data.length; index++){
                                        report.push({
                                            // editable: false,
                                            // id: data.event.data[index].userid,
                                            // title: data.event,
                                            
                                            // title:"",
                                            date: new Date(data.data[index].h_date).toLocaleDateString(),
                                            title: data.data[index].title,
                                            description: data.data[index].description,           
                                            holidayData: {
                                                holidayTitle:  data.data[index].title,
                                                desc: data.data[index].description,
                                                date:  new Date(data.data[index].h_date).toLocaleDateString(),
                                            }                                  
                                            // color: doc.data[index].color,                             
                                        });
                                    }
                                    callback(report);
                                }
                                else {
                                    toastr.error("Problem to gettting attendance data...");
                                }
                            });
                        }
                        else {
                            toastr.error("Problem to gettting attendance data...");
                        }
                    });
                  
                },
                // dayClick: function(date, allDay, jsEvent, view) {
                //     // console.log(date.format("DD-MM-YYYY"));
                //     var seldate = date.format();
                //     var attendance = $(".attendance").val().split(",");
                //      if ($.inArray(date.format(), attendance) != -1) {
                //         //  $("#datetime").val(seldate);
                //         //  $(".timedate").html("<strong>"+ seldate +"</strong>");
                //      }
                //      else {
                //          var date = new Date();
                //          var d = date.getDate();
                //          // var m = date.getMonth()+1;
                //          var m = ("0" + (date.getMonth() + 1)).slice(-2);
                //          var y= date.getFullYear();
                //          var currentdate = y+"-"+m+"-"+d;
                //          var websiteStart = "2018-09-13";
                //          if(seldate >= websiteStart && seldate <= currentdate) {
                //              toastr.error("You were absent for this date");
                //          }
                //      }
                //      // console.log(date.format('YYYY-MM-DD'));                 
                //   },
                loading: function(bool:any) {
                    $('#loading').toggle(bool);
                }
            });
        }
    };
    
};

// jQuery(document).ready(function() {    
//     console.log('element ==>',document.getElementById("attendancecalendar"))
//     AdminReportCalendar.init(); 
// });
async function findElement() {
    console.log("tesr");
    return new Promise((resolve, reject) => {
        let count = 0;
        const timer = setInterval(() => {
            if(document.getElementById("attendancecalendar") || count == 5) {
                clearInterval(timer)
                resolve(count == 5 ? false : true);
            }
            count += 1;
        }, 100);
    })
}




