<div class="main-content bg-body">
    <div class="row section align-items-center border-bottom p-2 mx-0">
      <div class="col-sm-4"><div class="fs-18">Tasks List</div></div>
      <div class="col-sm-4 mobile-none"></div>
      <div class="col-sm-4 row gap-1 gap-sm-0">
        <div class="dropdown col-sm-7">
          <div class="input-group">
              <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                  <option selected value="0">Select Project</option>
                  <ng-container *ngFor="let des of projectname">
                      <option value="{{des.id}}">{{des.name}}</option>
                  </ng-container>
              </select>
          </div>
        </div>
        <!-- <div class="col-sm-5"><button  mat-raised-button color="primary" class="user-btn width-100 task-btn" data-bs-toggle="modal" data-bs-target="#TaskModal" (click)="addEditTaskModalbtn()">Add Task</button></div> -->
        <div class="col-sm-5"><button  mat-raised-button color="primary" class="user-btn width-100 task-btn" (click)="gotoAddTaskComponent()">Add Task</button></div>
      </div>
    </div>
    <div class="">
      <table mat-table [dataSource]="dataSource"  matSort>
              
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
            <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
          </ng-container>
  
          <ng-container matColumnDef="project_name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Project Name </th>
            <td mat-cell *matCellDef="let element"> {{element.projectname}} </td>
          </ng-container>
  
          <ng-container matColumnDef="task_name">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Task Name </th>
              <td mat-cell *matCellDef="let element"> {{element.taskname}} </td>
          </ng-container>
  
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
  
          <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Users </th>
            <td mat-cell *matCellDef="let element"> {{element.username}}</td>
          </ng-container>
  
          <ng-container matColumnDef="finished">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Finished </th>
              <td mat-cell *matCellDef="let element"> {{element.isfinished}}</td>
          </ng-container>
        
          <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
              <td mat-cell *matCellDef="let element">
                  <mat-icon class="example-icon edit-icon" (click)="editTask(element)">edit</mat-icon>
                  <mat-icon class="example-icon delete-icon" (click)="deleteTask(element.id)">delete</mat-icon>
              </td>
            </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
      <mat-form-field appearance="fill">
        <mat-label>Records per page</mat-label>
        <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
          <mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
          <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
        [pageSizeOptions]="[50, 100, 200, 500, -1]"
        aria-label="Select page">
    </mat-paginator>
</div>

<div class="modal fade" id="deltask" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="border-bottom modal-header pb-0">
          <h2 class="modal-title" id="exampleModalLabel">Delete Task</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="deltask()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
</div>

<div class="modal hide" id="TaskModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header border-bottom pb-0">
          <h2 class="modal-title">{{addEditTaskModalTitle}}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body pt-5">
            <form [formGroup]="addTaskForm" name="addproject" style="margin-top:-45px;" id="myform">
                <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id" style="visibility:hidden;" >
                <mat-form-field appearance="fill">
                    <mat-label>Select Project </mat-label>
                    <mat-select formControlName="projectid" name="projectid">
                      <mat-option *ngFor="let des of projectname" [value]="des.id">
                        {{des.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            
                <span class="text-danger"
                   *ngIf="(addTaskForm.get('name')?.invalid && addTaskForm.get('name')?.touched && addTaskForm.get('name')?.errors?.required )">
                       ProjectName is required
                </span>

                <mat-form-field>
                    <mat-label>Task Name </mat-label>
                    <input matInput value="" placeholder="Enter Name" #message formControlName="taskname" name="taskname">
                </mat-form-field>

                <span class="text-danger"
                   *ngIf="(addTaskForm.get('taskname')?.invalid && addTaskForm.get('taskname')?.touched && addTaskForm.get('taskname')?.errors?.required )">
                       TaskName is required
                </span>

                <mat-form-field>
                    <mat-label> Description </mat-label>
                    <textarea matInput value="" placeholder="Enter Description" #message formControlName="description" name="description"></textarea>
                </mat-form-field>
              
                  <span class="text-danger"
                     *ngIf="(addTaskForm.get('description')?.invalid && addTaskForm.get('description')?.touched && addTaskForm.get('description')?.errors?.required )">
                        Description is required
                  </span>

                  <mat-form-field appearance="fill">
                    <mat-label>Select User </mat-label>
                    <mat-select formControlName="userids" name="userids" multiple>
                      <mat-option *ngFor="let des of usersname" [value]="des.id">
                        {{des.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <span class="text-danger"
                    *ngIf="(addTaskForm.get('users')?.invalid && addTaskForm.get('users')?.touched && addTaskForm.get('users')?.errors?.required )">
                        Users is required
                  </span>
                  
                  <mat-form-field appearance="fill">
                    <mat-label>Is Finished </mat-label>
                    <mat-select formControlName="isfinished">
                      <mat-option value="Yes">Yes</mat-option>
                      <mat-option value="No">No</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <span class="text-danger"
                  *ngIf="(addTaskForm.get('finished')?.invalid && addTaskForm.get('finished')?.touched && addTaskForm.get('finished')?.errors?.required )">
                    Finished is required
                </span>
                  
                <div mat-dialog-actions style="float: right;">
                    <button type="submit" *ngIf="addtasktbtn" [disabled]="addTaskForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addTask()">Save</button>
                    <span class="margin-right-20"></span>
                    <button type="submit" *ngIf="edittaskbtn" mat-raised-button color="primary" tabindex="2" (click)="saveTask()">Edit</button>
                    <span class="margin-right-20"></span>

                    <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
                </div>
            </form>
        </div>
      </div>
    </div>
</div>
