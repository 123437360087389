import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Timesheet{
  startime:String,
  endtime:String,
  projname:String,
  taskname:String,
  description:String
}

interface Users {
  id: string;
  value: string;
}
@Component({
  selector: 'app-admintimesheet',
  templateUrl: './admintimesheet.component.html',
  styleUrls: ['./admintimesheet.component.css']
})
export class AdmintimesheetComponent implements OnInit {

  displayedColumns:string[] = ['id','startime','endtime','projname','taskname','description'];
  data:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Timesheet> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
 
  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void {
    this.UserList();
    
    this.TimesheetList(this.id,this.searchvalue,this.pageSize,this.currentPage);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  TimesheetList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminTimesheetList,{id:id,searchtext:searchvalue,page:currentPage + 1,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success){
      // console.log(response.data.users);
      // this.usersname = response.data.users;
      this.dataSource.sort = this.sort;
      this.dataSource.data = response.data.data;
      // this.paginator.length = response.data.total;
      this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   onSelectEvent(event:any){
    this.id = event.target.value;
    this.TimesheetList(event.target.value,this.searchvalue,this.pageSize,this.currentPage)
   }

   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data)
      if(response.success){
        this.usersname = response.data;
        if(!this.id){
          this.TimesheetList(response.data[0].id,this.searchvalue,this.pageSize,this.currentPage)
        }
      }
     }).catch((error) => {
      
     });
   }

   SearchTimsheet(event:any)
   {

    this.searchvalue = event.target.value;
    this.currentPage = 0;
    this.TimesheetList(this.id,this.searchvalue,this.pageSize,this.currentPage);

   }

  //  changePaging(event: PageEvent)
  //  {
  //   this.pageSize = event.pageSize;
  //   this.currentPage = event.pageIndex;
  //   const page = event.pageIndex +1;
  //   this.TimesheetList(this.id,this.searchvalue,this.pageSize,page);
  //  }
  //  changePaging(event: PageEvent)
  //  {
  //    this.pageSize = event.pageSize;
  //    this.currentPage = event.pageIndex;
  //    const page = event.pageIndex +1;
 
  //    this.TimesheetList(this.id,this.searchvalue,this.pageSize,page);
  //  }

   changePaging(event: PageEvent) {
      if (this.pageSize !== -1) {
          this.pageSize = event.pageSize;
          this.currentPage = event.pageIndex;
          this.TimesheetList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        // this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.userId)
      }
   }

   onPageSizeChange(event: any) {
      const selectedSize = event.value;
      if (selectedSize === -1) {
          this.showAllRecords();
      } else {
          this.pageSize = selectedSize;
          this.TimesheetList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        // this.getLeaveList(this.searchData, this.pageSize, this.currentPage, this.userId);
      }
	}
	 
	showAllRecords() {
      this.pageSize = this.length; 
      this.paginator.pageSize = this.length;  
      this.paginator.pageIndex = 0; 
      this.paginator._changePageSize(this.length); 
      this.TimesheetList(this.id,this.searchvalue,this.pageSize,this.currentPage);
		// this.getLeaveList(this.searchUserData, this.pageSize, this.currentPage, this.specificUserId)
	}
}
