<div class="main-content bg-body">
    <div class="row p-2 border-bottom section align-items-center m-0">
        <div class="col-sm-4"><div class="fs-5">Document List</div></div>
        <div class="col-sm-4 mobile-none"></div>
        <div class="col-sm-4">
            <button mat-raised-button color="primary" class="user-btn feedback-btn document-btn"  (click)="gotoAddDocsComponent()">Add Document</button>
        </div>
    </div>
    <div class="table-area">
        <div>
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
        
                <ng-container matColumnDef="no">
        
                    <th class="custom" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by no">
                        No
                    </th>
        
                    <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
                </ng-container>
        
                <ng-container matColumnDef="type">
                    <th class="custom" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
                        Type
                    </th>
                    <td class="type" mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
        
                <ng-container matColumnDef="date">
                    <th class="date px-2" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
                        Date
                    </th>
                    <td class="px-2" mat-cell *matCellDef="let element"> {{element.date}} </td>
                </ng-container>
        
                <ng-container matColumnDef="todate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by todate">
                        ToDate
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.d_to}} </td>
                </ng-container>
        
                <!-- <ng-container matColumnDef="content">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by content">
                        Content
                    </th>
                    <td class="content pe-4" mat-cell *matCellDef="let element" [innerHTML]="element.content"> </td>
                </ng-container> -->
        
                <ng-container matColumnDef="gstno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by gstno">
                        GST No
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.isGSTNo}} </td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        Actions
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-nav-list class="" appAccordion>
                            <!-- <mat-icon (click)="editDocument(element)"  data-bs-toggle="modal" data-bs-target="#addDocument" class="material-icons edit-btn">
                                edit
                            </mat-icon> -->
                            <mat-icon (click)="editDocument(element)"  class="material-icons edit-btn text-primary">
                                edit
                            </mat-icon>
                            <mat-icon (click)="deleteData(element)" data-bs-toggle="modal" data-bs-target="#deleteModal"  class="material-icons delete-btn">
                                delete
                            </mat-icon>
                            <mat-icon (click)="downloadDocument(element.id)"  data-bs-toggle="modal" data-bs-target=""  class="material-icons download-btn text-primary">
                                file_download
                            </mat-icon>
                        </mat-nav-list>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
            <mat-form-field appearance="fill">
              <mat-label>Records per page</mat-label>
              <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
                <mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
                <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
              [pageSizeOptions]="[50, 100, 200, 500, -1]"
              aria-label="Select page">
          </mat-paginator>
    </div>
</div>




<!-- DELETE MODEL -->

<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Delete Record</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure, you want to delete this Document?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn close-button" data-bs-dismiss="modal">No</button>
                <button type="button" (click)="deleteUserRecord()" value="delete" data-bs-dismiss="modal" class="btn btn-outline-danger">Yes,
                    delete
                    it!</button>
            </div>
        </div>
    </div>
</div>

<!-- ADD DOCUMENT MODEL -->


<div class="modal fade " id="addDocument" tabindex="-1" aria-labelledby="addModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content mt-5">
            <div class="modal-header">
                <h1 class="modal-title fs-5" [ngStyle]="{'color':'#46B2E0'}" id="exampleModalLabel">Add Document
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form [formGroup]="addForm" id="myform">

                    <div [ngStyle]="{'margin-left':'10px'}">

                        <div class="form-group" [ngStyle]="{'display':'flex','gap' :'40px'}">
                            <label for="type">Type</label>
                            <label class="control-label username"></label>
                            <input formControlName="addType" id="type" type="text" class="form-control me-5" id="usr">
                        </div>

                        <div class="invalid-error" *ngIf="isSubmitted && formElement['addType'].errors?.required">
                            <sup>*</sup>Please Enter Value
                        </div>

                        <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap':'80px'}">
                            <label class="mt-4">Date</label>
                            <mat-form-field (click)="picker.open()" appearance="fill">
                                <input formControlName="selectDate" matInput [matDatepicker]="picker">
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                            <sup>*</sup>Please Select Date
                        </div>

                        <div class="reason" [ngStyle]="{'margin-top':'30px','display':'flex','gap': '100px'  }">
                            <label>To</label>

                            <textarea formControlName="enterTo" placeholder="To Description" name=" " id="to" class="form-control me-4 " rows="2 "></textarea>
                        </div>

                        <div class="invalid-error" *ngIf="isSubmitted && formElement['enterTo'].errors?.required">
                            <sup>*</sup>Please enter Value
                        </div>

                        <div class="reason" [ngStyle]="{'margin-top':'30px','display':'flex','gap': '50px'  }">
                            <label>Content</label>
                            <textarea id="editor"></textarea>
                            <!-- <custom-element formControlName="content"></custom-element> -->
                        </div>

                        <div class="invalid-error" *ngIf="isSubmitted && formElement['content'].errors?.required">
                            <sup>*</sup>Please enter Content
                        </div>


                        <div class="reason" [ngStyle]="{'margin-top':'30px','display':'flex','gap': '65px'  }">
                            <label>GSTNo</label>
                            <input formControlName="gst" id="type" type="text" class="form-control me-5" id="usr">
                        </div>

                    </div>
                    <div class="mt-4 text-center d-flex justify-content-center gap-3 ">
                        <button type="submit" *ngIf="isupdate" [disabled]="addForm.invalid" class="btn save-button text-white btn" (click)="updateDocument()" >Update</button>
                        <button type="submit" *ngIf="isAdd" [disabled]="addForm.invalid" class="btn save-button text-white btn" (click)="addDocument()">Save</button>
                        <!-- <button id="cancelBtn" type="button " data-bs-dismiss="modal " class="btn btn-outline-danger ">Cancel</button> -->
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>