import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth.guard';

const ApiUrls = {

	// users api urls
	login: environment.apiUrl + '/login',
	holidayList: environment.apiUrl + '/getholidaylist',
	tasklogList: environment.apiUrl + '/getrackshow',
	timesheetList: environment.apiUrl + '/timesheet/task/list',
	geTimesheetById: environment.apiUrl + '/timesheet/task/getbyid',
	editimesheet: environment.apiUrl + '/timesheet/task/edit',
	leaveList: environment.apiUrl + '/leaverequest',
	attendanceList: environment.apiUrl + '/attendance_list',
	profile: environment.apiUrl + '/profile',
	checkin: environment.apiUrl + '/attendance/add',
	checkOut: environment.apiUrl + '/checkout',
	isCheckedIn: environment.apiUrl + '/isCheckedIn',
	project: environment.apiUrl + '/getprojectlist',
	task: environment.apiUrl + '/getaskbyprojectid',
	addtask: environment.apiUrl + '/timesheet/task/add',
	caltask: environment.apiUrl + '/get/task',
	activity: environment.apiUrl + '/activity',
	TaskByDate: environment.apiUrl + '/task/getTaskByDate',
	attendanceByUser: environment.apiUrl + '/attendanceByUser',
	edittimesheet: environment.apiUrl + '/timesheet/task/edit',
	addleave: environment.apiUrl + '/leaverequest/add',
	adminLogin: environment.apiUrl + '/admin/login',
	adminUser: environment.apiUrl + '/admin/user/listing',
	adminaddUser: environment.apiUrl + '/admin/add/user',
	admindeleteUser: environment.apiUrl + "/admin/delete/user",
	admineditUser: environment.apiUrl + "/admin/user/edit",
	recentActivity: environment.apiUrl + '/admin/recent/activity',
	upcomingActivity: environment.apiUrl + '/admin/upcoming/event',
	absentEmployee: environment.apiUrl + '/admin/user/absent',
	adminResumeList: environment.apiUrl + '/admin/resume',
	adminResumeDelete: environment.apiUrl + '/admin/resume/delete',
	adminResumeAdd: environment.apiUrl + '/admin/resume/add',
	adminUpdateResume: environment.apiUrl + '/admin/resume/edit',
	adminTimesheetList: environment.apiUrl + '/admin/user/timesheet',
	UserList: environment.apiUrl + '/admin/user/list',
	adminAttendanceList: environment.apiUrl + '/admin/attendence',
	adminGetAttendanceList: environment.apiUrl + '/admin/getattendence/list',
	adminEditAttendance: environment.apiUrl + '/admin/attendance/edit',
	adminProjectList: environment.apiUrl + '/admin/project',
	adminProject: environment.apiUrl + '/admin/project/list',
	adminProjectAdd: environment.apiUrl + '/admin/project/add',
	adminProjectUpdate: environment.apiUrl + '/admin/project/edit',
	adminProjectDelete: environment.apiUrl + '/admin/project/delete',
	adminTaskList: environment.apiUrl + '/admin/task/list',
	adminTaskDelete: environment.apiUrl + '/admin/task/delete',
	adminTaskAdd: environment.apiUrl + '/admin/task/add',
	adminTaskEdit: environment.apiUrl + '/admin/task/edit',
	adminDailyAttendance: environment.apiUrl + '/admin/dailyattendance',
	adminHolidayList: environment.apiUrl + '/admin/holiday/list',
	adminDeleteHoliday: environment.apiUrl + '/admin/holiday/delete',
	adminAddHoliday: environment.apiUrl + '/admin/add/holiday',
	adminEditHoliday: environment.apiUrl + '/admin/holiday/update',
	adminTaskLogList: environment.apiUrl + '/admin/tasklog',
	admininventoryList: environment.apiUrl + '/admin/inventory/list',
	admininventoryDelete: environment.apiUrl + '/admin/inventory/delete',
	admininventoryAdd: environment.apiUrl + '/admin/add/inventory',
	adminSaveInventory: environment.apiUrl + '/admin/inventory/update',
	adminDocumentDelete: environment.apiUrl + '/admin/document/delete',
	adminFeedbackList: environment.apiUrl + '/admin/feedback/list',
	adminFeedbackDelete: environment.apiUrl + '/admin/feedback/delete',
	adminFeedbackAdd: environment.apiUrl + '/admin/feedback/add',
	adminFeedbackUpdate: environment.apiUrl + '/admin/feedback/update',
	adminThemeChange: environment.apiUrl + '/admin/theme/change',
	adminManagemenu: environment.apiUrl + '/admin/menu/add',
	adminMenuList: environment.apiUrl + '/admin/menu/list',
	attendanceByUserId: environment.apiUrl + '/admin/user/attendence',
	adminleaveList: environment.apiUrl + '/admin/user/leave',
	adminUserList: environment.apiUrl + '/admin/user/list',
	adminDeleteLeave: environment.apiUrl + '/admin/leave/delete',
	adminEditLeave: environment.apiUrl + '/admin/leave/edit',
	leaveReport: environment.apiUrl + '/admin/leave/report',
	timesheetReport: environment.apiUrl + '/admin/timesheet/report',
	adminAddLeave: environment.apiUrl + '/admin/leave/add',
	adminDetail: environment.apiUrl + '/admin/detail',
	adminDocumentList: environment.apiUrl + '/admin/document/list',
	adminDocumentDeleteFeedback: environment.apiUrl + '/admin/document/delete',
	adminDocumentAdd: environment.apiUrl + '/admin/document/add',
	adminDocumentUpdate: environment.apiUrl + '/admin/document/update',
	adminDownloadDocument: environment.apiUrl + '/admin/downloadPDF',
	userListChat: environment.apiUrl + '/chat/getusers',
	addnewtask: environment.apiUrl + '/task/add',
	adminReportTimesheetData: environment.apiUrl + '/admin/report/gettimesheetdata',
	adminReportLeaveData: environment.apiUrl + "/admin/report/getleavedata",
	adminReportHolidayData: environment.apiUrl + "/admin/report/getholidays",
	// add attendence
	addattendence: environment.apiUrl + '/attendance/add',

	// Datat response apis
	getAdminUserList: environment.apiUrl + '/admin/users/adminUserList',
	getAdminResumeList: environment.apiUrl + '/admin/resume/list',
	getAdminLeaveList: environment.apiUrl + '/admin/users/leave/list',
}

@Injectable({
	providedIn: 'root'
})
export class Api {
	user = null;
	token = '';
	isUserLogin = false
	static url = ApiUrls;
	constructor(private auth: AuthGuard) {
		this.setLoginData();
		this.auth.loginChange.subscribe(data => {
			this.setLoginData();
		})
	}


	setLoginData() {
		this.isUserLogin = this.auth.isUserLoggedin();
		if (this.isUserLogin) {
			const data = this.auth.getLoggedUserData();
			this.user = data;
			this.token = data.logintoken
		}
	}

	getTradeshowHeaders() {
		// console.log(this.user);
		return {
			'Authorization': 'Bearer ' + this.token,
			// 'Accept': 'application/x.ew.trade_show+json'
		}
	}

	static commonParams = {
		// gzip: false,
		// eventid: "1652256228YEmU",
		// appid: "5HtTgllCVFHxRLce"
	}
}
