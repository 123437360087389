<!-- ============================================================== -->
<!-- row -->
<!-- ============================================================== -->
<div fxLayout="row" fxLayoutWrap="wrap">
		<!-- Card column -->
		<div fxFlex.gt-sm="100%" fxFlex="100">
				<mat-card>
						<mat-card-content>
								<mat-card-title>Basic Tab </mat-card-title>
								<mat-card-subtitle>Check the <code class=""><a href="https://material.angular.io/components/tabs/overview">Official Component</a></code></mat-card-subtitle>
						</mat-card-content>
						<mat-tab-group>
								<mat-tab label="Tab 1"><mat-card-content>Content 1</mat-card-content></mat-tab>
								<mat-tab label="Tab 2"><mat-card-content>Content 2</mat-card-content></mat-tab>
						</mat-tab-group>
				</mat-card>    
		</div>
</div>

<!-- ============================================================== -->
<!-- row -->
<!-- ============================================================== -->
<div fxLayout="row" fxLayoutWrap="wrap">
	<!-- Card column -->
	<div fxFlex.gt-sm="100%" fxFlex="100">
		<mat-card>
			<mat-card-content>
					<mat-card-title>Complex Tab Example (Responsive tab)</mat-card-title>
			</mat-card-content>
			<mat-tab-group class="demo-tab-group">
				<mat-tab label="Tab 1">
					<div class="demo-tab-content">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla venenatis ante augue.
						Phasellus volutpat neque ac dui mattis vulputate. Etiam consequat aliquam cursus.
						In sodales pretium ultrices. Maecenas lectus est, sollicitudin consectetur felis nec,
						feugiat ultricies mi. Aliquam erat volutpat. Nam placerat, tortor in ultrices porttitor,
						orci enim rutrum enim, vel tempor sapien arcu a tellus.
					</div>
				</mat-tab>
				<mat-tab label="Tab 2">
					<ng-template mat-tab-label>
						⭐
					</ng-template>
					<div class="demo-tab-content">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla venenatis ante augue.
						Phasellus volutpat neque ac dui mattis vulputate. Etiam consequat aliquam cursus.
						In sodales pretium ultrices. Maecenas lectus est, sollicitudin consectetur felis nec,
						feugiat ultricies mi. Aliquam erat volutpat. Nam placerat, tortor in ultrices porttitor,
						orci enim rutrum enim, vel tempor sapien arcu a tellus.
					</div>  
				</mat-tab>
				<mat-tab label="Tab 3" disabled>
					No content  
				</mat-tab>
				<mat-tab label="Tab 4">
				 <div class="demo-tab-content">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla venenatis ante augue.
						Phasellus volutpat neque ac dui mattis vulputate. Etiam consequat aliquam cursus.
						In sodales pretium ultrices. Maecenas lectus est, sollicitudin consectetur felis nec,
						feugiat ultricies mi. Aliquam erat volutpat. Nam placerat, tortor in ultrices porttitor,
						orci enim rutrum enim, vel tempor sapien arcu a tellus.
						<br />
						<br />
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla venenatis ante augue.
						Phasellus volutpat neque ac dui mattis vulputate. Etiam consequat aliquam cursus.
						In sodales pretium ultrices. Maecenas lectus est, sollicitudin consectetur felis nec,
						feugiat ultricies mi. Aliquam erat volutpat. Nam placerat, tortor in ultrices porttitor,
						orci enim rutrum enim, vel tempor sapien arcu a tellus.
					</div>  
				</mat-tab>
				<mat-tab label="Tab 5">
					No content  
				</mat-tab>
				<mat-tab label="Tab 6">
					No content  
				</mat-tab>
			</mat-tab-group>
		</mat-card>    
	</div>
</div>

<!-- ============================================================== -->
<!-- row -->
<!-- ============================================================== -->
<div fxLayout="row" fxLayoutWrap="wrap">
	<!-- Card column -->
	<div fxFlex.gt-sm="100%" fxFlex="100">
		<mat-card>
			<mat-card-content>
				<mat-card-title>Label alignment <span class="bg-success text-white rounded font-12 p-l-5 p-r-5">New</span></mat-card-title>
				<mat-card-subtitle>If you want to align the tab labels in the center or towards the end of the container, you can do so using the <code>[mat-align-tabs]</code> attribute.</mat-card-subtitle>
			</mat-card-content>
			<mat-tab-group mat-align-tabs="start">
				<mat-tab label="First">
				  	<div class="p-20">Content 1</div>
				</mat-tab>
				<mat-tab label="Second">
				  	<div class="p-20">Content 2</div>
				</mat-tab>
				<mat-tab label="Third">
				  	<div class="p-20">Content 3</div>
				</mat-tab>
			</mat-tab-group>

			<mat-tab-group mat-align-tabs="center">
			  	<mat-tab label="First">
			  		<div class="p-20">Content 1</div>
			  	</mat-tab>
			  	<mat-tab label="Second">
			  		<div class="p-20">Content 2</div>
			  	</mat-tab>
			  	<mat-tab label="Third">
			  		<div class="p-20">Content 3</div>
			  	</mat-tab>
			</mat-tab-group>

			<mat-tab-group mat-align-tabs="end">
			  	<mat-tab label="First">
			  		<div class="p-20">Content 1</div>
			  	</mat-tab>
			  	<mat-tab label="Second">
			  		<div class="p-20">Content 2</div>
			  	</mat-tab>
			  	<mat-tab label="Third">
			  		<div class="p-20">Content 3</div>
			  	</mat-tab>
			</mat-tab-group>
		</mat-card>    
	</div>
</div>

<!-- ============================================================== -->
<!-- row -->
<!-- ============================================================== -->
<div fxLayout="row" fxLayoutWrap="wrap">
	<!-- Card column -->
	<div fxFlex.gt-sm="100%" fxFlex="100">
		<mat-card>
			<mat-card-content class="m-b-0">
				<mat-card-title>Tab group animations <span class="bg-success text-white rounded font-12 p-l-5 p-r-5">New</span></mat-card-title>
				<mat-card-subtitle>If you want to align the tab labels in the center or towards the end of the container, you can do so using the <code>[mat-align-tabs]</code> attribute.</mat-card-subtitle>
			</mat-card-content>
			<div class="p-l-20 p-r-20 p-b-20">
				<h5 class="m-0">No animation</h5>
				<mat-tab-group animationDuration="0ms">
				  <mat-tab label="First">Content 1</mat-tab>
				  <mat-tab label="Second">Content 2</mat-tab>
				  <mat-tab label="Third">Content 3</mat-tab>
				</mat-tab-group>

				<h5 class="m-b-0">Very slow animation</h5>
				<mat-tab-group animationDuration="2000ms">
				  <mat-tab label="First">Content 1</mat-tab>
				  <mat-tab label="Second">Content 2</mat-tab>
				  <mat-tab label="Third">Content 3</mat-tab>
				</mat-tab-group>
			</div>			
		</mat-card>    
	</div>
</div>