        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a> -->
<!-- <span>Hii {{username}}</span> -->
<div [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 font-18" style="cursor: pointer;">
    <!-- <mat-icon>person</mat-icon> -->
    <span>Hii {{username}}</span>
    <!-- <img src="assets/images/default.png" alt="user" class="profile-pic">  -->
</div>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item *ngIf="role" (click)="openChaneThemeDialog()">
        <mat-icon>settings</mat-icon> Change Theme 
    </button>
    <!-- <button mat-menu-item *ngIf="role" data-bs-toggle="modal" data-bs-target="#menu_setting">
            <mat-icon>settings</mat-icon> Manage Menu </button> -->
    <button mat-menu-item *ngIf="role" (click)="openManageMenuDialog()">
            <mat-icon>settings</mat-icon> Manage Menu 
    </button>
    <button mat-menu-item routerLink="/profile" *ngIf="!role">
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>

        <!-- <div>
          <button mat-raised-button (click)="openDialog()">Open Modal</button>
        </div> -->
</mat-menu>

<div class="modal hide" id="addsettingmodal" style="color: black;">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">Change Theme</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <form class="example-form" [formGroup]="changeThemeForm" name="adduser"  id="myform">
                <mat-form-field class="example-full-width">
                  <mat-label>Theme Color</mat-label>
                  <input matInput type="color" placeholder="color" value="#000" formControlName="theme_color" name="theme_color">
                </mat-form-field><Br/>

                <mat-form-field class="example-full-width">
                    <mat-label>Forecolor</mat-label>
                    <input matInput type="color" placeholder="color" value="#000" formControlName="back_color" name="back_color">
                </mat-form-field><Br/>

                <mat-form-field class="example-full-width">
                    <mat-label>Menu Color</mat-label>
                    <input matInput type="color" placeholder="color" value="#000" formControlName="menu_color" name="menu_color">
                </mat-form-field><Br/>

              </form>
              <div mat-dialog-actions style="float: right;">
                <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="DefaultTheme()">Default Theme</button>
                <span class="margin-right-20"></span>
                <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="changeTheme()">Save</button>
                <span class="margin-right-20"></span>
                <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
        </div>
      
      </div>
    </div>
</div>

<div class="modal hide" id="menu_setting" style="color: black;">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">Manage Menu</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
         
            <form class="example-form" [formGroup]="Setting_Menu" name="adduser"  id="myform">
              <!-- <mat-slide-toggle
              class="example-margin"
              [checked]="checked"
             >
            Slide me!
          </mat-slide-toggle> -->
                <mat-slide-toggle formControlName="Resumes" name="Resumes" (change)="changed($event)">Resumes</mat-slide-toggle><br/>
                <mat-slide-toggle  formControlName="Timesheet" name="Timesheet" (change)="changed($event)">Timesheet</mat-slide-toggle><br/>
                <mat-slide-toggle formControlName="Attendance" name="Attendance" (change)="changed($event)">Attendance</mat-slide-toggle><br/>
                <mat-slide-toggle formControlName="Machine" name="Machine" (change)="changed($event)">Machine</mat-slide-toggle><br/>
                <mat-slide-toggle formControlName="Tasklogs" name="Tasklogs" (change)="changed($event)">Tasklogs</mat-slide-toggle><br/>
                <mat-slide-toggle formControlName="Inventory" name="Inventory" (change)="changed($event)">Inventory</mat-slide-toggle><br/>
                <mat-slide-toggle formControlName="Document" name="Document" (change)="changed($event)">Document</mat-slide-toggle><br/>
            </form>

           
            <div mat-dialog-actions style="float: right;">
                <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="settingMenu()">Save</button>
                <span class="margin-right-20"></span>
                <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
        </div>
     </div>
    </div>
</div>


