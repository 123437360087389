// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	// apiUrl: "http://127.0.0.1:8000/api"
	// apiUrl: 'http://192.168.1.2:8000/api',
	apiUrl: "https://erpv2.bmacinfotech.com/public/api",
	firebase: {
		// apiKey: "AIzaSyBUakvNGIXbMS1vrzolxoenP3dsD0-4eEQ",
		// authDomain: "fir-chat-4c9a8.firebaseapp.com",
		// databaseURL: "https://fir-chat-4c9a8-default-rtdb.firebaseio.com",
		// projectId: "fir-chat-4c9a8",
		// storageBucket: "fir-chat-4c9a8.appspot.com",
		// messagingSenderId: "920785689460",
		// appId: "1:920785689460:web:393be3b870abd0d58a6a21",
		// measurementId: "G-YYZV3656J8"

		// Erp Chat Firebase Config - new
		apiKey: "AIzaSyBP8N5hv6djwLWfOW1cjXFqIR-sVShJmes",
		authDomain: "erp-chat-c9a48.firebaseapp.com",
		databaseURL: "https://erp-chat-c9a48-default-rtdb.firebaseio.com",
		projectId: "erp-chat-c9a48",
		storageBucket: "erp-chat-c9a48.appspot.com",
		messagingSenderId: "422268335006",
		appId: "1:422268335006:web:1c89aa26c1ab7923009c8f"
	}
};
