import { Component, Input, OnInit, } from '@angular/core';
import { upcomingEvent } from './events-data';

@Component({
  selector: 'app-up-coming-events',
  templateUrl: './up-coming-events.component.html',

})
export class UpComingEventsComponent implements OnInit {

  @Input() upcomingEventData: upcomingEvent[] = [];
  @Input() upcomingKeyValue: any = [];

  constructor() {
   
  }

  ngOnInit(): void {
  }

}
