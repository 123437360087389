<div class="main-content bg-body">
  <div class="row p-2 border-bottom section m-0">
    <div class="col-sm-4">
        <div class="col-auto" >
            <label class="visually-hidden" for="search">Search</label>
            <input type="text" (keyup)="SearchUser($event)" class="form-control" id="search" placeholder="Search">
        </div>
    </div>
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <!-- <button mat-raised-button color="primary" class="user-btn width-100" data-bs-toggle="modal" data-bs-target="#userModal">Add User</button> -->
      <button mat-raised-button color="primary" class="user-btn width-100" (click)="gotoAddUsersComponent()">Add User</button>
    </div>
  </div>
  <!-- <div class="example-container">
    <table mat-table [dataSource]="dataSource" matSort>
          
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} ({{element.gender}}) </td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email/Contact No. </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} <Br/>{{element.contactno}}<br> Alt:{{element.alternatecontact}} <br/> Parent: {{element.parentscontact}} </td>
        </ng-container>
  
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element">Bday: {{element.birthdate}} <br/> Join: {{element.joindate}} <br/> Leave: {{element.leaving_date}} </td>
        </ng-container>
  
        <ng-container matColumnDef="fulladdress">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Full Current Address  </th>
          <td mat-cell *matCellDef="let element"> Current: {{element.currentaddress}} <Br/> Permenant: {{element.fulladdress}}</td>
        </ng-container>
  
        <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Tags  </th>
          <td mat-cell *matCellDef="let element"> Role: {{element.role}} <Br/> Designation: {{element.designation}} <br/> isLeft:{{element.isleft}}</td>
        </ng-container>
  
        <ng-container matColumnDef="upload">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Upload  </th>
            <td mat-cell *matCellDef="let element"> <img *ngIf="element.file" class="user-img" src="{{element.file}}" alt=""> <div *ngIf="element.file == null">Not Upload</div></td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
          <td mat-cell *matCellDef="let element">
              <mat-icon class="example-icon edit-icon" (click)="editUser(element)">edit</mat-icon>
              <mat-icon class="example-icon delete-icon" (click)="deleteUser(element.id)">delete</mat-icon>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
    <mat-form-field appearance="fill">
      <mat-label>Records per page</mat-label>
      <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
        <mat-option [value]="-1">All</mat-option> 
        <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
      [pageSizeOptions]="[50, 100, 200, 500, -1]"
      aria-label="Select page">
  </mat-paginator> -->
  <table id="userDataTable" class="display" style="width:100%"></table>
</div>
<div class="modal hide" id="userModal">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">Add User</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addUserForm" name="adduser" style="margin-top:-45px;" id="myform">
          <input matInput value="" placeholder="Enter id" #message formControlName="user_id" name="user_id" style="visibility:hidden;" >
          <mat-form-field>
            <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
          </mat-form-field>
      
          <span class="text-danger"
             *ngIf="(addUserForm.get('name')?.invalid && addUserForm.get('name')?.touched && addUserForm.get('name')?.errors?.required )">
                 Name is required
          </span>
          
           <mat-form-field>
              <input matInput value="" placeholder="email" #message formControlName="email" name="email">
           </mat-form-field>  
           <span class="text-danger"
             *ngIf="(addUserForm.get('email')?.invalid && addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.required )">
                 Eamil is required
           </span>   
           <span class="text-danger"
             *ngIf="(addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.email )">
              Enter a valid email address
           </span>   
           <mat-form-field>
              <input matInput value="" placeholder="password" #message formControlName="password" name="email">
           </mat-form-field> 
           <span class="text-danger"
           *ngIf="(addUserForm.get('password')?.invalid && addUserForm.get('password')?.touched && addUserForm.get('password')?.errors?.required )">
             Password is required
         </span>  
         <section class="gender">
          <label class="example-margin">Gender: </label>
          <mat-radio-group formControlName="gender" name="gender">
            <mat-radio-button class="example-margin" value="Female" style="margin-left:10px">
              Female
            </mat-radio-button>
            <mat-radio-button class="example-margin" value="Male" style="margin-left:15px">
              Male
            </mat-radio-button>
          </mat-radio-group>
          </section><br/>
          <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
            <mat-label>Choose a Birthdate</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthdate" name="birthdate">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <span class="text-danger"
             *ngIf="(addUserForm.get('gender')?.invalid && addUserForm.get('gender')?.touched && addUserForm.get('gender')?.errors?.required )">
              Gender is required
           </span>  
          <mat-form-field appearance="fill">
            <mat-label>Select Role</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let role of roleoptions" [value]="role.value">
                {{role.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      
          <span class="text-danger"
            *ngIf="(addUserForm.get('role')?.invalid && addUserForm.get('role')?.touched && addUserForm.get('role')?.errors?.required )">
              Role is required
          </span>  
          <mat-form-field appearance="fill">
            <mat-label>Select Designation </mat-label>
            <mat-select formControlName="designation">
              <mat-option *ngFor="let des of desOptions" [value]="des.id">
                {{des.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
         
          <span class="text-danger"
            *ngIf="(addUserForm.get('designation')?.invalid && addUserForm.get('designation')?.touched && addUserForm.get('designation')?.errors?.required )">
              Designation is required
          </span>  
          <mat-form-field>
            <input matInput value="" placeholder="contact_no" #message formControlName="contactno" name="contactno"  maxlength="13">
          </mat-form-field>
          <span class="text-danger"
            *ngIf="(addUserForm.get('contact_no')?.invalid && addUserForm.get('contact_no')?.touched && addUserForm.get('contact_no')?.errors?.required )">
              Contact_No is required
          </span> 
          <mat-form-field>
            <input matInput value="" placeholder="alternate_contact_No" #message formControlName="alternatecontact" name="alternatecontact"  maxlength="13">
          </mat-form-field>
          <span class="text-danger"
            *ngIf="(addUserForm.get('alternate_contact_No')?.invalid && addUserForm.get('alternate_contact_No')?.touched && addUserForm.get('alternate_contact_No')?.errors?.required )">
              Alternate Contact No is required
          </span> 
          <mat-form-field>
            <input matInput value="" placeholder="parents_contact_No" #message formControlName="parentscontact" name="parentscontact"  maxlength="13">
          </mat-form-field>
          <span class="text-danger"
            *ngIf="(addUserForm.get('parents_contact_No')?.invalid && addUserForm.get('parents_contact_No')?.touched && addUserForm.get('parents_contact_No')?.errors?.required )">
              Parents Contact No is required
          </span> 
          <mat-form-field>
            <input matInput value="" placeholder="full_current_address" #message formControlName="currentaddress" name="currentaddress">
          </mat-form-field>
          <span class="text-danger"
          *ngIf="(addUserForm.get('full_current_address')?.invalid && addUserForm.get('full_current_address')?.touched && addUserForm.get('full_current_address')?.errors?.required )">
            Full Current Address is required
          </span> 
          <mat-form-field>
            <input matInput value="" placeholder="full_permanent_address" #message formControlName="fulladdress" name="fulladdress">
          </mat-form-field>
          <span class="text-danger"
          *ngIf="(addUserForm.get('full_permanent_address')?.invalid && addUserForm.get('full_permanent_address')?.touched && addUserForm.get('full_permanent_address')?.errors?.required )">
            Permanent Address is required
          </span> 
          <mat-form-field class="example-full-width" appearance="fill" (click)="picker2.open()">
            <mat-label>Choose a Joindate</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="joindate" name="joindate">
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
      
          <span class="text-danger"
          *ngIf="(addUserForm.get('joindate')?.invalid && addUserForm.get('joindate')?.touched && addUserForm.get('joindate')?.errors?.required )">
            JoinDate is required
          </span> 
          <!-- <input class="ng-hide" id="input-file-id" multiple type="file" #message formControlName="upload" name="upload" />
          <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label> -->
          <!-- <mat-form-field>
            <input matInput value=""  placeholder="upload" #message formControlName="upload" name="upload">
          </mat-form-field> -->
          <div >
            <button type="button" mat-raised-button (click)="fileInput.click()" class="file-upload">Choose File</button>
            <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" formControlName="upload" name="upload">
          </div>
          <mat-form-field class="example-full-width" appearance="fill" (click)="picker3.open()">
            <mat-label>Choose a LeaveDate</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="leaving_date" name="leaving_date">
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
          
          <div mat-dialog-actions style="float: right;">
              <button type="submit" *ngIf="adduserbtn" [disabled]="addUserForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="adduser()">Save</button>
              <span class="margin-right-20"></span>
              <button type="submit" *ngIf="edituserbtn" mat-raised-button color="primary" tabindex="2" (click)="edituser()">Edit</button>
              <span class="margin-right-20"></span>
              <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
        </form>
      </div>
    </div>
  </div>
</div>




