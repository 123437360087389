import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

declare var $:any;
export interface Task{
  project_name:String,
  task_name:String,
  description:String,
  users:String,
  finished:String
}

@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.css']
})
export class AddEditTaskComponent implements OnInit {
  componentTitle = "Add Task";
  displayedColumns:string[] = ['id','project_name','task_name','description','users','finished','action'];
  data:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Task> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
  addTaskForm = this.formBuilder.group({
    id: new FormControl(''),
    projectid: new FormControl('',[Validators.required]),
    taskname: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    userids: new FormControl('',[Validators.required]),
    isfinished: new FormControl('',[Validators.required]),    
  });
  addtasktbtn:any;
  edittaskbtn:any;
  projectname:any;
  taskid:any;
  array:any;
  addEditTaskModalTitle:string = "Add Task";
  editTaskData: any;
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private formBuilder: FormBuilder,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api, private router: Router) { 

    // this.formGroup = this.formBuilder.group({
    //   toppings: [[], Validators.required],
    // })
  }

  ngOnInit(): void {
  
    this.addtasktbtn = true;
    this.edittaskbtn = false;
    this.ProjectNameList();
    this.UserList();
    this.editTaskData = history.state;
    console.log("Edit task data", history.state)
    if(this.editTaskData.editTaskMode){
      this.componentTitle = "Edit Task";
      this.edittaskbtn = true;
      this.editTask(this.editTaskData)
    }else{
      this.componentTitle = "Add Task";
    }
  }
  
  ProjectNameList()
   {
    this.http.post(Api.url.adminProject).then((response: any)=>{
      if(response.success)
      {
        this.projectname = response.data;
      }
      }).catch((error) => {
     });
   }
 

  
   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }
   addEditTaskModalbtn(){
      this.addEditTaskModalTitle = "Add Task";
      this.edittaskbtn = false;
      this.addtasktbtn = true;
      this.addTaskForm.reset();
   }
   addTask(){
    if(this.addTaskForm.value.userids){
      this.addTaskForm.value.userids = this.addTaskForm.value.userids.toString();
    }
    this.http.post(Api.url.adminTaskAdd,this.addTaskForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add Task Successfully","","green-snackbar");
         this.router.navigate(['/admin/task']);
      }
    });
   }
  

   editTask(element:any)
   {
        console.log(element.userids);
        this.addEditTaskModalTitle = "Edit Task";
        this.edittaskbtn = true;
        this.addtasktbtn = false;
        let userIdsArr = element.userids.split(",");
        userIdsArr = userIdsArr.map((id) => {return parseInt(id, 10);});

        // var index = this.usersname.findIndex(function(el: { name: string; }) {
        //   return el.name == element.username;
        // });
        
        this.addTaskForm.controls['id'].setValue(element['id']);
        this.addTaskForm.controls['projectid'].setValue(element['projectid']);
        // this.addTaskForm.controls['userids'].setValue(this.usersname[index]['id']);
        this.addTaskForm.controls['userids'].setValue(userIdsArr);
        this.addTaskForm.controls['taskname'].setValue(element['taskname']);
        this.addTaskForm.controls['description'].setValue(element['description']);
        this.addTaskForm.controls['isfinished'].setValue(element['isfinished']);

   }
   saveTask(){
    if(this.addTaskForm.value.userids){
      this.addTaskForm.value.userids = this.addTaskForm.value.userids.toString();
    }
    this.http.post(Api.url.adminTaskEdit, this.addTaskForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Edit Task Successfully","","green-snackbar");
        this.router.navigate(['/admin/task']);
      }
    });
   }



   returnToTaskComponent(){
    this.router.navigate(['/admin/task']);
   }
}
