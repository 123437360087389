<div class="shadow bg-body">
  <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
    <div><div class="fs-5">{{componentTitle}}</div></div>
    <div><button class="btn" (click)="returnToUsersComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
  </div>
  <div class="mt-5">
    <form [formGroup]="addUserForm" name="adduser" class="p-l-20" style="margin-top:-45px;" id="myform">
      <input matInput value="" placeholder="Enter id" #message formControlName="user_id" name="user_id" style="visibility:hidden;" >
     
      <mat-form-field >
        <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
      </mat-form-field>
  
      <span class="text-danger"
         *ngIf="(addUserForm.get('name')?.invalid && addUserForm.get('name')?.touched && addUserForm.get('name')?.errors?.required )">
             Name is required
      </span>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field >
             <input matInput value="" placeholder="email" #message formControlName="email" name="email">
          </mat-form-field>  
          <span class="text-danger"
            *ngIf="(addUserForm.get('email')?.invalid && addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.required )">
                Eamil is required
          </span>   
          <span class="text-danger"
            *ngIf="(addUserForm.get('email')?.touched && addUserForm.get('email')?.errors?.email )">
             Enter a valid email address
          </span>   
        </div>
        <div class="col-md-6">
          <mat-form-field  >
             <input matInput value="" placeholder="password" #message formControlName="password" name="email">
          </mat-form-field> 
          <span class="text-danger"
          *ngIf="(addUserForm.get('password')?.invalid && addUserForm.get('password')?.touched && addUserForm.get('password')?.errors?.required )">
            Password is required
          </span>  
        </div>
      </div>
     <section class="gender">
      <label class="example-margin">Gender: </label>
      <mat-radio-group formControlName="gender" name="gender">
        <mat-radio-button class="example-margin" value="Female" style="margin-left:10px">
          Female
        </mat-radio-button>
        <mat-radio-button class="example-margin" value="Male" style="margin-left:15px">
          Male
        </mat-radio-button>
      </mat-radio-group>
      </section>
      <span class="text-danger"
        *ngIf="(addUserForm.get('gender')?.invalid && addUserForm.get('gender')?.touched && addUserForm.get('gender')?.errors?.required )">
        Gender is required
      </span>  
      <br/>
      <mat-form-field class="example-full-width"  (click)="picker.open()">
        <mat-label>Choose a Birthdate</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="birthdate" name="birthdate">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
     

       <div class="row">
        <div class="col-md-6">
          <mat-form-field >
            <mat-label>Select Role</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let role of roleoptions" [value]="role.value">
                {{role.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      
          <span class="text-danger"
            *ngIf="(addUserForm.get('role')?.invalid && addUserForm.get('role')?.touched && addUserForm.get('role')?.errors?.required )">
              Role is required
          </span> 
        </div>
        <div class="col-md-6">
          <mat-form-field >
            <mat-label>Select Designation </mat-label>
            <mat-select formControlName="designation">
              <mat-option *ngFor="let des of desOptions" [value]="des.id">
                {{des.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
         
          <span class="text-danger"
            *ngIf="(addUserForm.get('designation')?.invalid && addUserForm.get('designation')?.touched && addUserForm.get('designation')?.errors?.required )">
              Designation is required
          </span>
        </div>
       </div>
    
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <mat-form-field >
              <input matInput value="" placeholder="contact_no" #message formControlName="contactno" name="contactno"  maxlength="13">
            </mat-form-field>
            <span class="text-danger"
              *ngIf="(addUserForm.get('contact_no')?.invalid && addUserForm.get('contact_no')?.touched && addUserForm.get('contact_no')?.errors?.required )">
                Contact_No is required
            </span> 
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mat-form-field >
              <input matInput value="" placeholder="alternate_contact_No" #message formControlName="alternatecontact" name="alternatecontact"  maxlength="13">
            </mat-form-field>
            <span class="text-danger"
              *ngIf="(addUserForm.get('alternate_contact_No')?.invalid && addUserForm.get('alternate_contact_No')?.touched && addUserForm.get('alternate_contact_No')?.errors?.required )">
                Alternate Contact No is required
            </span> 
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mat-form-field >
              <input matInput value="" placeholder="parents_contact_No" #message formControlName="parentscontact" name="parentscontact"  maxlength="13">
            </mat-form-field>
            <span class="text-danger"
              *ngIf="(addUserForm.get('parents_contact_No')?.invalid && addUserForm.get('parents_contact_No')?.touched && addUserForm.get('parents_contact_No')?.errors?.required )">
                Parents Contact No is required
            </span> 
          </div>
        </div>
       

     
      
      
      <mat-form-field >
        <input matInput value="" placeholder="full_current_address" #message formControlName="currentaddress" name="currentaddress">
      </mat-form-field>
      <span class="text-danger"
      *ngIf="(addUserForm.get('full_current_address')?.invalid && addUserForm.get('full_current_address')?.touched && addUserForm.get('full_current_address')?.errors?.required )">
        Full Current Address is required
      </span> 
      <mat-form-field >
        <input matInput value="" placeholder="full_permanent_address" #message formControlName="fulladdress" name="fulladdress">
      </mat-form-field>
      <span class="text-danger"
      *ngIf="(addUserForm.get('full_permanent_address')?.invalid && addUserForm.get('full_permanent_address')?.touched && addUserForm.get('full_permanent_address')?.errors?.required )">
        Permanent Address is required
      </span> 

      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width"  (click)="picker2.open()">
            <mat-label>Choose a Joindate</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="joindate" name="joindate">
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
      
          <span class="text-danger"
          *ngIf="(addUserForm.get('joindate')?.invalid && addUserForm.get('joindate')?.touched && addUserForm.get('joindate')?.errors?.required )">
            JoinDate is required
          </span> 
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width"  (click)="picker3.open()">
            <mat-label>Choose a LeaveDate</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="leaving_date" name="leaving_date">
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div >
        <button type="button" mat-raised-button (click)="fileInput.click()" class="file-upload">Choose File</button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" formControlName="upload" name="upload">
      </div>
      
      <div class="p-2 d-flex justify-content-end" mat-dialog-actions>
          <button type="submit" *ngIf="adduserbtn" [disabled]="addUserForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="adduser()">Save</button>
          <span class="margin-right-20"></span>
          <button type="submit" *ngIf="edituserbtn" mat-raised-button color="primary" tabindex="2" (click)="edituser()">Edit</button>
          <span class="margin-right-20"></span>
          <button mat-raised-button color="warn" (click)="returnToUsersComponent()">Cancel</button> 
        </div>
    </form>
  </div>
</div>