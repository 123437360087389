import { Time } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

declare var $: any;
export interface Inventory {
  name: string,
  description: string,
  given_to_user: Number,
  date_inventory: String,
  status: String,
}

@Component({
  selector: 'app-add-edit-inventory',
  templateUrl: './add-edit-inventory.component.html',
  styleUrls: ['./add-edit-inventory.component.css']
})
export class AddEditInventoryComponent implements OnInit {
  componentTitle = "Add Inventory";
  editInventoryData: any;

  
  // displayedColumns: string[] = ['id', 'name', 'description', 'giventotheuser', 'date_inventory', 'status', 'action'];
  data: any;
  addinventorybtn: any;
  editinventorybtn: any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Inventory> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  inventory_id: any;
  addInventoryForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    giventotheuser: new FormControl('', [Validators.required]),
    date_inventory: new FormControl('', [Validators.required]),
    status: new FormControl('', [Validators.required]),
  });
  contact_contition: any;
  title_section: string;
  openSnackBar(message: string, action: string, Color: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  constructor(private changeDetector: ChangeDetectorRef, public snackBar: MatSnackBar, private http: HttpService, private auth: AuthGuard, private api: Api, private router: Router) { }

  ngOnInit(): void {
    this.title_section = "Add Inventory"
    this.editInventoryData = history.state;
    if(this.editInventoryData.editInventoryMode){
      this.componentTitle = "Edit Inventory";
      this.editInventory(this.editInventoryData);
    } else {
      this.addinventorybtn = true;
    }

  }
  // InventoryList(searchvalue: any, pageSize: any, currentPage: any) {
  //   this.http.post(Api.url.admininventoryList, { searchtext: searchvalue, page: currentPage, recordPerPage: pageSize }).then((response: any) => {
  //     if (response.success) {
  //       // console.log(response.data.total);
  //       this.dataSource.sort = this.sort;
  //       this.dataSource.data = response.data.data;
  //       this.paginator.length = response.data.total;
  //       this.length = response.data.total;
  //     }
  //   }).catch((error) => {

  //   });
  // }

  addResume() {
    this.http.post(Api.url.admininventoryAdd, this.addInventoryForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Add Inventory Successfully", "", "green-snackbar");
        this.router.navigate(['/admin/inventory']);
        // this.InventoryList(this.searchvalue, this.pageSize, this.currentPage);
      }
    });
  }
  editInventory(element: any) {
    this.title_section = "Update Inventory"
    this.addinventorybtn = false;
    this.editinventorybtn = true;
    
    this.addInventoryForm.controls['id'].setValue(element['id']);
    this.addInventoryForm.controls['name'].setValue(element['name']);
    this.addInventoryForm.controls['description'].setValue(element['description']);
    this.addInventoryForm.controls['giventotheuser'].setValue(element['giventotheuser']);
    this.addInventoryForm.controls['status'].setValue(element['status']);
    let join_Array = element['date_inventory'].split("-");
    const date_inventory = new Date(join_Array[2], join_Array[1] - 1, join_Array[0]);
    this.addInventoryForm.controls['date_inventory'].setValue(date_inventory);
  }

  updateInventory() {
    this.http.post(Api.url.adminSaveInventory, this.addInventoryForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Edit Inventory Successfully", "", "green-snackbar");
        this.router.navigate(['/admin/inventory']);
        // this.InventoryList(this.searchvalue, this.pageSize, this.currentPage);
        this.editinventorybtn = false;
        this.addinventorybtn = true;
        this.title_section = "Add Inventory"

      }
    });
  }

  

  returnToInventoryComponent() {
    this.router.navigate(['/admin/inventory']);
  }
}
