import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { Api } from 'src/app/services/api.service';
import { selectboxComponent } from '../../layouts/selectbox/selectbox.component';

@Component({
  selector: 'app-leavemodal',
  styleUrls: ['./leavemodal.component.css'],
  template: `<h1 mat-dialog-title>Add Leave</h1>
  <div mat-dialog-content>
  <form [formGroup]="addLeaveForm" name="addleave" >
    <mat-grid-list cols="1" rowHeight="2:1">
      <mat-grid-tile>
        <mat-form-field>
        <mat-label>Select Task</mat-label>
          <mat-select class="example-select" formControlName="leavetype" >
            <mat-option *ngFor="let item of leavetypeoptions" [value]=item>{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Select a Leave Date</mat-label>
          <div style="display:flex">
            <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="leavedate" name="leavedate">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput 
              placeholder="Valid Reason" formControlName="leavereason" name="leavereason">
            </textarea>
        </mat-form-field>
        <div mat-dialog-actions style="float: right;">
        <button  mat-raised-button color="primary" tabindex="2" (click)="saveLeave()">Save</button>
        <button  mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
      </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  
  </div>`
})
export class LeavemodalComponent implements OnInit {

  addLeaveForm = new FormGroup({
    leavetype: new FormControl(''),
    leavedate: new FormControl(''),
    leavereason: new FormControl('')
  });
  leavetypeoptions: any;
  minDate: Date;

  constructor(
    public dialogRef: MatDialogRef<LeavemodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private http: HttpService, private auth: AuthGuard, private api: Api,
  ) {
    this.leavetypeoptions = ['Half Day Leave', 'Full Day Leave'];
    this.minDate = new Date();

  }

  ngOnInit(): void {
  }

  openDialog(): void {
    // this.starttime = "1ff"; 
    const dialogRef = this.dialog.open(LeavemodalComponent, {
      width: '250px',
    });


    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.starttime = result;
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  saveLeave() {
    this.http.post(Api.url.addleave, this.addLeaveForm.value).then((response: any) => {
      // console.log(response);
      if (response.success) {
        // this.openSnackBar("Add Task Successfully","","green-snackbar");
        this.dialogRef.close();

      }
    });
  }
  addLeaveType(newItem: string) {
    this.addLeaveForm.controls['leavetype'].setValue(newItem);
  }
}
