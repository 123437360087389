import { Time,formatDate } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

declare var $:any;
export interface Project{
  name:String,
  description:String,
  startdate:String,
  users:String,
  finished:String
}
@Component({
  selector: 'app-adminproject',
  templateUrl: './adminproject.component.html',
  styleUrls: ['./adminproject.component.css']
})
export class AdminprojectComponent implements OnInit {
  displayedColumns:string[] = ['id','name','description','startdate','users','finished','action'];
  // selectedUsersValue: string[];

  data:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Project> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  usersname:any;
  addProjectForm = new FormGroup({
    project_id: new FormControl(''),
    name: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    startdate: new FormControl('',[Validators.required]),
    users: new FormControl('',[Validators.required]),
    finished: new FormControl('',[Validators.required]),
  });
  addprojectbtn:any;
  editprojectbtn:any;
  projectname:any;
  projectid:any;
  title_section:string;

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api, private router: Router) { }

  ngOnInit(): void 
  {
    this.title_section = "Add Project";
    this.addprojectbtn = true;
    this.editprojectbtn = false;
    this.ProjectNameList();
    this.UserList();
    this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);

  }
  changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    // console.log(page);
    this.ProjectList(this.id,this.searchvalue,this.pageSize,page);
   }
  ProjectList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminProjectList,{projectid:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        this.dataSource.sort = this.sort;
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   ProjectNameList()
   {
    this.http.post(Api.url.adminProject).then((response: any)=>{
      // console.log(response.data)
      if(response.success)
      {
        this.projectname = response.data;
      }
      }).catch((error) => {
     });
   }

   SearchProject(event:any){
    // console.log(event);
    this.ProjectList(this.id,event.target.value,this.pageSize,this.currentPage);
   }

   onSelectEvent(event:any){
    // console.log(event);
    this.id = event.target.value;
    this.ProjectList(event.target.value,this.searchvalue,this.pageSize,this.currentPage);
   }

   UserList(){
    this.http.post(Api.url.UserList).then((response: any)=>{
      // console.log(response.data);
      if(response.success){
        this.usersname = response.data;
      }
     }).catch((error) => {
      
     });
   }
   addProjectBtn(){
      this.title_section = "Add Project";
      this.editprojectbtn = false;
      this.addprojectbtn = true;
      this.addProjectForm.reset();
   }
    addProject(){
        // console.log(typeof this.addProjectForm.value.users);
        // return;
      if(this.addProjectForm.value.users){
        this.addProjectForm.value.users = this.addProjectForm.value.users.toString();
      }
      this.http.post(Api.url.adminProjectAdd, this.addProjectForm.value).then((response: any) => {
        if (response.success) {
          this.openSnackBar("Add Project Successfully","","green-snackbar");
          $("#ProjectModal").modal('hide');
          this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        }
      });
    }

   editProject(element:any)
   {
      element.editProjectMode = true;
      this.router.navigate(['/admin/add-edit-project'], {state: element});
        // this.title_section = "Update Project";
        // this.editprojectbtn = true;
        // this.addprojectbtn = false;
        // // console.log("this.usersname", this.usersname)
        // let userIdsArr = element.userids.split(",");
        // // this.selectedUsersValue = userIdsArr;
        // userIdsArr = userIdsArr.map((id) => {return parseInt(id, 10);});
        // // console.log("users ids", element.userids.split(","));
        // // console.log("users ids", userIdsArr);
        // const usersArr = this.usersname;
        // const usersByProjects = usersArr.filter(user => userIdsArr.includes(user.id));
        // // console.log("this.addProjectForm.controls['users']", this.addProjectForm.controls['users'])

        // // var index = this.usersname.findIndex(function(el: { name: string; }) {
        // //   return el.name == element.username;
        // // });
        // $("#ProjectModal").modal('show');
        // this.addprojectbtn = false;
        // this.editprojectbtn = true;
        // this.addProjectForm.controls['project_id'].setValue(element['id']);
        // this.addProjectForm.controls['name'].setValue(element['name']);
        // this.addProjectForm.controls['description'].setValue(element['description']);
        // // this.addProjectForm.controls['users'].setValue(this.usersname[index]['id']);
        // this.addProjectForm.controls['users'].setValue(userIdsArr);

        // let Array =  element['startdate'].split("-");
        // const date = new Date(Array[2], Array[1]-1, Array[0]);
        // this.addProjectForm.controls['startdate'].setValue(date);
        // this.addProjectForm.controls['finished'].setValue(element['isfinished']);
   }

   saveProject() {
      if(this.addProjectForm.value.users){
        this.addProjectForm.value.users = this.addProjectForm.value.users.toString();
      }
      const dateInLocalTimezone = new Date(this.addProjectForm.value.startdate).toLocaleDateString();
      const [month, day, year] = dateInLocalTimezone.split('/');
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      if(this.addProjectForm.value.startdate){
        this.addProjectForm.value.startdate = formattedDate
      }
      this.http.post(Api.url.adminProjectUpdate, this.addProjectForm.value).then((response: any) => {
        if (response.success) {
          this.openSnackBar("Edit Project  Successfully","","green-snackbar");
          $("#ProjectModal").modal('hide');
          this.addProjectForm.reset();
          this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
          this.editprojectbtn = false;
          this.addprojectbtn = true;
          this.title_section = "Add Project";
        }
      });
    }

    deleteProject(id:any){
      this.projectid = id;
      $("#delproject").modal('show');
    }

    deleteproj(){
      this.http.post(Api.url.adminProjectDelete,{projectid:this.projectid}).then((response: any)=>{
        $("#delproject").modal('hide');
        this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        this.openSnackBar("Delete Project Successfully","","green-snackbar");
      });
  
    }

    gotoProjectComponent(){
      this.router.navigate(['/admin/add-edit-project']);
    }



    onPageSizeChange(event: any) {
      const selectedSize = event.value;
      if (selectedSize === -1) {
        // When 'All' is selected, show all records
        this.showAllRecords();
      } else {
        // Otherwise, use the selected page size
        this.pageSize = selectedSize;
        this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
      }
    }

    showAllRecords() {
      this.pageSize = this.length;  // Set page size to total number of records
      this.paginator.pageSize = this.length;  // Disable pagination effectively
      this.paginator.pageIndex = 0;  // Go to the first "page"
      this.paginator._changePageSize(this.length);  // Manually trigger paginator update
      this.ProjectList(this.id,this.searchvalue,this.pageSize,this.currentPage);
      // Fetch all records
      // this.UserList(this.searchvalue, this.length, 0);
    }
}


