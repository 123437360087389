import { Time,formatDate, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

declare var $:any;
declare var moment;
export interface Holiday{
  title:String,
  description:String,
  date:String
}


@Component({
  selector: 'app-adminholiday',
  templateUrl: './adminholiday.component.html',
  styleUrls: ['./adminholiday.component.css']
})

export class AdminholidayComponent implements OnInit {
  displayedColumns:string[] = ['id','title','description','date','action'];
  data:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Holiday> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  id:any;
  names:any;
  holiday_id:any;
  addHolidayForm = new FormGroup({
    id: new FormControl(''),
    title: new FormControl('',[Validators.required]),
    description: new FormControl('',[Validators.required]),
    hdate: new FormControl('',[Validators.required]),
  });
  addholidaybtn:any;
  editholidaybtn:any;
  public model: any = [];
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModal = new Date(0);
  title_section:string;
  currentYear: any;

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  @ViewChild('picker', { static: true })
  _picker!: MatDatepicker<Date>;
  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) {
   }

   public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {

    if (event.value) {
      const date = event.value;
      const index = this._findDate(date);

      if (index === -1) {
        this.model.push(date);
        console.log("IFFF");

      } else {
        this.model.splice(index, 1);
        console.log("ELSEEEEEE");
      }

      this.resetModal = new Date(0);
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_componentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
    }

    // console.log(this.model);

  }
  public remove(date: Date): void {
    const index = this._findDate(date);
    this.model.splice(index, 1)
    !this.model.length && this.addHolidayForm.controls['hdate'].setValue('')

  }
  private _findDate(date: Date): number {
    return this.model.map((m: any) => +m).indexOf(+date);
  }
  ngOnInit(): void 
  {
    this.title_section = "Add Holiday";
    this.addholidaybtn = true;
    this.editholidaybtn = false;
    this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
    this.currentYear = new Date().getFullYear();
  }

  addEditHolidayFun(){
    this.title_section = "Add Holiday";
    this.addholidaybtn = true;
    this.editholidaybtn = false;
    this.addHolidayForm.reset();
  }
  HolidayList(id:any,searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminHolidayList,{id:id,searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        this.dataSource.data = response.data.data;
        this.dataSource.sort = this.sort;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   changePaging(event: PageEvent)
   {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const page = event.pageIndex +1;
    this.HolidayList(this.id,this.searchvalue,this.pageSize,page);
   }

   addHoliday()
   {

    let parseDate = this.model.map((ele: any) => moment(ele).format('YYYY-MM-DD'));
    this.addHolidayForm.controls['hdate'].setValue(parseDate);
    // console.log(parseDate);

    this.http.post(Api.url.adminAddHoliday, this.addHolidayForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add holiday Successfully","","green-snackbar");
         $("#HolidayModal").modal('hide');
         this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
      }
    });
   }

   editHoliday(element:any)
   {
      // console.log(element);
      this.model = [];
      this.title_section = "Update Holiday";
      this.addholidaybtn = false;
      this.editholidaybtn = true;
      $("#HolidayModal").modal('show');
      this.addHolidayForm.controls['title'].setValue(element['title']);
      this.addHolidayForm.controls['description'].setValue(element['description']);
      this.addHolidayForm.controls['id'].setValue(element['id']);
      // const hdate = new Date(2010, 0, 3);
      const pipe = new DatePipe('en-US');
      const now = Date.now();
        
      let join_Array =  element['h_date'].split("-");
      const joindate = new Date(join_Array[2], join_Array[0]-1, join_Array[1]);
      this.addHolidayForm.controls['hdate'].setValue(joindate);
      // const dateInLocalTimezone = new Date(joindate).toLocaleDateString(); // Convert to local timezone
      // console.log(dateInLocalTimezone);
      this.model.push(joindate);
   }
   
   saveHoliday()
   {
      const dateInLocalTimezone = new Date(this.addHolidayForm.value.hdate).toLocaleDateString();
      const [month, day, year] = dateInLocalTimezone.split('/');
      const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      // console.log(formattedDate);
      if(this.addHolidayForm.value.hdate){
        this.addHolidayForm.value.hdate = formattedDate;
      }
      
      this.http.post(Api.url.adminEditHoliday, this.addHolidayForm.value).then((response: any) => {
        if (response.success)
        {
          this.openSnackBar("Edit Holiday Successfully","","green-snackbar");
          $("#HolidayModal").modal('hide');
          this.addHolidayForm.reset();
          this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
          this.editholidaybtn = false;
          this.title_section = "Add Holiday";
        }
      });
   }

   deleteHoliday(id:any){
    this.holiday_id = id;
      $("#delholiday").modal('show');
   }
   delethol(){
    this.http.post(Api.url.adminDeleteHoliday,{id:this.holiday_id}).then((response: any)=>{
      if(response.success)
      {
        $("#delholiday").modal('hide');
        this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
        this.openSnackBar("Delete holiday Successfully","","green-snackbar"); 
      } else {  
        this.openSnackBar(response.message,"","green-snackbar"); 
      }
     }).catch((error) => {});
   }

   formatDate(dateStr: string): string {
    const parts = dateStr.split('-');
    return `${parts[1]}-${parts[0]}-${parts[2]}`;
  }
  onPageSizeChange(event: any) {
		const selectedSize = event.value;
		if (selectedSize === -1) {
			this.showAllRecords();
		} else {
		  	this.pageSize = selectedSize;
        this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage);
		}
	}
	 
	showAllRecords() {
		this.pageSize = this.length; 
		this.paginator.pageSize = this.length;  
		this.paginator.pageIndex = 0; 
		this.paginator._changePageSize(this.length);
    this.HolidayList(this.id,this.searchvalue,this.pageSize,this.currentPage); 
	}
}


