
declare var jQuery:any,$:any,toastr:any;
var ajaxCallData:any;
var viewTask:any;
var checkInOutTime:any;


export function  customCalendar() {

    return {
        //main function to initiate the module
        // init: function() {
        //     this.initCalendar();
        // },
        ajaxCallData:null,
        //main function to initiate the module
        init: function(data:any) {
            ajaxCallData = data;
            this.initCalendar();
        },

        initCalendar: async function() {

            if (!jQuery().fullCalendar) {
                return;
            }

            // await findElement();
            const result = await findElement();
            if(!result) return;
            var date = new Date();
            var d = date.getDate();
            var m = date.getMonth();
            var y = date.getFullYear();

            var h = {};

        
                if ($('#customcalendar').parents(".portlet").width() <= 720) {
                    $('#customcalendar').addClass("mobile");
                    h = {
                        left: 'title',
                        center: '',
                        right: 'prev, next,month'
                    };
                } else {
                    $('#customcalendar').removeClass("mobile");
                    h = {
                        left: 'title',
                        center: '',
                        right: 'prev, next,month'
                    };
                }

            var initDrag = function(el:any) {
                // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
                // it doesn't need to have a start or end
                var eventObject = {
                    title: $.trim(el.text()) // use the element's text as the event title
                };
                // store the Event Object in the DOM element so we can get to it later
                el.data('eventObject', eventObject);
                // make the event draggable using jQuery UI
                el.draggable({
                    zIndex: 999,
                    revert: true, // will cause the event to go back to its
                    revertDuration: 0 //  original position after the drag
                });
            };
            $('#customcalendar').fullCalendar('destroy'); // destroy the calendar
            $('#customcalendar').fullCalendar({ //re-initialize the calendar
                header: h,
                defaultView: 'month', // change default view with available options from http://arshaw.com/fullcalendar/docs/views/Available_Views/ 
                slotMinutes: 15,
                // eventLimit: true,
                editable: false,
                droppable: true, // this allows things to be dropped onto the calendar !!!
                // selectable: true,
                // selectHelper: true,
                showNonCurrentDates: false,
                fixedWeekCount: false,
                unselectAuto: false,                
                views: {
                    month: {
                        displayEventTime: false,
                    },                    
                },
               
                select: function (start:any, end:any, jsEvent:any, view:any, resource:any) {
                    console.log("fxcgfx");
                    var eventstart = start.format();
                    console.log('-->',eventstart);
                    $("#datetime").val(eventstart);
                  

                    var attendance = $(".attendance").val().split(",");
                        $.each(attendance, function( key:any, value:any){
                            // var celldate = start.format();
                            // console.log(start.format());
                            if(value == eventstart) {
                                // $("#customcalendar").attr("data-date",value).fullCalendar("unselect");
                                console.log(value+"\n"+eventstart);
                                // $(cell).attr("data-date",value).addClass('fc-state-disabled');
                                // $(this).addClass('fc-state-disabled');
                                // $(this).selectable = false;
                                // $(cell).attr("data-date",value).addClass('fc-not-allowed');
                            }
                            else {
                                selectable: false;
                                return false;
                            }
                        });
                        // if(start.isBefore(moment())) {
                        //     $('#customcalendar').fullCalendar('unselect');
                        //     return false;
                        // }
                    
                },        
                
                eventRender: function (event:any, element:any) {
                    var date = new Date();
                    var d = date.getDate();
                    // var m = date.getMonth()+1;
                    var m = ("0" + (date.getMonth() + 1)).slice(-2);
                    var y= date.getFullYear();
                    // var chkdate = y+"-"+m+"-"+d;
                    const chkdate = d+"-"+m+"-"+y;
                    $(".timedate").html("<strong>"+chkdate+"</strong>");                    
                                     
                    $(element).each(function (ele:any) {
                        $(ele).attr('date-num', event.start.format('YYYY-MM-DD')); 
                    });
                },
                
                dayClick: function(date:any, allDay:any, jsEvent:any, view:any) {
                    var attendance = $(".attendance").val().split(",");
                    if ($.inArray(date.format(), attendance) != 0) {
                    //    console.log("Fgfgd");
                       //TRUE
                       seldate = date.format('YYYY-MM-DD'); 
                    //    console.log(seldate);
                        // viewTask(seldate);
                        // checkInOutTime(seldate);
                        $("#datetime").val(seldate);
                        $(".timedate").html("<strong>"+ seldate +"</strong>");
                    } else {
                         //FLASE
                        // $(".errormsg").text("Timesheet disabled for this date");
                        // $("#error").removeClass("hidden");
                        // toastr.error("You were absent for this date");                       
                        var seldate = this.date.format("YYYY-MM-DD");
                        const date = new Date();
                        var d = date.getDate();
                        // var m = date.getMonth()+1;
                        var m = ("0" + (date.getMonth() + 1)).slice(-2);
                        var y= date.getFullYear();
                        var currentdate = d+"-"+m+"-"+y;
                        // var currentdate = y+"-"+m+"-"+d;
                        if(seldate < currentdate) {
                            // console.log(seldate, currentdate);
                            toastr.error("You were absent for this date");
                        }
                    }
                 },
                eventAfterAllRender: function(view:any){
                    // console.log('--> ',view.start.clone());
                   for( let cDay = view.start.clone(); cDay.isBefore(view.end) ; cDay.add(1, 'day') ){
                        var dateNum = cDay.format('YYYY-MM-DD');
                        var dayEl = $('.fc-day[data-date="' + dateNum + '"]');
                        var taskCount = $('.fc-event[date-num="' + dateNum + '"]').length;
                        // console.log(taskCount);

                        // console.log("task:" +taskCount);
                        if(taskCount){
                            // console.log("helo");
                            var html = '<br><a href="javascript:void(0);" data-target="#event-details" class="task-count" clickdate="'+dateNum+'">' + 
                                        '<b>' +
                                        taskCount + 
                                        '</b>' +
                                        // ' Task' +
                                        '</a>';
                            console.log(html);
                            dayEl.append(html);
                        }
                    }
                    var date = new Date();
                    var d = date.getDate();
                    // var m = date.getMonth()+1;
                    var m = ("0" + (date.getMonth() + 1)).slice(-2)
                    var y= date.getFullYear();
                    var chkdate = y+"-"+m+"-"+d;
                    
                    // disable future dates
                    $(".fc-future").addClass('fc-state-disabled');
                    $(".fc-future").addClass('fc-not-allowed');
                    $(".fc-future").addClass('disabled-cell');
                    // end disable future dates
                    // viewTask(chkdate);
                },        
                
      
                
                events: function(start:any, end:any, timezone:any, callback:any) {
                    // console.log('start',start);
                    // var mStart = new Date(start);
                    // console.log("mStar"+mStart);
                 
                        ajaxCallData.getData(start,end,ajaxCallData).then((doc:any) => {
                        if (doc.success){
                            // console.log(doc)
                            var tasks = [];
                            for (let index = 0; index < doc.task.data.length; ++index) {
                                // console.log(doc.task.data[index]);
                                tasks.push({
                                    // editable: false,
                                    // id: doc.event.data[index].userid,
                                    // title: doc.event,
                                    title: doc.task.data[index].title,
                                    start: doc.task.data[index].start.split('T')[0], 
                                    end: doc.task.data[index].end.split('T')[0],                                                                 
                                });
                                // console.log(doc.event.data[1].title);
                            }
                            console.log(unique(tasks));
                            callback(tasks);
                            // console.log($(".fc-event-container").find(".fc-title"));
                        }
                        else {
                            alert("Problem to gettting task data...");
                        }
                    });
                   
                  },
                  loading: function(bool:any)
                    {
                        $('#loading').toggle(bool);
                    }
            });
        }
    };
};

function unique(array:any){
    return array.filter(function(el:any, index:any, arr:any) {
        console.log(arr.indexOf(el));
        return index == arr.indexOf(el);
    });
  }


function findElement() {
    return new Promise((resolve, reject) => {
        let count = 0;
        const timer = setInterval(() => {
            if(document.getElementById("customcalendar") || count == 5) {
                clearInterval(timer)
                resolve(true)
            }
            count += 1;
        }, 100);
    })
}

