import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';

declare var $:any;
export interface Feedback{
  organization:string,
  feedback:string,
}

@Component({
  selector: 'app-adminfeedback',
  templateUrl: './adminfeedback.component.html',
  styleUrls: ['./adminfeedback.component.css']
})
export class AdminfeedbackComponent implements OnInit {
  displayedColumns:string[] = ['id','organization','feedback','action'];
  data:any;
  addfeedbackbtn:any;
  editfeedbackbtn:any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<Feedback> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  resumeid:any;
  addFeedbackForm = new FormGroup({
    id: new FormControl(''),
    feedback:new FormControl('',[Validators.required])
  });
  feedbackid:any;
  contact_contition:any;

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  constructor(private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api) { }

  ngOnInit(): void {
    this.addfeedbackbtn = true;
    this.FeedbackList(this.searchvalue,this.pageSize,this.currentPage);
  }

  FeedbackList(searchvalue:any,pageSize:any,currentPage:any) 
  {
    this.http.post(Api.url.adminFeedbackList,{searchtext:searchvalue,page:currentPage,recordPerPage:pageSize}).then((response: any)=>{
     if(response.success)
     {
        // console.log(response.data.total);
        this.dataSource.sort = this.sort;
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
     }
    }).catch((error) => {
     
    });
   }

   addFeedback()
   {
    this.http.post(Api.url.adminFeedbackAdd, this.addFeedbackForm.value).then((response: any) => {
      if (response.success) 
      {
         this.openSnackBar("Add Feedback Successfully","","green-snackbar");
         $("#FeedbackModal").modal('hide');
         this.FeedbackList(this.searchvalue,this.pageSize,this.currentPage);
         this.addFeedbackForm.reset();
      }
    });
   }
   editFeedback(element:any)
   {
    this.editfeedbackbtn = true;
    this.addfeedbackbtn = false;
    $("#FeedbackModal").modal('show');
    this.addFeedbackForm.controls['id'].setValue(element['id']);
    this.addFeedbackForm.controls['feedback'].setValue(element['feedback']);
   }

   updateFeedback()
   {
    this.http.post(Api.url.adminFeedbackUpdate, this.addFeedbackForm.value).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Edit Feedback Successfully","","green-snackbar");
         $("#FeedbackModal").modal('hide');
         this.addFeedbackForm.reset();
         this.FeedbackList(this.searchvalue,this.pageSize,this.currentPage);
         this.editfeedbackbtn = false;
         this.addfeedbackbtn = true;
      }
    });
   }
   delFeedback(id:any)
   {
     this.feedbackid = id;
     $("#delfeedback").modal('show');
   }
   deleteFeedback()
   {
    this.http.post(Api.url.adminFeedbackDelete,{id:this.feedbackid}).then((response: any)=>{
      $("#delfeedback").modal('hide');
      this.FeedbackList(this.searchvalue,this.pageSize,this.currentPage);
      this.openSnackBar("Delete Feedback Successfully","","green-snackbar");
    });
   }

}
