import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  template: `<h1 mat-dialog-title>Edit Timesheet</h1>
    <div mat-dialog-content>
    <form name="edittimesheet" >
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile>
        <mat-form-field>
          <input matInput tabindex="1" placeholder="Start Time" formControlName="startime" name="startime">
        </mat-form-field>
        <mat-form-field>
          <input matInput tabindex="1" placeholder="End Time">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field>
          <input matInput tabindex="1" placeholder="Project Name">
        </mat-form-field>
        <mat-form-field>
          <input matInput tabindex="1" placeholder="Task Name">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="100px">
      <mat-grid-tile>
          <mat-form-field>
            <textarea matInput 
              placeholder="Description">
            </textarea>
          </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    </form>
    </div>
    <div mat-dialog-actions>
      <button mat-button  tabindex="2">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>`
})
export class DialogOverviewExampleDialogComponent {
  
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  starttime: string='';
  endtime: string='';
  inputVar: string;


  constructor(public dialog: MatDialog) {
    this.inputVar = "initial value"
   }
   timesheetForm = new FormGroup({
		startime: new FormControl(''),
	});

  openDialog(): void {
    // this.starttime = "1ff"; 

    const dialogRef = this.dialog.open(DialogOverviewExampleDialogComponent, {
      width: '250px',
      data: { starttime: this.starttime, endtime: this.endtime }
    });
    this.timesheetForm.controls['startime'].setValue(this.starttime);
    this.inputVar = "changed";


    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.starttime = result;
    });
  }
}
