<!-- ============================================================== -->
<!-- Fixed height Card Grid-->
<!-- ============================================================== -->
<div fxLayout="row">
    <div fxFlex.gt-sm="100%" >
        <mat-card>
            <mat-card-content>
              <mat-card-title>Fixed height grid-list</mat-card-title>
                <mat-card-subtitle><code>&lt;mat-grid-list&gt;</code> is a two-dimensional list view that arranges cells into grid-based layout. See Material Design spec. <code><a href="https://material.io/guidelines/components/grid-lists.html">Official Doc here</a></code></mat-card-subtitle>
              <mat-grid-list cols="4" rowHeight="100px">
                  <mat-grid-tile
                      *ngFor="let tile of tiles"
                      [colspan]="tile.cols"
                      [rowspan]="tile.rows"
                      [style.background]="tile.color">
                    {{tile.text}}
                  </mat-grid-tile>
                </mat-grid-list>  
            </mat-card-content>        
        </mat-card>
    </div>
</div>    
<!-- ============================================================== -->
<!-- Basic Card Grid-->
<!-- ============================================================== -->
<mat-card>
    <mat-card-content>
      <mat-card-title>Basic grid-list</mat-card-title>
      <mat-card-subtitle><code>&lt;mat-grid-list&gt;</code> is a two-dimensional list view that arranges cells into grid-based layout. See Material Design spec. <a href="https://material.io/guidelines/components/grid-lists.html">here</a></mat-card-subtitle>
      <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile>1</mat-grid-tile>
        <mat-grid-tile>2</mat-grid-tile>
        <mat-grid-tile>3</mat-grid-tile>
        <mat-grid-tile>4</mat-grid-tile>
      </mat-grid-list>  
    </mat-card-content>        
</mat-card>
