<mat-card>
    <div class="p-3 bg-primary position-relative">
        <h4 class="card-title text-white m-0">Up Coming Events
        </h4>

        <h6 class="card-subtitle text-white m-0 op-5"></h6>
        <!-- <button mat-mini-fab color="accent" class="add-contact">+</button> -->
    </div>
    <div class="message-box  Contact-box">
        <div class="message-widget contact-widget">

            <ng-container *ngFor="let data of upcomingEventData  ">

                <div *ngIf="data['birthday'].length == 0 && data['join-date'].length == 0 && data['leave'].length == 0">
                    <div class="p-2">No upcoming events.</div>
                </div>
                <ng-container *ngFor="let item of data | keyvalue ">
                    <a *ngFor="let element of item.value">
                        <ng-container>
                            <div [ngStyle]="{'margin-right':'20px', 'margin-top':'-2px' }">
                                <mat-nav-list appAccordion>
                                    <mat-icon [ngStyle]="{'color':'#7460EE','font-size':'18px' }" class="material-icons-outlined">
                                        check_circle
                                    </mat-icon>
                                </mat-nav-list>
                            </div>
                            <div *ngIf="item.key==='birthday'" class="mail-contnet">
                                <h5 [ngStyle]="{'font-size':'14px','font-weight':'250'}"> {{element.name}} {{ 'Birthday : '+ element.birthdate}} </h5>
                            </div>

                            <div *ngIf="item.key==='join-date'" class="mail-contnet">
                                <h5 [ngStyle]="{'font-size':'14px','font-weight':'250'}"> {{element.name}} {{ 'Joinday : '+ element.joindate}} </h5>
                            </div>

                            <div *ngIf="item.key==='leave'" class="mail-contnet">
                                <h5 [ngStyle]="{'font-size':'14px','font-weight':'250'}"> {{element.name}} {{ 'Leave Date : '+ element.leavedate}} </h5>
                            </div>

                        </ng-container>
                    </a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</mat-card>