
<div class="shadow bg-body">
    <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
        <div>
            <div class="fs-5">{{componentTitle}}</div>
        </div>
        <div><button class="btn" (click)="returnToLeaveComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
    </div>
    <div class="">
        <div class="shadow bg-body" style="border-radius: 15px;">
            <form [formGroup]="editForm" class="px-4 py-2" (ngSubmit)="editLeaveSubmit()">
                <!-- <div class="row">
                    <div class="col-md-6">User Name: {{userEditData.username}}</div>
                    <div class="col-md-6">Leave Date: {{userEditData.leavedate}}</div>
                    <div class="col-md-6">Leave Reason: {{userEditData.leavereason}}</div>
                    <div class="col-md-6">Leave Type: {{userEditData.leavetype}}</div>
                </div> -->
                <div class="user-info-card">
                    <!-- <div class="user-info-header">
                        <h3 class="text-center">Leave Information</h3>
                    </div> -->
                    <div class="user-info-body">
                        <div class="user-info-row">
                            <span class="label">User Name:</span>
                            <span class="value">{{userEditData.username}}</span>
                        </div>
                        <div class="user-info-row">
                            <span class="label">Leave Date:</span>
                            <span class="value">{{userEditData.leavedate}}</span>
                        </div>
                        <div class="user-info-row">
                            <span class="label">Leave Reason:</span>
                            <span class="value">{{userEditData.leavereason}}</span>
                        </div>
                        <div class="user-info-row">
                            <span class="label">Leave Type:</span>
                            <span class="value">{{userEditData.leavetype}}</span>
                        </div>
                    </div>
                </div>
                
                
                <div [ngStyle]="{'margin-left':'10px'}">
                    <!-- <div [ngStyle]="{'display':'flex','gap' :'50px'}">
                        <label>User Name</label>
                        <label class="control-label username">{{userEditData.username}}</label>
                    </div>
    
                    <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap':'50px'}">
                        <label>Leave Date </label>
                        <label class="control-label username">{{userEditData.leavedate}}
                        </label>
                    </div>
    
                    <div class="reason" [ngStyle]="{'margin-top':'30px','display':'flex','gap': '30px'  }">
                        <label>Leave Reason </label>
                        <label class="control-label username">{{userEditData.leavereason}}
    
                        </label>
                    </div>
    
                    <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'50px'}">
                        <label>Leave Type</label>
                        <label class="control-label username">{{userEditData.leavetype}}
                        </label>
                    </div> -->
    
                    <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'30px'}">
                        <label>Admin&nbsp;Note
                        </label>
                        <textarea formControlName="adminNote" name="" id="adminnote" class="form-control"
                            rows="2"> </textarea>
    
                    </div>
    
                    <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'50px'}">
                        <label>Approval </label>
    
                        <select formControlName="approvalSelect" name="" id="" class="form-control">
                            <option value="" selected>Please Select</option>
                            <option value="1">Approved</option>
                            <option value="2">Declined</option>
                        </select>
                    </div>
    
                    <div class="invalid-error" *ngIf="isSubmitted && editElement['approvalSelect'].errors?.required">
                        <sup>*</sup>Select DropDown
                    </div>
    
                    <!-- <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                            <sup>*</sup>Select Date Please
                        </div> -->
    
                </div>
                <div class="px-4 py-2 d-flex align-items-end justify-content-end">
                    <button type="submit" [disabled]="editForm.invalid" class="me-4" mat-raised-button color="primary">Update</button>
                    <button  mat-raised-button color="warn" (click)="returnToLeaveComponent()">Cancel</button> 
                </div>
            </form>
        </div>
    </div>
</div>
