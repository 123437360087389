<!-- ============================================================== -->
<!-- Fixed height Card Grid-->
<!-- ============================================================== -->
<div fxLayout="row">
    <div fxFlex.gt-sm="100%" >
        <mat-card>
            <mat-card-content>
              <mat-card-title>Basic slide-toggles</mat-card-title>
              <mat-card-subtitle><code>&lt;mat-slide-toggle&gt;</code> is an on/off control that can be toggled via clicking or dragging.</mat-card-subtitle>
              <mat-slide-toggle>Slide me!</mat-slide-toggle>
            </mat-card-content>        
        </mat-card>
    </div>
</div>    
<!-- ============================================================== -->
<!-- Basic Card Grid-->
<!-- ============================================================== -->
<mat-card>
    <mat-card-content>
      <mat-card-title>Basic grid-list</mat-card-title>
      <mat-card-subtitle><code>&lt;mat-slide-toggle&gt;</code> is an on/off control that can be toggled via clicking or dragging.</mat-card-subtitle>
      <section class="example-section">
      <label class="example-margin">Color:</label>
      <mat-radio-group [(ngModel)]="color">
        <mat-radio-button class="example-margin" color="primary" value="primary">
          Primary
        </mat-radio-button>
        <mat-radio-button class="example-margin" color="accent" value="accent">
          Accent
        </mat-radio-button>
        <mat-radio-button class="example-margin" color="warn" value="warn">
          Warn
        </mat-radio-button>
      </mat-radio-group>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>
    </section>

    <section class="example-section">
      <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
    </section>
    <h2 class="example-h2">Result</h2>

    <section class="example-section">
      <mat-slide-toggle
          class="example-margin"
          [color]="color"
          [checked]="checked"
          [disabled]="disabled">
        Slide me!
      </mat-slide-toggle>
    </section>  
    </mat-card-content>        
</mat-card>
