<!-- ============================================================== -->
<!-- Fixed height Card Grid-->
<!-- ============================================================== -->
<div fxLayout="row">
		<div fxFlex.gt-sm="100%" >
				<mat-card>
						<mat-card-content>
							<mat-card-title>Basic list</mat-card-title>
							<mat-card-subtitle><code>&lt;mat-list&gt;</code> is a container component that wraps and formats a series of line items. As the base list component, it provides Material Design styling, but no behavior of its own.<code><a href="https://material.angular.io/components/list/overview">Official Doc here</a></code></mat-card-subtitle>
							<mat-list role="list">
								<mat-list-item role="listitem">Item 1</mat-list-item>
								<mat-list-item role="listitem">Item 2</mat-list-item>
								<mat-list-item role="listitem">Item 3</mat-list-item>
							</mat-list>
						</mat-card-content>        
				</mat-card>
		</div>
</div>
<!-- ============================================================== -->
<!-- List with selection-->
<!-- ============================================================== -->
<div fxLayout="row">
		<div fxFlex.gt-sm="50%" >
				<mat-card>
						<mat-card-content>
							<mat-card-title>List with selection</mat-card-title>
							<mat-card-subtitle>A selection list provides an interface for selecting values, where each list item is an option.</mat-card-subtitle>
							<mat-selection-list #shoes>
								 <mat-list-option *ngFor="let shoe of typesOfShoes">
										{{shoe}}
								 </mat-list-option>
							</mat-selection-list>
							<p>
									Options selected: {{shoes.selectedOptions.selected.length}}
							</p>    
						</mat-card-content>        
				</mat-card>
		</div>
		<div fxFlex.gt-sm="50%" >
				<mat-card>
						<mat-card-content>
							<mat-card-title>Multiline lists</mat-card-title>
							<mat-card-subtitle>A selection list provides an interface for selecting values, where each list item is an option.</mat-card-subtitle>
							<mat-list>
								<mat-list-item *ngFor="let message of messages">
									<h3 matLine>{{message.from}}</h3>
									<p matLine class="text-muted">{{message.subject}}</p>
									<p matLine class="text-muted">{{message.content}}</p>    
								</mat-list-item>
							</mat-list>    
						</mat-card-content>        
				</mat-card>
		</div>
</div>
<!-- ============================================================== -->
<!-- List with icons selection-->
<!-- ============================================================== -->
<div fxLayout="row">
		<div fxFlex.gt-sm="100%" >
				<mat-card>
						<mat-card-content>
							<mat-card-title>Multiline lists</mat-card-title>
							<mat-card-subtitle>A selection list provides an interface for selecting values, where each list item is an option.</mat-card-subtitle>
							<mat-list>
								<mat-list-item *ngFor="let message of messages">
									<img mat-list-avatar [src]="message.image" alt="Image of {{message.from}}">    
									<h3 matLine>{{message.from}}</h3>
									<p matLine class="text-muted">{{message.content}}</p>     
								</mat-list-item>
							</mat-list>    
						</mat-card-content>        
				</mat-card>
		</div>
</div> 
<!-- ============================================================== -->
<!-- List with icons selection-->
<!-- ============================================================== -->
<div fxLayout="row">
	<div fxFlex.gt-sm="100%" >
		<mat-card>
			<mat-card-content class="p-b-0 m-b-0">
				<mat-card-title>List with sections</mat-card-title>
				<mat-card-subtitle>A selection list provides an interface for selecting values, where each list item is an option.</mat-card-subtitle>
			</mat-card-content>   
			<mat-list>
				<mat-card-content class="p-t-0">    
					<h3 mat-subheader>Folders</h3>
					<mat-list-item *ngFor="let folder of folders">
						<mat-icon mat-list-icon>folder</mat-icon>
						<h4 mat-line>{{folder.name}}</h4>
						<p mat-line> {{folder.updated | date}} </p>
					</mat-list-item>
				</mat-card-content>   
				<mat-divider></mat-divider>
				<mat-card-content>    
					<h3 mat-subheader>Notes</h3>
					<mat-list-item *ngFor="let note of notes">
						<mat-icon mat-list-icon>note</mat-icon>
						<h4 mat-line>{{note.name}}</h4>
						<p mat-line> {{note.updated | date}} </p>
					</mat-list-item>
				</mat-card-content>      
			</mat-list>    
		</mat-card>
	</div>
</div>