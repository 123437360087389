import { Injectable} from '@angular/core';
import { Api } from 'src/app/services/api.service';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  
  	private colorPairs = {
		A: { background: "#FFDAB9", text: "#8B4513" }, B: { background: "#E6E6FA", text: "#4B0082" }, C: { background: "#B0E0E6", text: "#2F4F4F" }, D: { background: "#FFFFE0", text: "#8B8000" }, E: { background: "#F5F5DC", text: "#8B4513" }, F: { background: "#F0FFF0", text: "#006400" }, G: { background: "#FFFACD", text: "#8B4513" }, H: { background: "#F0F8FF", text: "#00008B" }, I: { background: "#FFF0F5", text: "#800000" }, J: { background: "#FFF5EE", text: "#8B4513" }, K: { background: "#FFE4E1", text: "#800000" }, L: { background: "#E0FFFF", text: "#2F4F4F" }, M: { background: "#FFEBCD", text: "#8B4513" }, N: { background: "#FAFAD2", text: "#8B8000" }, O: { background: "#FAEBD7", text: "#8B4513" }, P: { background: "#FFEFD5", text: "#8B4513" }, Q: { background: "#FDF5E6", text: "#8B4513" }, R: { background: "#FFFAF0", text: "#8B0000" }, S: { background: "#DCDCDC", text: "#2F4F4F" }, T: { background: "#AFEEEE", text: "#2F4F4F" }, U: { background: "#FFF8DC", text: "#8B4513" }, V: { background: "#F5F5F5", text: "#2F4F4F" }, W: { background: "#F5F5F5", text: "#2F4F4F" }, X: { background: "#F5F5F5", text: "#2F4F4F" }, Y: { background: "#F5F5F5", text: "#2F4F4F" }, Z: { background: "#F5F5F5", text: "#2F4F4F" },
	};
  
  	constructor( private http: HttpService ) {}

  	getBmacUsersList() {
		return this.http.get(Api.url.userListChat, {}).then((data: any) => {
			const userList = [];
			data.data.forEach(user => {
				const nameFirstLetter = user.name.substring(0,1).toUpperCase();
				userList.push({
					...user,
					firstLetter: nameFirstLetter,
					bgColor: this.colorPairs[nameFirstLetter].background || '#fff',
					txtColor: this.colorPairs[nameFirstLetter].text || '#000'
				})
			});
			const userSearchSuggessions = userList;
      		return { userList, userSearchSuggessions}
		});
	}
	getIconFromuserName(name) {
		const nameFirstLetter = name.substring(0,1).toUpperCase();
		return {
			firstLetter: nameFirstLetter,
			bgColor: this.colorPairs[nameFirstLetter].background || '#fff',
			txtColor: this.colorPairs[nameFirstLetter].text || '#000'
		}
	}
}
