import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Api } from 'src/app/services/api.service';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuItems } from '../../../../shared/menu-items/menu-items';

@Component({
  selector: 'app-manage-menu-modal',
  templateUrl: './manage-menu-modal.component.html',
  styleUrls: []
})
export class ManageMenuModalComponent implements OnInit {

  
  Setting_Menu = new FormGroup({
    Resumes: new FormControl(false),
    Timesheet: new FormControl(false),
    Attendance: new FormControl(false),
    Machine: new FormControl(false),
    Tasklogs: new FormControl(false),
    Inventory: new FormControl(false),
    Document: new FormControl(false),
  });

  menus: string[] = [];
  menuList: any;
  message: string = 'This is a modal!';
  
  constructor(
    public dialogRef: MatDialogRef<ManageMenuModalComponent>,  
    public menuItems: MenuItems,
    private http: HttpService,
    private api: Api,
    public snackBar: MatSnackBar
  ) {}

  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  ngOnInit() {
    this.MenuList();
    this.AdminDetail();
  }

  
  MenuList() {
    this.http.post(Api.url.adminMenuList).then((response: any) => {
      if (response.success) {
        for (let i = 0; i < response.data.length; i++) {
          this.Setting_Menu.controls[response.data[i]['menu_name']].setValue(true);
          this.menuList = response.data[i]['menu_name'];
          const index = this.menuItems.getMenuitem().findIndex(x => x.name === this.menuList);
          if (index !== -1) {
            this.menuItems.getMenuitem().splice(index, 1);
          }
        }
      }
    });
  }

  
  AdminDetail() {
    this.http.get(Api.url.adminDetail).then((response: any) => {
      if (response.success) {
        
        this.applyTheme(response.data['theme_color'], response.data['back_color'], response.data['menu_color']);
        
        this.Setting_Menu.controls['theme_color'].setValue(response.data['theme_color']);
        this.Setting_Menu.controls['back_color'].setValue(response.data['back_color']);
        this.Setting_Menu.controls['menu_color'].setValue(response.data['menu_color']);
      }
    });
  }

  
  applyTheme(themeColor: string, backColor: string, menuColor: string) {
    
    const toolbar = document.querySelector('.mat-toolbar.mat-primary') as HTMLElement;
    if (toolbar) {
      toolbar.style.backgroundColor = themeColor;
    }

    
    const root = document.documentElement;
    root.style.setProperty('--themecolor-alt', backColor);
    root.style.setProperty('--menu-color', menuColor);
  }

  
  changed(e: any) {
    if (e.checked) {
      this.menus.push(e.source.name);
    } else {
      const index = this.menus.indexOf(e.source.name);
      if (index !== -1) {
        this.menus.splice(index, 1);
      }
    }
  }

  
  settingMenu() {
    this.http.post(Api.url.adminManagemenu, { menu: this.menus }).then((response: any) => {
      if (response.success) {
        // this.snackBar.open('Menu updated successfully', '', { duration: 3000 });
        this.openSnackBar("Menu updated successfully","","green-snackbar");
        this.MenuList();  
        this.menus = [];  
        this.dialogRef.close();  
      } else {
        // this.snackBar.open('Failed to update menu', '', { duration: 3000 });
        this.openSnackBar("Failed to update menu","","green-snackbar");
      }
    });
  }
}
