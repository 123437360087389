<!-- 
<div fxLayout="row">
    <div fxFlex.gt-sm="100%" >
        <span class="dashboard-title">ATTENDANCE</span>
        <div style="display: inline-block;float: right;">{{currentdate}}</div>
    </div>
</div>
<span layout="row"><hr flex/></span> -->
<div class="main-content bg-body p-2">
	<div class="col-4 ps-2 pb-3 pt-4">
		<div class="col-auto">
			<label class="visually-hidden" for="search">Search</label>
			<input type="text" (keyup)="searchAttendance($event)" class="form-control" id="search" placeholder="Search">
		</div>
	</div>
	<mat-grid-list #grid [cols]="cols">
		<mat-grid-tile><br />
			<mat-card class="scroll-record" style="box-shadow:none!important;">
				<table mat-table [dataSource]="dataSource" style="box-shadow:none!important;" matSort>
					<!-- Position Column -->
					<ng-container matColumnDef="createdate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
						<td mat-cell *matCellDef="let element let i = index"> {{element.createdate}} </td>
					</ng-container>
	
					<ng-container matColumnDef="createtime">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Star Time </th>
						<td mat-cell *matCellDef="let element"> {{element.create_time}} </td>
					</ng-container>
	
					<ng-container matColumnDef="checkout">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> End Time </th>
						<td mat-cell *matCellDef="let element"> {{element.checkouttime}} </td>
					</ng-container>
	
					<ng-container matColumnDef="action">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
						<td mat-cell *matCellDef="let element">
							<mat-icon class="example-icon" (click)="getTimeSheetById(element.id)">edit</mat-icon>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
				<mat-paginator class="select-dropdown" [pageSizeOptions]="[7,5,10]" showFirstLastButtons></mat-paginator>
			</mat-card>
		</mat-grid-tile>
		<mat-grid-tile>
			<mat-card>
				<br />
				<div id="attendancecalendar" class="p-2"> </div>
				<!-- <mat-calendar [selected]="13" class="cal"></mat-calendar> -->
			</mat-card>
		</mat-grid-tile>
	</mat-grid-list>
</div>