<!-- <div fxLayout="row">
    <div fxFlex.gt-sm="100%">
    <mat-form-field appearance="outline" style="width:40%;float:right" >
    <mat-label>Select User </mat-label>
    <mat-select (selectionChange)="onSelectEvent($event.value)">
      <mat-option *ngFor="let des of usersname" [value]="des.id">
        {{des.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
</div> -->

<div class="main-content bg-body">
  <div class="row p-2 border-bottom section m-0">
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <div class="dropdown">
        <div class="input-group">
            <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                <option selected value="0">Select Username</option>
                <ng-container *ngFor="let des of usersname">
                    <option value="{{des.id}}">{{des.name}}</option>
                </ng-container>
            </select>
        </div>
      </div>
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="dataSource" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element let i = index" > {{element.user_id}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="total_time">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Total Time </th>
            <td mat-cell *matCellDef="let element"> {{element.track}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Created At </th>
            <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
    </mat-paginator>
  </div>
</div>
