<div class="shadow bg-body">
    <div class="d-flex justify-content-between align-items-center p-2 border-bottom border-2">
        <div><div class="fs-5">{{componentTitle}}</div></div>
        <div><button class="btn" (click)="returnToLeaveComponent()"><mat-icon>keyboard_return</mat-icon></button></div>
    </div>
    <div class="">
        <div>
            <form [formGroup]="requestForm" class="px-4 py-2" (ngSubmit)="onSubmit()">
    
                <div class="dropdown">
                    <div class="input-group mb-3 ">
                        <button class="btn select-user btn-md disabled text-light" mat-raised-button color="primary" type="button">USER</button>
                        <select formControlName="addUserLeave" class="form-select" id="inputGroupSelect03" aria-label="Example select with button addon">
                            <option value="">Select User</option>
                            <option *ngFor="let item of userDataDisplay" value="{{item.id}}">{{item.name}}
                            </option>
                        </select>
    
                        <div class="invalid-error" *ngIf="isSubmitted && formElement['addUserLeave'].errors?.required">
                            <sup>*</sup>Select User Please
                        </div>
    
                    </div>
                </div>
    
                <div class="mt-2" [ngStyle]="{'display':'flex','gap' :'20px'}">
                    <label>Leave Type</label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" formControlName="leaveType" type="radio" name="leaveType" id="inlineRadio1" value="0">
                        <label class="form-check-label" for="inlineRadio1"> Half Day Leave
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input checked formControlName="leaveType" class="form-check-input" type="radio" name="leaveType" id="inlineRadio2" value="1">
                        <label class="form-check-label" for="inlineRadio2"> Full Day Leave</label>
                    </div>
                </div>
    
                <div class="invalid-error" *ngIf="isSubmitted && formElement['leaveType'].errors?.required">
                    <sup>*</sup>Select Leave Type
    
                </div>
    
                <div [ngStyle]="{ 'margin-top':'20px','display':'flex','gap' :'20px'}">
                    <label class="w-25 mt-3">Leave Date</label>
    
                    <mat-form-field (click)="picker.open()">
                        <mat-chip-list #chipList aria-label="Choose a date">
                            <mat-chip *ngFor="let value of model" [selectable]="false" [removable]="true" (removed)="remove(value)">
                                {{ value | date }}
                                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                            </mat-chip>
                            <input formControlName="selectDate" [value]="resetModal" matInput [matDatepicker]="picker" [min]="minDate" placeholder="" (dateChange)="dateChanged($event)" [matChipInputFor]="chipList" hidden />
                        </mat-chip-list>
                        <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [startAt]="init" [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>
    
                </div>
    
                <div class="invalid-error" *ngIf="isSubmitted && formElement['selectDate'].errors?.required">
                    <sup>*</sup>Select Date Please
                </div>
    
                <div [ngStyle]="{'margin-top':'30px' , 'display':'flex','gap' :'30px'}">
                    <label>Valid&nbsp;Reason
                    </label>
                    <textarea name="" id="adminnote" formControlName="adminNote" class="form-control" rows="2"></textarea>
                </div>
    
                <div class="invalid-error" *ngIf="isSubmitted && formElement['adminNote'].errors?.rquired ">
                    <sup>*</sup>Enter Valid Reason
    
                </div>
                <div class="px-4 py-2 d-flex align-items-end justify-content-end">
                    <button type="submit" [disabled]="requestForm.invalid" class="me-4" mat-raised-button color="primary">Save</button>  
                    <!-- <button type="button" class="btn btn-secondary">Close</button> -->
                    <button  mat-raised-button color="warn" (click)="returnToLeaveComponent()">Cancel</button> 
                </div>
    
            </form>
        </div>
    </div>
</div>