import { Component, OnInit,Input } from '@angular/core';
import { absentEmployeeData } from './absent-employee';

@Component({
  selector: 'app-absent-employee-today',
  templateUrl: './absent-employee-today.component.html',

})
export class AbsentEmployeeTodayComponent implements OnInit {


  // contactsData: Contact[];
  @Input() absentEmployeeDataShow: absentEmployeeData[] = [];
  constructor() {
    // this.contactsData = contacts;
  }

  ngOnInit(): void {
  }

}
