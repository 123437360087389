import { Time } from '@angular/common';
import { Component, OnInit, ViewChild,ChangeDetectorRef, AfterContentChecked,NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import {  FormControl,FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';


declare var $:any;
interface Role {
  value: string;
  id: string;
}
@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.css']
})
export class AddEditUsersComponent implements OnInit {
  componentTitle = "Add User";
  adduserbtn:any;
  edituserbtn = false;
  file_Array:any;
  roleoptions: Role[] = [
    {value: 'User', id: '1'},
    {value: 'Manager', id: '2'},
  ];
  desOptions: Role[] = [
    {value: 'Android developer', id: '1'},
    {value: 'iOS Developer', id: '2'},
    {value: 'Web developer', id: '3'},
    {value: 'Designer', id: '4'},
    {value: 'Python Developer', id: '5'}
  ];
    addUserForm = new FormGroup({
      user_id: new FormControl(''),
      name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.email]),
      password: new FormControl('',[Validators.required]),
      gender: new FormControl('',[Validators.required]),
      birthdate: new FormControl('',[Validators.required]),
      role: new FormControl('',[Validators.required]),
      designation: new FormControl('',[Validators.required]),
      contactno: new FormControl('',[Validators.required]),
      alternatecontact: new FormControl('',[Validators.required]),
      parentscontact: new FormControl('',[Validators.required]),
      currentaddress: new FormControl('',[Validators.required]),
      fulladdress: new FormControl('',[Validators.required]),
      joindate: new FormControl('',[Validators.required]),
      upload: new FormControl(''),
      leaving_date: new FormControl(''),
    });
    editUserData: any;

  
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  
  constructor(private NgZone: NgZone,private changeDetector: ChangeDetectorRef,public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api, private router: Router) { }

  ngOnInit(): void {

   
    this.editUserData = history.state;
    if(this.editUserData.edituserMode){
      this.componentTitle = "Edit User";
      this.adduserbtn = false;
      this.editUser(history.state);
    } else {
      this.adduserbtn = true;
    }
  }
  
  ngAfterContentChecked(): void {
   
  }

  
  onFileSelected(event:any) {
    console.log(event.target.files);
    if (event.target.files.length > 0) 
    {
      const file = event.target.files[0];
      this.file_Array = event.target.files[0];
    }
   
  }

  adduser(){
    const pipe = new DatePipe('en-US');
    const now = Date.now();
      
    const join_date = pipe.transform(this.addUserForm.get('joindate').value, 'yyyy-MM-dd');
    const leave_date =  pipe.transform(this.addUserForm.get('leaving_date').value, 'yyyy-MM-dd');
    const birth_date =  pipe.transform(this.addUserForm.get('birthdate').value, 'yyyy-MM-dd');

    const formData = new FormData();
    if(this.file_Array){
      formData.append('upload', this.file_Array);
    }
    formData.append('name', this.addUserForm.get('name').value);
    formData.append('email', this.addUserForm.get('email').value);
    formData.append('password', this.addUserForm.get('password').value);
    formData.append('gender', this.addUserForm.get('gender').value);
    formData.append('birthdate',birth_date);
    formData.append('role', this.addUserForm.get('role').value);
    formData.append('designation', this.addUserForm.get('designation').value);
    formData.append('contactno', this.addUserForm.get('contactno').value);
    formData.append('alternatecontact', this.addUserForm.get('alternatecontact').value);
    formData.append('parentscontact', this.addUserForm.get('parentscontact').value);
    formData.append('currentaddress', this.addUserForm.get('currentaddress').value);
    formData.append('fulladdress', this.addUserForm.get('fulladdress').value);
    formData.append('joindate',join_date);
    formData.append('leaving_date', leave_date);

    this.http.post(Api.url.adminaddUser,formData).then((response: any) => {
      if (response.success) {
         this.openSnackBar("Add User Successfully","","green-snackbar");
         this.router.navigate(['/admin/users']);
         $('#myform')[0].reset();
      }
    });
   }

   editUser(data:any){
     this.edituserbtn = true;
    let index = this.desOptions.findIndex(function(el: { value: string; }) {
      return el.value == data.designation;
    });
    // let index = this.desOptions.findIndex(x => x.value === data.designation);
          this.addUserForm.controls['user_id'].setValue(data['id']);
          this.addUserForm.controls['name'].setValue(data['name']);
          this.addUserForm.controls['email'].setValue(data['email']);
          this.addUserForm.controls['password'].setValue(data['password']);
          this.addUserForm.controls['gender'].setValue(data['gender']);

          let Array =  data['birthdate'].split("-");
          const date = new Date(Array[2], Array[1]-1, Array[0]);

          this.addUserForm.controls['birthdate'].setValue(date);
          this.addUserForm.controls['role'].setValue(data['role']);
          this.addUserForm.controls['designation'].setValue(this.desOptions[index]['id']);
          this.addUserForm.controls['contactno'].setValue(data['contactno']);
          this.addUserForm.controls['alternatecontact'].setValue(data['alternatecontact']);
          this.addUserForm.controls['parentscontact'].setValue(data['parentscontact']);
          this.addUserForm.controls['currentaddress'].setValue(data['currentaddress']);
          this.addUserForm.controls['fulladdress'].setValue(data['fulladdress']);

          let join_Array =  data['joindate'].split("-");
          const joindate = new Date(join_Array[2], join_Array[1]-1, join_Array[0]);

          this.addUserForm.controls['joindate'].setValue(joindate);
          this.addUserForm.controls['upload'].setValue(data['upload']);
          if(data['leavedate'] != null)
          {
            let leave_Array =  data['leavedate'].split("-");
            const leavedate = new Date(leave_Array[2], leave_Array[1]-1, leave_Array[0]);
            this.addUserForm.controls['leaving_date'].setValue(leavedate);
          }

   }

   edituser()
   {
    
     this.http.post(Api.url.admineditUser, this.addUserForm.value).then((response: any) => {
       if (response.success) {
          this.openSnackBar("Edit User Successfully","","green-snackbar");
          // $("#userModal").modal('hide');
          $('#myform')[0].reset();
          this.router.navigate(['/admin/users']);
          this.edituserbtn = false;
       }
     });
   
   }

   returnToUsersComponent(){
    this.router.navigate(['/admin/users']);
   }

}
