
<div class="main-content bg-body">
  <div class="row p-2 border-bottom align-items-center m-0">
    <div class="col-sm-4"><div class="fs-5">Invertory List</div></div>
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <button mat-raised-button color="primary" class="user-btn" (click)="gotoAddInventoryComponent()" >Add Inventory</button>
    </div>
  </div>
    <div>
    <table mat-table [dataSource]="dataSource" matSort>
            
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
          <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="giventotheuser">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Given To User </th>
          <td mat-cell *matCellDef="let element"> {{element.giventotheuser}}</td>
        </ng-container>

        <ng-container matColumnDef="date_inventory">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header>  Date Inventory  </th>
          <td mat-cell *matCellDef="let element"> {{element.date_inventory}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header>  Status  </th>
          <td mat-cell *matCellDef="let element">  {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
          <td mat-cell *matCellDef="let element">
              <mat-icon class="example-icon edit-icon" (click)="editInventory(element)">edit</mat-icon>
              <mat-icon class="example-icon delete-icon" (click)="deleteInventory(element.id)">delete</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
    <mat-form-field appearance="fill">
      <mat-label>Records per page</mat-label>
      <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
        <mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
        <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
      [pageSizeOptions]="[50, 100, 200, 500, -1]"
      aria-label="Select page">
  </mat-paginator>
</div>



<div class="modal fade" id="delinventory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title" id="exampleModalLabel">Delete Inventory</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>

          <div mat-dialog-actions style="float: right;">
            <button type="submit"  mat-raised-button color="primary" tabindex="2" (click)="delete_inventory()">Delete</button>
            <span class="margin-right-20"></span>
            <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
          </div>
        </div>
      
      </div>
    </div>
</div>

<div class="modal hide" id="InventoryModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">{{title_section}}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <form [formGroup]="addInventoryForm" name="adduser" style="margin-top:-45px;" id="myform">
            <input matInput value="" placeholder="Enter id" #message formControlName="id" name="id" style="visibility:hidden;" >
            <mat-form-field>
              <input matInput value="" placeholder="Enter Name" #message formControlName="name" name="name">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addInventoryForm.get('name')?.invalid && addInventoryForm.get('name')?.touched && addInventoryForm.get('name')?.errors?.required )">
                   Name is required
            </span>

            <mat-form-field>
              <input matInput value="" placeholder="Enter Description" #message formControlName="description" name="description">
            </mat-form-field>
        
            <span class="text-danger"
               *ngIf="(addInventoryForm.get('description')?.invalid && addInventoryForm.get('description')?.touched && addInventoryForm.get('description')?.errors?.required )">
                 Description is required
            </span>

            <mat-form-field>
              <input matInput value="" placeholder="Material..." #message formControlName="giventotheuser" name="giventotheuser">
            </mat-form-field>

            <span class="text-danger"
            *ngIf="(addInventoryForm.get('giventotheuser')?.invalid && addInventoryForm.get('giventotheuser')?.touched && addInventoryForm.get('giventotheuser')?.errors?.required )">
                Given To User is required
            </span> 

            <mat-form-field class="example-full-width" appearance="fill" (click)="picker2.open()">
              <mat-label>Inventory Date</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="date_inventory" name="date_inventory">
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input matInput value="" placeholder="Enter Status" #message formControlName="status" name="status">
              </mat-form-field>
  
              <span class="text-danger"
              *ngIf="(addInventoryForm.get('status')?.invalid && addInventoryForm.get('status')?.touched && addInventoryForm.get('status')?.errors?.required )">
                 Status is required
              </span> 

            <div mat-dialog-actions style="float: right;">
                <button type="submit" *ngIf="addinventorybtn" [disabled]="addInventoryForm.invalid" mat-raised-button color="primary" tabindex="2" (click)="addResume()">Save</button>
                <span class="margin-right-20"></span>
                <button type="submit" *ngIf="editinventorybtn" mat-raised-button color="primary" tabindex="2" (click)="updateInventory()">Update</button>
                <span class="margin-right-20"></span>
                <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


