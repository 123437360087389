<div class="main-content bg-body">
  
  <div class="row p-2 border-bottom section m-0 align-items-center">
    <div class="col-sm-4"><div class="fs-4">Attendance</div></div>
    <div class="col-sm-4 mobile-none"></div>
    <div class="col-sm-4">
      <div class="dropdown">
        <div class="input-group">
            <select class="form-select" (change)="onSelectEvent($event)" id="inputGroupSelect03" aria-label="Example select with button addon">
                <option selected value="0">Select User</option>
                <ng-container *ngFor="let des of usersname">
                    <option value="{{des.id}}">{{des.name}}</option>
                </ng-container>
            </select>
        </div>
      </div>
    </div>
  </div>
  
  <div class="">
    <table mat-table [dataSource]="dataSource" class="" matSort>
          
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> No </th>
        <td mat-cell *matCellDef="let element let i = index" > {{i+1}} </td>
      </ng-container>
  
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> User Name </th>
        <td mat-cell *matCellDef="let element" > {{element.username}} </td>
      </ng-container>
  
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element"> {{element.date}} </td>
      </ng-container>
  
      <ng-container matColumnDef="checkInTime">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> CheckIn </th>
          <td mat-cell *matCellDef="let element"> {{element.checkInTime}} </td>
      </ng-container>
  
      <ng-container matColumnDef="checkOutTime">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> CheckOut</th>
        <td mat-cell *matCellDef="let element"> {{element.checkOutTime}} </td>
      </ng-container>
  
      <ng-container matColumnDef="attend">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Attend </th>
        <td mat-cell *matCellDef="let element"> {{element.attend}}</td>
      </ng-container>
  
      <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> Action  </th>
          <td mat-cell *matCellDef="let element">
              <mat-icon class="example-icon edit-icon edit-icon" (click)="editAttendance(element)">edit</mat-icon>
          </td>
        </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div mat-paginator-range-controls class="page-size-dropdown custom-paginator">
    <mat-form-field appearance="fill">
      <mat-label>Records per page</mat-label>
      <mat-select [(value)]="pageSize" (selectionChange)="onPageSizeChange($event)">
        <mat-option [value]="-1">All</mat-option> <!-- Custom label for "All" -->
        <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-paginator #paginator [length]="length" (page)="changePaging($event)" [pageIndex]="currentPage" [pageSize]="pageSize" 
      [pageSizeOptions]="[50, 100, 200, 500, -1]"
      aria-label="Select page">
  </mat-paginator>
</div>

<div class="modal hide" id="attendanceModal">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
  
        <div class="modal-header">
          <h4 class="modal-title">Edit Attendance</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <form [formGroup]="editAttendanceForm" name="adduser" style="margin-top:-45px;" id="myform">
                <input matInput value="" placeholder="Enter id" #message formControlName="attendanceid" name="attendanceid" style="visibility:hidden;" >
                <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
                    <mat-label>Choose a Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="date" name="date">
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                <span class="text-danger"
               *ngIf="(editAttendanceForm.get('date')?.invalid && editAttendanceForm.get('date')?.touched && editAttendanceForm.get('date')?.errors?.required )">
               date is required
            </span>
            <mat-form-field>
              <input matInput placeholder="CheckIn" class="checkin" [ngxTimepicker]="toggleTimepicker2" [disableClick]="true" readonly [value]=currenttime formControlName="checkin" name="checkin">
              <ngx-material-timepicker-toggle [for]="toggleTimepicker2" style="float: right;" ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #toggleTimepicker2  [defaultTime]="currenttime"></ngx-material-timepicker>
            </mat-form-field>

            <!-- <mat-form-field>
              <input matInput placeholder="CheckIn" class="checkin" 
                     [ngxTimepicker]="toggleTimepicker2" 
                     [disableClick]="true" readonly 
                     [value]="currenttime" 
                     formControlName="checkin" name="checkin">
              
              <ngx-material-timepicker-toggle [for]="toggleTimepicker2" style="float: right;"></ngx-material-timepicker-toggle>
              
              <ngx-material-timepicker #toggleTimepicker2 [defaultTime]="currenttime"></ngx-material-timepicker>
            </mat-form-field> -->

            <!-- <mat-form-field>
              <input matInput placeholder="checkout" class="checkin" [ngxTimepicker]="toggleTimepicker" [disableClick]="true" readonly [value]=checkout formControlName="checkout" name="checkout">
              <ngx-material-timepicker-toggle [for]="toggleTimepicker" style="float: right;"></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #toggleTimepicker [defaultTime]="checkout"></ngx-material-timepicker>
            </mat-form-field> -->
            <mat-form-field>
              <input matInput placeholder="Checkout" class="checkout" 
                     [ngxTimepicker]="toggleTimepicker" 
                     [disableClick]="true" readonly 
                     [value]="checkout" 
                     formControlName="checkout" name="checkout">
              
              <ngx-material-timepicker-toggle [for]="toggleTimepicker" style="float: right;"></ngx-material-timepicker-toggle>
              
              <ngx-material-timepicker #toggleTimepicker [defaultTime]="checkout"></ngx-material-timepicker>
            </mat-form-field>

            <div mat-dialog-actions style="float: right;">
              <button type="submit" mat-raised-button color="primary" tabindex="2" (click)="saveAtetndance()">Save</button>
              <span class="margin-right-20"></span>
              <button  mat-raised-button color="warn" data-bs-dismiss="modal">Cancel</button> 
            </div>
      

              
             </form>
        </div>
      </div>
    </div>
  </div>

