import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators } from '@angular/forms';
import {HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { AuthGuard } from '../services/auth.guard';
import { Api } from '../services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var toastr: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  durationInSeconds = 5;
  isUserLoggedIn = false;
  options:any;
  constructor(private http:HttpService,private auth:AuthGuard,private api:Api,private fb: FormBuilder,public snackBar: MatSnackBar) { 
    this.auth.redirectIfLogin();
    this.createForm();
    
  }
  openSnackBar(message: string, action: string,Color:string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }
  LoginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  ngOnInit(): void {
    this.options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
  }
  createForm() {
    this.LoginForm = this.fb.group({
       email: ['', Validators.required],
       password: ['', Validators.required ]
    });
  }
  
  doLogin() {
    this.http.post(Api.url.login,this.LoginForm.value).then((response: any)=>{
      if(response.success){
       // console.log(response.data[0]['Admin']);
       this.auth.login(response.data[0],response.data[0].token,response.data[0]['Admin']);
       this.openSnackBar("Login successfully.","","blue-snackbar");
       //  setTimeout(() => {
       //    window.localStorage.setItem('userData',response.token);
       //    window.localStorage.setItem('userName',response.user['name']);
       //    window.localStorage.setItem('type',response.user['user_type']);
       //    window.location.href ='/';
       //  }, 2000);
      } 
      else if(response.success == false){
         this.openSnackBar("Invalid credentials","","blue-snackbar");
      }
    }).catch((error) => {
      this.openSnackBar(error.message,"",'red');
    });
   }
   
}

