import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
export interface PeriodicElement {
  h_date:any,
  title: any,
  description:any
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.css']
})

export class HolidayComponent implements OnInit {
  year: number = new Date().getFullYear();
  displayedColumns: string[] = ['h_date','title','description'];
  data:any;
  
   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  // dataSource: any[] = [];
  // dataSource = ELEMENT_DATA;
  constructor(private http:HttpService,private auth:AuthGuard,private api:Api) { 
    this.HolidayList();
  }

  ngOnInit(): void {
    // this.dataSource.paginator = this.paginator;
   
  }
  HolidayList() {
    this.http.post(Api.url.holidayList).then((response: any)=>{
     if(response.success){
      // console.log(response.data[0].data);
      this.dataSource.data = response.data.data;
     }
    }).catch((error) => {
     
    });
   }

}
