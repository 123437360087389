import { Injectable } from '@angular/core';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from '../../services/auth.guard';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const ADMINMENUS = [

  { state: 'admin/home', name: 'Home', type: 'link', icon: 'av_timer' },
  { state: 'admin/users', name: 'Users', type: 'link', icon: 'person' },
  { state: 'admin/resume', name: 'Resumes', type: 'link', icon: 'group' },
   { state: 'admin/leave', name: 'Leave', type: 'link', icon: 'lightbulb' },
  { state: 'admin/timesheet', name: 'Timesheet', type: 'link', icon: 'view_headline' },

   { state: 'admin/attendance', name: 'Attendance', type: 'link', icon: 'view_comfy' },
   { state: 'admin/project', name: 'Projects', type: 'link', icon: 'folder' },

   { state: 'admin/task', name: 'Task', type: 'link', icon: 'task' },
   { state: 'admin/machine', name: 'Machine', type: 'link', icon: 'inbox_customize' },
   { state: 'admin/holiday', name: 'Holidays', type: 'link', icon: 'holiday_village' },
   { state: 'admin/report', name: 'Reports', type: 'link', icon: 'summarize' },
   { state: 'admin/tasklog', name: 'Tasklogs', type: 'link', 'icon': 'web' },
   { state: 'admin/inventory', name: 'Inventory', type: 'link', icon: 'leaderboard' },
   { state: 'admin/document', name: 'Document', type: 'link', icon: 'edit_document' },
   { state: 'admin/feedback', name: 'Feed Back', type: 'link', 'icon': 'feed' },

];
const MENUITEMS = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state: 'attendance', type: 'link', name: 'Attendance', icon: 'view_comfy' },
  { state: 'leave', type: 'link', name: 'Leave', icon: 'view_list' },
  { state: 'timesheet', type: 'link', name: 'Timesheet', icon: 'view_headline' },
  { state: 'holiday', type: 'link', name: 'Holiday', icon: 'tab' },
  { state: 'task_track', type: 'link', name: 'Task Logs', icon: 'web' },
  { state: 'chat', type: 'link', name: 'Chat', icon: 'web' },
];


@Injectable()
export class MenuItems {
  data: any
  http: any;
  getMenuitem() {
    this.data = localStorage.getItem('ROLE');
    if (this.data != "Admin") {
      return MENUITEMS;
    } else {
      return ADMINMENUS;
    }
  }
}
