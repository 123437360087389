import { Time } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Api } from 'src/app/services/api.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { HttpService } from 'src/app/services/http.service';
import { UsermodalComponent } from '../../layouts/usermodal/usermodal.component'
import { ConfirmationDialogComponent } from '../../layouts/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AfterViewInit, ElementRef, Renderer2 } from '@angular/core';

// import { JqueryDatatableComponent } from 'src/app/common-component/jquery-datatable/jquery-datatable.component';
// import { environment } from 'src/environments/environment';
// import { Subject } from 'rxjs';
declare var DataTable;
declare var $: any;
export interface User {
  timedate: Date,
  startime: Time,
  endtime: Time,
  projname: String,
  taskname: String,
  description: string,
}
interface Role {
  value: string;
  id: string;
}

interface Designation {
  value: string;
  id: string;
}

const ELEMENT_DATA: User[] = [];
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit, AfterContentChecked {



  displayedColumns: string[] = ['id', 'name', 'email', 'date', 'fulladdress', 'tags', 'upload', 'action'];
  data: any;
  adduserbtn: any;
  edituserbtn: any;
  totalRows = 0;
  pageSize = 50;
  currentPage = 0;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  pageSizeOptions: number[] = [50, 100, 200, 500, 1000];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  length: any;
  searchvalue: any;
  userid: any;
  roleoptions: Role[] = [
    { value: 'User', id: '1' },
    { value: 'Manager', id: '2' },
  ];
  openSnackBar(message: string, action: string, Color: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [Color]
    });
  }

  desOptions: Role[] = [
    { value: 'Android developer', id: '1' },
    { value: 'iOS Developer', id: '2' },
    { value: 'Web developer', id: '3' },
    { value: 'Designer', id: '4' },
    { value: 'Python Developer', id: '5' }
  ];
  addUserForm = new FormGroup({
    user_id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    birthdate: new FormControl('', [Validators.required]),
    role: new FormControl('', [Validators.required]),
    designation: new FormControl('', [Validators.required]),
    contactno: new FormControl('', [Validators.required]),
    alternatecontact: new FormControl('', [Validators.required]),
    parentscontact: new FormControl('', [Validators.required]),
    currentaddress: new FormControl('', [Validators.required]),
    fulladdress: new FormControl('', [Validators.required]),
    joindate: new FormControl('', [Validators.required]),
    upload: new FormControl(''),
    leaving_date: new FormControl(''),
  });

  file_Array: any;
  constructor(public dialogbox: MatDialog, private changeDetector: ChangeDetectorRef,
    public snackBar: MatSnackBar, private http: HttpService, private auth: AuthGuard, private api: Api, public dialog: MatDialog,
    private router: Router, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    this.adduserbtn = true;
    this.UserList(this.searchvalue, this.pageSize, this.currentPage);
    this.usertable();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onFileSelected(event: any) {
    console.log(event.target.files);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.file_Array = event.target.files[0];
    }

  }

  onPageSizeChange(event: any) {
    const selectedSize = event.value;
    if (selectedSize === -1) {
      // When 'All' is selected, show all records
      this.showAllRecords();
    } else {
      // Otherwise, use the selected page size
      this.pageSize = selectedSize;
      // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
    }
  }


  changePaging(event: PageEvent) {
    if (this.pageSize !== -1) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
    }
  }


  showAllRecords() {
    this.pageSize = this.length;  // Set page size to total number of records
    this.paginator.pageSize = this.length;  // Disable pagination effectively
    this.paginator.pageIndex = 0;  // Go to the first "page"
    this.paginator._changePageSize(this.length);  // Manually trigger paginator update

    // Fetch all records
    // this.UserList(this.searchvalue, this.length, 0);
  }


  UserList(searchvalue: any, pageSize: any, currentPage: any) {
    this.http.post(Api.url.adminUser, { searchtext: searchvalue, page: currentPage + 1, recordPerPage: pageSize }).then((response: any) => {
      if (response.success) {
        this.dataSource.data = response.data.data;
        this.paginator.length = response.data.total;
        this.length = response.data.total;
      }

    }).catch((error) => {
      console.error(error);
    });
  }


  adduser() {
    const pipe = new DatePipe('en-US');
    const now = Date.now();

    const join_date = pipe.transform(this.addUserForm.get('joindate').value, 'yyyy-MM-dd');
    const leave_date = pipe.transform(this.addUserForm.get('leaving_date').value, 'yyyy-MM-dd');
    const birth_date = pipe.transform(this.addUserForm.get('birthdate').value, 'yyyy-MM-dd');

    const formData = new FormData();
    if (this.file_Array) {
      formData.append('upload', this.file_Array);
    }
    formData.append('name', this.addUserForm.get('name').value);
    formData.append('email', this.addUserForm.get('email').value);
    formData.append('password', this.addUserForm.get('password').value);
    formData.append('gender', this.addUserForm.get('gender').value);
    formData.append('birthdate', birth_date);
    formData.append('role', this.addUserForm.get('role').value);
    formData.append('designation', this.addUserForm.get('designation').value);
    formData.append('contactno', this.addUserForm.get('contactno').value);
    formData.append('alternatecontact', this.addUserForm.get('alternatecontact').value);
    formData.append('parentscontact', this.addUserForm.get('parentscontact').value);
    formData.append('currentaddress', this.addUserForm.get('currentaddress').value);
    formData.append('fulladdress', this.addUserForm.get('fulladdress').value);
    formData.append('joindate', join_date);
    formData.append('leaving_date', leave_date);

    this.http.post(Api.url.adminaddUser, formData).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Add User Successfully", "", "green-snackbar");
        $("#userModal").modal('hide');
        // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
      }
    });
  }

  editUser(data: any) {
    data.edituserMode = true;
    this.router.navigate(['/admin/add-edit-users'], { state: data });
  }
  edituser() {

    this.http.post(Api.url.admineditUser, this.addUserForm.value).then((response: any) => {
      if (response.success) {
        this.openSnackBar("Edit User Successfully", "", "green-snackbar");
        $("#userModal").modal('hide');
        $('#myform')[0].reset();
        // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
        this.edituserbtn = false;

      }
    });

  }

  SearchUser(event: any) {
    this.searchvalue = event.target.value;
    // this.UserList(this.searchvalue, this.pageSize, this.currentPage);
  }

  deleteUser(id: any) {
    this.userid = id;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        id: this.userid,
        model: "user",
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Delete',
          cancel: 'No'
        }
      }
    });
   $('#userDataTable').DataTable( {});
  }

  gotoAddUsersComponent() {
    this.router.navigate(['/admin/add-edit-users']);
  }
  ngOnChanges() {
  }



  // usertable() {
  //   new DataTable('#userDataTable', {
  //     ajax: {
  //       url: `${Api.url.getAdminUserList}`,
  //       type: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('logindata')).user.token,
  //         'Content-Type': 'application/json',

  //       },
  //       data: function (d) {
  //         // Send the parameters in the desired format
  //         return {
  //           page: Math.floor(d.start / d.length) + 1, // Page number calculation from start and length
  //           recordPerPage: d.length, // Number of records per page
  //           searchtext: d.search.value // Search text from DataTable search box
  //         };
  //       },
  //       dataSrc: function (json) {
  //         // The server should return { data: [], totalRecords: number }
  //         return json.data; // Data for the current page
  //       }
  //     },
  //     processing: true,
  //     serverSide: true,
  //     "order": [],
  //     destroy: true,
  //     searching: true,
  //     bSearching: true,
  //     bSearch: true,
  //     lengthMenu: [
  //       [10, 40, 80, 100, 150, -1],
  //       [10, 40, 80, 100, 150, "All"] // change per page values here
  //     ],
  //     drawCallback: function (settings) {
  //       const api = this.api();
  //       const totalRecords = api.settings()[0].json.totalRecords; // The total number of records for pagination
  //       const totalPages = Math.ceil(totalRecords / settings._iDisplayLength); // Calculate total pages
  //       console.log(`Total pages: ${totalPages}, Total records: ${totalRecords}`);
  //     },
  //     columns: [
  //       {
  //         title: 'SR No.',
  //         render: function (data, type, row, meta) {
  //           return meta.row + 1;
  //         },
  //         searchable: false,
  //         orderable: false
  //       },
  //       {

  //         data: 'name',
  //         title: 'Name',
  //         render: function (data, type, row) {
  //           return `${data} (${row.gender})`;
  //         }
  //       },
  //       {
  //         // data: 'name',
  //         title: 'Email/Contact No.',
  //         render: function (data, type, row) {
  //           return `<div>${row.email}</div>
  //               <div>${row.contactno}</div>
  //               <div>Alt:${row.alternatecontact}</div>
  //               <div>Parent:${row.parentscontact}</div>`;
  //         }
  //       },
  //       {
  //         // data: 'name',
  //         title: 'Date',
  //         render: function (data, type, row) {
  //           return `<div>Bday:${row.birthdate}</div>
  //               <div>Join:${row.joindate}</div>
  //               <div>Leave:${row.leaving_date ? row.leaving_date : '-'}</div>`;
  //         }
  //       },
  //       {
  //         // data: 'name',
  //         title: 'Full Current Address',
  //         render: function (data, type, row) {
  //           return `<div>Current:${row.currentaddress}</div>
  //               <div>Permenant:${row.fulladdress}</div>`;
  //         }
  //       },
  //       {
  //         // data: 'name',
  //         title: 'Tags',
  //         render: function (data, type, row) {
  //           return `<div>Role:${row.role}</div>
  //               <div>isLeft:${row.isleft}</div>
  //               <div>Designation:${row.designation}</div>`;
  //         }
  //       },
  //       {
  //         // data: 'name',
  //         title: 'Upload',
  //         render: function (data, type, row) {
  //           return `<div><img src=${row.file ? row.file : ""} alt="Not Uploaded"/></div>`;
  //         }
  //       },
  //       {
  //         title: 'Action',
  //         render: (data, type, row) => {
  //           const rowDataString = JSON.stringify(row);
  //           // console.log('Serialized Row data:', rowDataString);

  //           return `
  //             <button class="btn example-icon edit-icon" data-row='${rowDataString}'><i class="fa fa-edit"></i></button>
  //             <button class="btn example-icon delete-icon" data-id="${row.id}"><i class="fa fa-trash"></i></button>
  //           `;
  //         }
  //       }
  //     ],
  //   });
  //   this.renderer.listen(this.el.nativeElement, 'click', (event) => {
  //     if (event.target && event.target.classList.contains('edit-icon')) {
  //       const rowData = JSON.parse(event.target.getAttribute('data-row'));
  //       this.editUser(rowData);
  //     } else if (event.target && event.target.classList.contains('delete-icon')) {
  //       const id = event.target.getAttribute('data-id');
  //       this.deleteUser(id);
  //     }
  //   });

  // }

  usertable() {
    const table = new DataTable('#userDataTable', {
      processing: true,
      serverSide: true,
      // pagingType: "simple_numbers",
      // pageLength: 10, // Default to 10 records per page
      "drawCallback": function (settings) {
        const api = this.api();
        const totalRecords = api.settings()[0].json.totalRecords; // Total number of records from the server
        const totalPages = Math.ceil(totalRecords / settings._iDisplayLength); // Calculate total pages
        console.log(`Total pages: ${totalPages}, Total records: ${totalRecords}`);
      },
      ajax: {
        url: `${Api.url.getAdminUserList}`,
        type: 'GET',
        headers: {
          'Authorization': `Bearer ` + JSON.parse(localStorage.getItem('logindata')).user.token,
          'Content-Type': 'application/json',

        },
        data: function (d) {
          // console.log(d, "data");return;
          return {
            page: Math.floor(d.start / d.length) + 1,
            recordPerPage: d.length, 
            searchtext: d.search.value
          };
        },
        dataSrc: function (json) {
          return json.data;
        }
      },  
      columns: [
        {
          title: 'SR No.',
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
          searchable: false,
          orderable: false
        },
        {

          data: 'name',
          title: 'Name',
          render: function (data, type, row) {
            return `${data} (${row.gender})`;
          }
        },
        {
          // data: 'name',
          title: 'Email/Contact No.',
          render: function (data, type, row) {
            return `<div>${row.email}</div>
                  <div>${row.contactno}</div>
                  <div>Alt:${row.alternatecontact}</div>
                  <div>Parent:${row.parentscontact}</div>`;
          }
        },
        {
          // data: 'name',
          title: 'Date',
          render: function (data, type, row) {
            return `<div>Bday:${row.birthdate}</div>
                  <div>Join:${row.joindate}</div>
                  <div>Leave:${row.leaving_date ? row.leaving_date : '-'}</div>`;
          }
        },
        {
          // data: 'name',
          title: 'Full Current Address',
          render: function (data, type, row) {
            return `<div>Current:${row.currentaddress}</div>
                  <div>Permenant:${row.fulladdress}</div>`;
          }
        },
        {
          // data: 'name',
          title: 'Tags',
          render: function (data, type, row) {
            return `<div>Role:${row.role}</div>
                  <div>isLeft:${row.isleft}</div>
                  <div>Designation:${row.designation}</div>`;
          }
        },
        {
          // data: 'name',
          title: 'Upload',
          render: function (data, type, row) {
            return `<div><img src=${row.file ? row.file : ""} alt="Not Uploaded"/></div>`;
          }
        },
        {
          title: 'Action',
          render: (data, type, row) => {
            const rowDataString = JSON.stringify(row);
            // console.log('Serialized Row data:', rowDataString);

            return `
                <button class="btn example-icon edit-icon" data-row='${rowDataString}'><i class="fa fa-edit"></i></button>
                <button class="btn example-icon delete-icon" data-id="${row.id}"><i class="fa fa-trash"></i></button>
              `;
          }
        }
      ],
    });
      this.renderer.listen(this.el.nativeElement, 'click', (event) => {
        if (event.target && event.target.classList.contains('edit-icon')) {
          const rowData = JSON.parse(event.target.getAttribute('data-row'));
          this.editUser(rowData);
        } else if (event.target && event.target.classList.contains('delete-icon')) {
          const id = event.target.getAttribute('data-id');
          this.deleteUser(id);
        }
      });
  }


}
