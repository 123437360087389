import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from 'src/app/services/http.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { Api } from 'src/app/services/api.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  menuList:any;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public snackBar: MatSnackBar,private http:HttpService,private auth:AuthGuard,private api:Api
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.MenuList();
    // console.log(menuItems.getMenuitem()[0]);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  MenuList(){
    this.http.post(Api.url.adminMenuList).then((response: any) => {
      if (response.success) {
        console.log(response.data);
        for (var i = 0; i<response.data.length;i++)
        {
          this.menuList = response.data[i]['menu_name'];
          // console.log(this.menuList);
          // console.log(this.menuItems.getMenuitem());
          const index = this.menuItems.getMenuitem().findIndex(x => x.name === this.menuList);
          // console.log(index);
          this.menuItems.getMenuitem().splice(index, 1);
        }
        // console.log(this.menuList)

      }
    }); 
  }
}
